import styled from 'styled-components';
import List from 'react-chatview';
import SliderMobile from '~/components/SliderMobile';

interface IBanner {
  src: string;
}

export const Container = styled(List)`
  position: relative;
  height: calc(100vh - 61px);

  img {
    width: 70px;
    height: 70px;
  }

  .description {
    line-height: 33.5px;
  }
`;

export const Banner = styled.div<IBanner>`
  position: absolute;
  background: linear-gradient(2.91deg, #202020 10.56%, rgba(1, 1, 1, 0) 95.75%),
    linear-gradient(0deg, rgba(32, 32, 32, 0.4), rgba(32, 32, 32, 0.4)),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  z-index: -1;
`;

export const Courses = styled.div``;

export const CoursesSlider = styled(SliderMobile)``;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #3a3a3a;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonDown = styled.button`
  background: none;
  border: none;

  div {
    background: rgba(32, 32, 32, 0.9);
    border: 1px solid #bbbbbb;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #bbbbbb;
  }

  ::before {
    display: none;
  }
`;
