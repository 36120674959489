import styled from 'styled-components';

interface IContainerProps {
  width: number | string;
  height: number | string;
  radius?: number;
}

export const Container = styled.div<IContainerProps>`
  width: ${(props) =>
    typeof props.width === 'number' ? `${props.width}px` : props.width};
  height: ${(props) =>
    typeof props.height === 'number' ? `${props.height}px` : props.height};
  background-color: #343434 !important;
  overflow: hidden;
  position: relative;
  border-radius: ${(props) => (props.radius ? props.radius : 26)}px;
  border: none !important;
  color: transparent !important;
  pointer-events: none;
  box-shadow: none;
  span {
    color: transparent !important;
  }
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      #343434 0%,
      rgba(24, 26, 26, 0) 0%,
      rgba(24, 26, 26, 0) 10%,
      rgba(24, 26, 26, 0.2) 20%,
      rgba(24, 26, 26, 0.5) 40%,
      rgba(24, 26, 26, 0.8) 50%,
      rgba(24, 26, 26, 0.2) 80%,
      rgba(24, 26, 26, 0) 90%,
      rgba(24, 26, 26, 0) 100%
    ) !important;
    animation: glow 1.3s linear infinite;
  }

  @keyframes glow {
    100% {
      transform: translateX(100%);
    }
  }
`;
