/* eslint-disable no-restricted-syntax */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Container } from './styles';
import Quiz from '~/components/Quiz';
import api from '~/services/api';
import Loading from '~/components/Loading';

interface IQuestionAnswer {
  id: string;
  content: string;
  correct: boolean;
}

interface IUserQuestion {
  id: string;
  correct: boolean;
  attempt: number;
}

interface IQuestion {
  id: string;
  content: string;
  attempt: number;
  correct?: boolean;
  answer_id?: string;
  answers: IQuestionAnswer[];
  userQuestion: IUserQuestion;
}

interface IExam {
  show_answers?: boolean;
  duration?: string;
  attempts?: number;
  average?: number;
  questions: IQuestion[];
  attempt?: number;
}

interface ICourse {
  id: string;
  title: string;
  has_certificate: boolean;
  exam: {
    id: string;
  };
}

interface IParams {
  slug: string;
}

const Test: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const [exam, setExam] = useState({} as IExam);
  const [testStarted, setTestStarted] = useState(false);
  const [counterStarted, setCounterStarted] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [loading, setLoading] = useState(false);

  const checkCertificate = useCallback(async () => {
    try {
      const responseCourse = await api.get<ICourse>(`courses/${params.slug}`);
      if (responseCourse.data.has_certificate) {
        await api.get(`users-certificates/courses/${responseCourse.data.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  }, [params.slug]);

  useEffect(() => {
    api.get<ICourse>(`courses/${params.slug}`).then(async (responseCourse) => {
      const response = await api.get<IExam>(
        `exams/${responseCourse.data.exam.id}`
      );
      response.data.attempt = Math.max(
        ...response.data.questions.map((question) => question.attempt)
      );

      setCourseName(responseCourse.data.title);
      setExam(response.data);
    });
  }, [params.slug]);

  const handleSubmitAnswers = useCallback(
    (questions: IQuestion[], attempt: number) => {
      questions.forEach(async (question) => {
        const formData = {
          question_id: question.id,
          answer_id: question.answer_id,
          correct: !!question.correct,
          attempt,
        };

        await api.post('users-questions', formData);
      });

      const newExam = { ...exam };

      if (newExam.attempt) {
        newExam.attempt += 1;
      }

      const hits = questions.filter((question) => question.correct);

      const average = (hits.length / questions.length) * 10;

      if (average >= 7) {
        setLoading(true);
        checkCertificate().finally(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Parabéns!',
            text: 'Seu certificado foi gerado com sucesso',
            showConfirmButton: true,
            confirmButtonText: 'Ver meu certificado',
          }).then(async (result) => {
            if (result.isConfirmed) {
              history.push(`${process.env.PUBLIC_URL}/certificados`);
            }
          });
        });
      }

      setExam(newExam);
      setTestStarted(false);
      setCounterStarted(false);
    },
    [checkCertificate, exam]
  );

  const handleClickStart = useCallback(() => {
    setTestStarted(true);
    // setTimeout(() => {
    //   setCounterStarted(true);
    // }, 1000);
  }, []);

  return (
    <Container className="container">
      <div className="row">
        <div className="col-12 pt-5">
          <h1 className="h2 text-white fw-semibold">Avaliação {courseName}</h1>
        </div>
        <div className="col-12 py-5">
          <div className="box bg-dark-2 p-md-3 p-lg-5">
            <div className="p-4">
              {Object.keys(exam).length > 0 && (
                <>
                  {exam.attempt &&
                  exam.attempts &&
                  exam.attempt > exam.attempts ? (
                    <h2 className="text-center">
                      Desculpe, mas seu limite de tentativas foi atigindo.
                    </h2>
                  ) : (
                    <>
                      {testStarted ? (
                        <Quiz
                          questions={exam.questions}
                          duration={exam.duration}
                          showAnswers={exam.show_answers}
                          onSubmitAnswers={handleSubmitAnswers}
                          started={counterStarted}
                        />
                      ) : (
                        <div className="w-100 d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            onClick={handleClickStart}
                            className="btn btn-primary rounded-pill px-4"
                          >
                            Começar Teste
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Loading
        show={loading}
        message={`Parabens, você passou! \nEstamos gerando seu certificado!`}
      />
    </Container>
  );
};

export default Test;
