import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IBanner {
  src: string;
}

interface IAvatar {
  src: string;
}

interface ITabsGroup {
  opened: boolean;
}

interface ITab {
  active: boolean;
}

export const Container = styled.div`
  position: relative;

  .box-plan {
    background: #3a3a3a;
    border-radius: 14px;

    div {
      svg,
      p {
        margin-bottom: 10px !important;
      }

      p {
        color: #fff;
        width: calc(100% - 35px);
      }

      span.plan-name {
        background: #c0010a;
        border-radius: 18px;
        color: #fff;
      }

      a {
        span {
          text-decoration-line: underline;
          color: #fff;
          margin-bottom: 0;
        }

        svg {
          margin-bottom: 0 !important;
        }
      }
    }

    @media screen and (min-width: 340px) {
      div {
        svg,
        p,
        span.plan-name {
          margin-bottom: 10px !important;
        }

        p {
          width: calc(100% - 123px);
        }
      }
    }

    @media screen and (min-width: 425px) {
      div {
        p {
          width: unset;
        }

        span.plan-name {
          margin-left: 10px;
        }
      }
    }

    @media screen and (min-width: 520px) {
      div {
        svg,
        p,
        span.plan-name {
          margin-bottom: 0px !important;
        }
      }
    }
  }
`;

export const Box = styled.div`
  background: #242526;
  border-radius: 30px;
  overflow: hidden;

  a.social-media {
    width: 45px;
    height: 45px;

    + a.social-media {
      margin-left: 15px;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: #3a3a3a;
    opacity: 0;
  }

  @media screen and (min-width: 576px) {
    div.borders {
      border-left: 1px solid #bbbbbb;
      border-right: 1px solid #bbbbbb;
    }
  }

  @media screen and (min-width: 768px) {
    hr {
      opacity: 1;
    }
  }
`;

export const Banner = styled.button<IBanner>`
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 240px;
  border: none;
  display: flex;
  transition-duration: 0.3s;

  > div {
    transition: all 0.3s;
    opacity: 1;
  }

  @media screen and (min-width: 992px) {
    height: 250px;

    > div {
      opacity: 0;
    }

    :hover {
      > div {
        opacity: 1;
      }
    }
  }
`;

export const Avatar = styled.button<IAvatar>`
  background: url(${(props) => props.src}), #6b5555;
  background-position: center;
  background-size: cover;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #242526;
  top: -6rem;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  span {
    transition-duration: 0.3s;
    opacity: 1;
    background: rgba(32, 32, 32, 0.54);
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 576px) {
    left: 23%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 992px) {
    left: 10%;
    transform: translateX(-50%);

    span {
      opacity: 0;
    }

    :hover {
      span {
        opacity: 1;
      }
    }
  }
`;

export const Tabs = styled.div`
  border: 1px solid #333333;
  border-radius: 15px;

  @media screen and (min-width: 768px) {
    border: none;
    border-radius: 0;
  }
`;

export const TabsButton = styled.button`
  border: 1px solid #333333;
  border-radius: 15px;
`;

export const TabsGroup = styled.div<ITabsGroup>`
  transition-duration: 0.3s;
  height: ${(props) => (props.opened ? '200px' : '0')};
  overflow: hidden;
  padding-top: ${(props) => (props.opened ? '20px' : '0')};
  margin-top: ${(props) => (props.opened ? '20px' : '0')};
  border-top: ${(props) => (props.opened ? '1px solid #333333' : 'none')};

  @media screen and (min-width: 768px) {
    height: unset;
    overflow: unset;
  }
`;

export const Tab = styled.button<ITab>`
  position: relative;
  transition-duration: 0.3s;
  color: ${(props) => (props.active ? '#ffffff' : '#bbbbbb')};

  :after {
    content: '';
    transition-duration: 0.3s;
    background: ${(props) => (props.active ? '#FF0000' : '#FF000000')};
    width: 80%;
    height: 1px;
    border-radius: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  :hover {
    color: #ffffff;

    :after {
      background: #ff0000;
    }
  }

  + button {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    + button {
      margin-top: 0;
      margin-left: 40px;
    }
  }
`;

export const PersonalData = styled.div``;

export const Address = styled.div``;

export const Payments = styled.div`
  .table-users {
    background-color: #3a3a3a;

    > div {
      background-color: #3a3a3a;
      border: none;

      .content {
        .tb-header {
          border: none;

          button {
            color: #ffffff;
          }
        }

        .tb-body {
          background-color: #3a3a3a;

          .tb-row-hover:hover {
            background: rgba(108, 108, 108, 0.15);
          }

          div {
            color: #bbbbbb;
          }
        }

        .tb-header .tb-row,
        .tb-body .tb-row {
          > button:nth-child(1),
          > div:nth-child(1) {
            width: 75px;
          }

          > button:nth-child(2),
          > div:nth-child(2) {
            width: 400px;
          }

          > button:nth-child(3),
          > div:nth-child(3) {
            width: 150px;
          }

          > button:nth-child(4),
          > div:nth-child(4) {
            width: 250px;
          }

          > button:nth-child(5),
          > div:nth-child(5) {
            width: 175px;
          }

          > button:nth-child(6),
          > div:nth-child(6) {
            width: 250px;
          }

          > button:nth-child(7),
          > div:nth-child(7) {
            width: 250px;
          }
        }
      }

      :before,
      :after {
        background-color: #3a3a3a;
        border: none;
      }

      :nth-child(2) {
        select {
          background-color: #3a3a3a;
          color: #bbbbbb;
        }

        small {
          color: #bbbbbb;
        }

        button {
          background-color: transparent;
        }
      }
    }
  }
`;

export const Notifications = styled.div``;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .modal-content {
      padding: 1.5rem;
    }
  }
`;

export const SocialMedias = styled.div`
  .icon-circle {
    width: 33px;
    height: 33px;
  }

  .input-border {
    border-bottom: 1px solid #3a3a3a;
  }

  .link-border {
    border-bottom: 1px solid #3a3a3a;
  }

  @media screen and (min-width: 992px) {
    .link-border {
      border-bottom: none;
    }
  }
`;
