import React, { useState, useEffect, useCallback } from 'react';

import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import { Container } from './styles';
import Course from '~/components/Course';
import Skeleton from '~/components/Skeleton';

interface ICourse {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
}

interface ICourseData {
  current_page: number;
  last_page: number;
  data: ICourse[];
}

const Favorites: React.FC = () => {
  const { user } = useAuth();
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<ICourseData>(`favorites/courses`, {
        params: {
          page,
        },
      })
      .then((response) => {
        if (page === 1) {
          setCourses(response.data.data);
        } else {
          setCourses((state) => [...state, ...response.data.data]);
        }
        setLastPage(response.data.last_page);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, user.id]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [lastPage, page]);

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row align-items-center pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4">
            <h1 className="fw-semibold text-white ms-4">Favoritos</h1>
          </div>
          {!loading ? (
            <>
              {courses.length > 0 ? (
                <>
                  {courses.map((course) => (
                    <div className="col-md-6 col-xl-4 mb-3">
                      <Course
                        key={course.id}
                        to={`${process.env.PUBLIC_URL}/cursos/${course.slug}`}
                        thumb={course.thumbnail}
                        title={course.title}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div className="col-12 text-center py-5">
                  <h2 className="h3 text-gray">
                    Você ainda não possui nenhum curso favorito
                  </h2>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Favorites;
