/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  IoIosArrowDown,
  IoIosDownload,
  IoIosPaper,
  IoIosSend,
  IoMdHeart,
  IoMdHeartEmpty,
} from 'react-icons/io';
import { IoArrowUndoOutline } from 'react-icons/io5';
import { BsArrowClockwise, BsArrowCounterclockwise } from 'react-icons/bs';
import { VscScreenFull, VscScreenNormal } from 'react-icons/vsc';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { parseISO, formatDistanceStrict, subHours } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';

import { VideoJsPlayer } from 'video.js';
import { FiArrowLeft } from 'react-icons/fi';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import {
  Container,
  LessonContent,
  Video,
  Toggle,
  LessonsGroup,
  Tabs,
  TabsButton,
  TabsGroup,
  Tab,
  About,
  Downloads,
  Download,
  Comments,
  Avatar,
  Comment,
  AnswerForm,
  Answer,
  Modal,
} from './styles';
import Lessons from '~/components/Lessons';
import Quiz from '~/components/Quiz';
import Player from '~/components/Player';

import avatar from '~/assets/defaults/avatar.png';
import Input from '~/components/Input';

interface IParams {
  slugCourse: string;
  slugModule: string;
  slug: string;
  productSlug?: string;
}

interface IDownload {
  id: string;
  name: string;
  resource_url: string;
  link: string;
}

interface IQuestionAnswer {
  id: string;
  content: string;
  correct: boolean;
}

interface IUserQuestion {
  id: string;
  correct: boolean;
  attempt: number;
}

interface IQuestion {
  id: string;
  content: string;
  attempt: number;
  correct?: boolean;
  answer_id?: string;
  answers: IQuestionAnswer[];
  userQuestion: IUserQuestion;
}

interface ILesson {
  id: string;
  title: string;
  description: string;
  duration: string;
  slug: string;
  watching?: boolean;
  video: {
    video_url: string;
    link: string;
  };
  resources: IDownload[];
  userLesson?: {
    id: string;
    views: number;
    time: string;
    completed: boolean;
  };
  exam?: {
    show_answers?: boolean;
    duration?: string;
    attempts?: number;
    average?: number;
    questions: IQuestion[];
    attempt?: number;
  };
}

interface IModule {
  id: string;
  title: string;
  slug: string;
  lessons: ILesson[];
}

interface ICourse {
  id: string;
  has_certificate: boolean;
  sell_certificate: boolean;
  modules: IModule[];
  certificate: {
    id: string;
    discount?: number;
    has_discount?: boolean;
    price?: number;
  };
  exam?: {
    id: string;
  };
}

interface IAnswer {
  id: string;
  avatar?: string;
  name: string;
  content: string;
  date: string;
  doubtAnswerLike_id?: string;
}

interface IAnswerData {
  current_page: number;
  last_page: number;
  data: IAnswer[];
  total: number;
}

interface IComment {
  id: string;
  avatar?: string;
  name: string;
  content: string;
  date: string;
  answersData: IAnswerData;
  showAnswerForm?: boolean;
  doubtLike_id?: string;
}

interface ICommentData {
  current_page: number;
  last_page: number;
  data: IComment[];
  total: number;
}

interface IFormData {
  comment_id?: string;
  comment: string;
}

interface IResetData {
  reset(): void;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const Lesson: React.FC = () => {
  const formCommentRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user } = useAuth();
  const params = useParams<IParams>();
  const location = useLocation();
  const [tabSelected, setTabSelected] = useState('about');
  const [showQuiz, setShowQuiz] = useState(false);
  const [openTabs, setOpenTabs] = useState(false);
  const [lesson, setLesson] = useState({} as ILesson);
  const [module, setModule] = useState({} as IModule);
  const [modulesCount, setModulesCount] = useState(0);
  const [prevModule, setPrevModule] = useState<IModule | undefined>(undefined);
  const [nextModule, setNextModule] = useState<IModule | undefined>(undefined);
  const [commentsData, setCommentsData] = useState<ICommentData>({
    current_page: 1,
    last_page: 0,
    data: [],
    total: 0,
  });
  const [time, setTime] = useState(0);
  const [autoPlay, setAutoPlay] = useState(() => {
    const autoPlayData = localStorage.getItem('@Leiaut:autoPlay');
    return autoPlayData === 'true';
  });
  const [expanded, setExpanded] = useState(false);
  const [player, setPlayer] = useState<VideoJsPlayer | undefined>(undefined);
  const [course, setCourse] = useState({} as ICourse);
  const [percentCourse, setPercentCourse] = useState(0);

  const path = useMemo(() => {
    return location.pathname.includes('meus-cursos') ? 'meus-cursos' : 'cursos';
  }, [location.pathname]);

  const productSlug = useMemo(() => {
    if (params.productSlug) {
      return `/${params.productSlug}`;
    }

    return '';
  }, [params.productSlug]);

  const loadAnswers = useCallback(
    async (pageData: number, answerId: string) => {
      const response = await api.get<IAnswerData>(
        `doubts-answers/doubts/${answerId}`,
        {
          params: {
            page: pageData,
          },
        }
      );

      const data = response.data.data.map((answer) => ({
        id: answer.id,
        avatar: answer.avatar,
        name: answer.name,
        content: answer.content,
        date: formatDistanceStrict(
          subHours(parseISO(answer.date), 3),
          new Date(),
          {
            addSuffix: true,
            locale: ptBr,
          }
        ),
        doubtAnswerLike_id: answer.doubtAnswerLike_id,
      }));

      response.data.data = data;

      return response;
    },
    []
  );

  const loadComments = useCallback(
    async (pageData: number, lessonId: string) => {
      const response = await api.get<ICommentData>(
        `doubts/lessons/${lessonId}`,
        {
          params: {
            page: pageData,
          },
        }
      );

      const data: IComment[] = [];

      for (const comment of response.data.data) {
        const responseAnswers = await loadAnswers(1, comment.id);
        const newComment = {
          id: comment.id,
          avatar: comment.avatar,
          name: comment.name,
          content: comment.content,
          date: formatDistanceStrict(
            subHours(parseISO(comment.date), 3),
            new Date(),
            {
              addSuffix: true,
              locale: ptBr,
            }
          ),
          answersData: responseAnswers.data,
          doubtLike_id: comment.doubtLike_id,
        };

        data.push(newComment);
      }

      if (pageData === 1) {
        setCommentsData((state) => {
          const newCommentsData = { ...state };
          newCommentsData.current_page = response.data.current_page;
          newCommentsData.data = data;
          newCommentsData.last_page = response.data.last_page;
          newCommentsData.total = response.data.total;
          return newCommentsData;
        });
      } else {
        setCommentsData((state) => {
          const newCommentsData = { ...state };
          newCommentsData.current_page = response.data.current_page;
          newCommentsData.data = [...newCommentsData.data, ...data];
          newCommentsData.last_page = response.data.last_page;
          newCommentsData.total = response.data.total;
          return newCommentsData;
        });
      }
    },
    [loadAnswers]
  );

  const checkCertificate = useCallback(async () => {
    try {
      const responseCourse = await api.get<ICourse>(
        `courses/${params.slugCourse}`
      );

      if (responseCourse.data.has_certificate && !responseCourse.data.exam) {
        await api.get(`users-certificates/courses/${responseCourse.data.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  }, [params.slugCourse]);

  useEffect(() => {
    api
      .get<ICourse>(`courses/${params.slugCourse}`)
      .then(async (responseCourse) => {
        const responseModule = await api.get(
          `modules/${responseCourse.data.id}/${params.slugModule}`
        );
        const response = await api.get<ILesson>(
          `lessons/${responseCourse.data.id}/${responseModule.data.id}/${params.slug}`
        );

        const data = responseCourse.data.modules.find(
          (moduleData) =>
            !!moduleData.lessons.find(
              (lessonData) => lessonData.id === response.data.id
            )
        );

        let totalTime = 0;
        if (!response.data.userLesson) {
          const formData = {
            lesson_id: response.data.id,
          };

          const responseUserLesson = await api.post('users-lessons', formData);
          if (data) {
            const lessonIndex = data.lessons.findIndex(
              (lessonData) => lessonData.id === response.data.id
            );
            if (lessonIndex >= 0) {
              data.lessons[lessonIndex].userLesson = responseUserLesson.data;
            }
          }
        } else {
          const formData = {
            lesson_id: response.data.id,
            views: response.data.userLesson.views + 1,
            time: response.data.userLesson.time,
            completed: response.data.userLesson.completed,
          };

          const [hours, minutes, seconds] =
            response.data.userLesson.time.split(':');

          totalTime += parseInt(hours, 10) * 3600;
          totalTime += parseInt(minutes, 10) * 60;
          totalTime += parseInt(seconds, 10);

          const responseUserLesson = await api.post('users-lessons', formData);
          if (data) {
            const lessonIndex = data.lessons.findIndex(
              (lessonData) => lessonData.id === response.data.id
            );
            if (lessonIndex >= 0) {
              data.lessons[lessonIndex].userLesson = responseUserLesson.data;
            }
          }
        }

        await loadComments(1, response.data.id);

        if (response.data.exam) {
          response.data.exam.attempt = Math.max(
            ...response.data.exam.questions.map((question) => question.attempt)
          );
        }

        if (data) {
          const moduleIndex = responseCourse.data.modules.findIndex(
            (moduleData) =>
              !!moduleData.lessons.find(
                (lessonData) => lessonData.id === response.data.id
              )
          );
          if (moduleIndex >= 0) {
            if (responseCourse.data.modules[moduleIndex + 1]) {
              setNextModule(responseCourse.data.modules[moduleIndex + 1]);
            } else {
              setNextModule(undefined);
            }
            if (responseCourse.data.modules[moduleIndex - 1]) {
              setPrevModule(responseCourse.data.modules[moduleIndex - 1]);
            } else {
              setPrevModule(undefined);
            }
          }
          setModulesCount(responseCourse.data.modules.length);
          setModule(data);
        }

        const videos = responseCourse.data.modules.reduce(
          (acumulador: number, currentValue: IModule) => {
            return acumulador + currentValue.lessons.length;
          },
          0
        );

        let qtdLessonsComplete = 0;
        responseCourse.data.modules.forEach((moduleData) => {
          qtdLessonsComplete = moduleData.lessons.reduce(
            (acumulador: number, currentValue: ILesson) => {
              const value =
                currentValue.userLesson && currentValue.userLesson.completed
                  ? 1
                  : 0;
              return acumulador + value;
            },
            qtdLessonsComplete
          );
        });

        const percent = (qtdLessonsComplete * 100) / videos;

        setCourse(responseCourse.data);
        setPercentCourse(percent);
        setLesson(response.data);
        setTime(totalTime);
        checkCertificate();
      });
  }, [
    params.slug,
    params.slugCourse,
    loadComments,
    checkCertificate,
    params.slugModule,
  ]);

  useEffect(() => {
    if (player) {
      player.currentTime(time);
    }
  }, [player, time]);

  useEffect(() => {
    if (player && autoPlay) {
      setTimeout(() => {
        player.play();
      }, 200);
    }
  }, [autoPlay, player]);

  const handleSelectTab = useCallback((value) => {
    setTabSelected(value);
    setOpenTabs(false);
  }, []);

  const handleClose = useCallback(() => {
    setShowQuiz(false);
  }, []);

  const handleClickQuiz = useCallback(() => {
    if (
      lesson.exam &&
      lesson.exam.attempt &&
      lesson.exam.attempts &&
      lesson.exam.attempt <= lesson.exam.attempts
    ) {
      Toast.fire({
        icon: 'warning',
        title: 'Oops, parece que o limite de tentativas foi atigindo.',
        iconColor: '#FF1A50',
      });
    } else {
      setShowQuiz(true);
    }
  }, [lesson.exam]);

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((state) => !state);
  }, []);

  const handleSubmitComment = useCallback(
    async ({ comment }: IFormData) => {
      try {
        if (comment) {
          const formData = {
            content: comment,
            lesson_id: lesson.id,
          };

          const response = await api.post('doubts', formData);

          const newComment: IComment = {
            id: response.data.id,
            avatar: user.avatar ? user.avatar.avatar_url : undefined,
            name: user.name,
            content: response.data.content,
            date: formatDistanceStrict(new Date(), new Date(), {
              addSuffix: true,
              locale: ptBr,
            }),
            answersData: {
              current_page: 1,
              last_page: 0,
              data: [],
              total: 0,
            },
          };
          const newCommentsData = { ...commentsData };
          newCommentsData.data = [newComment, ...newCommentsData.data];
          newCommentsData.total += 1;
          setCommentsData(newCommentsData);

          if (formCommentRef.current) {
            formCommentRef.current.reset();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [commentsData, lesson.id, user.avatar, user.name]
  );

  const handleClickAnwser = useCallback(
    (commentIndex: number) => {
      const newCommentsData = { ...commentsData };
      if (newCommentsData.data[commentIndex].showAnswerForm) {
        newCommentsData.data[commentIndex].showAnswerForm = false;
      } else {
        newCommentsData.data[commentIndex].showAnswerForm = true;
      }
      setCommentsData(newCommentsData);
    },
    [commentsData]
  );

  const handleSubmitAnswer = useCallback(
    async ({ comment_id, comment }: IFormData, { reset }: IResetData) => {
      try {
        if (comment && comment_id) {
          const formData = {
            doubt_id: comment_id,
            content: comment,
          };

          const response = await api.post('doubts-answers', formData);

          const newAnswer: IAnswer = {
            id: response.data.id,
            avatar: user.avatar ? user.avatar.avatar_url : undefined,
            name: user.name,
            content: response.data.content,
            date: formatDistanceStrict(new Date(), new Date(), {
              addSuffix: true,
              locale: ptBr,
            }),
          };

          const newCommentsData = { ...commentsData };
          const commentIndex = newCommentsData.data.findIndex(
            (commentData) => commentData.id === comment_id
          );

          if (commentIndex >= 0) {
            newCommentsData.data[commentIndex].answersData.data = [
              newAnswer,
              ...newCommentsData.data[commentIndex].answersData.data,
            ];
            newCommentsData.data[commentIndex].answersData.total += 1;
            newCommentsData.data[commentIndex].showAnswerForm = false;
            setCommentsData(newCommentsData);
          }

          reset();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [commentsData, user.avatar, user.name]
  );

  const handleClickShowMoreComment = useCallback(() => {
    loadComments(commentsData.current_page + 1, lesson.id);
  }, [commentsData.current_page, lesson.id, loadComments]);

  const handleClickShowMoreAnswer = useCallback(
    async (commentIndex) => {
      const newCommentsData = { ...commentsData };
      const response = await loadAnswers(
        newCommentsData.data[commentIndex].answersData.current_page + 1,
        newCommentsData.data[commentIndex].id
      );
      newCommentsData.data[commentIndex].answersData.current_page =
        response.data.current_page;
      newCommentsData.data[commentIndex].answersData.data = [
        ...newCommentsData.data[commentIndex].answersData.data,
        ...response.data.data,
      ];
      newCommentsData.data[commentIndex].answersData.last_page =
        response.data.last_page;
      newCommentsData.data[commentIndex].answersData.total =
        response.data.total;
      setCommentsData(newCommentsData);
    },
    [commentsData, loadAnswers]
  );

  const handleClickLikeComment = useCallback(
    async (commentIndex) => {
      try {
        const newCommentsData = { ...commentsData };
        if (newCommentsData.data[commentIndex].doubtLike_id) {
          await api.delete(
            `doubts-likes/${newCommentsData.data[commentIndex].doubtLike_id}`
          );
          newCommentsData.data[commentIndex].doubtLike_id = undefined;
        } else {
          const response = await api.post('doubts-likes', {
            doubt_id: newCommentsData.data[commentIndex].id,
          });
          newCommentsData.data[commentIndex].doubtLike_id = response.data.id;
        }
        setCommentsData(newCommentsData);
      } catch (error) {
        console.log(error);
      }
    },
    [commentsData]
  );

  const handleClickLikeAnswer = useCallback(
    async (commentIndex, answerIndex) => {
      try {
        const newCommentsData = { ...commentsData };
        if (
          newCommentsData.data[commentIndex].answersData.data[answerIndex]
            .doubtAnswerLike_id
        ) {
          await api.delete(
            `doubts-answers-likes/${newCommentsData.data[commentIndex].answersData.data[answerIndex].doubtAnswerLike_id}`
          );
          newCommentsData.data[commentIndex].answersData.data[
            answerIndex
          ].doubtAnswerLike_id = undefined;
        } else {
          const response = await api.post('doubts-answers-likes', {
            doubtAnswer_id:
              newCommentsData.data[commentIndex].answersData.data[answerIndex]
                .id,
          });
          newCommentsData.data[commentIndex].answersData.data[
            answerIndex
          ].doubtAnswerLike_id = response.data.id;
        }
        setCommentsData(newCommentsData);
      } catch (error) {
        console.log(error);
      }
    },
    [commentsData]
  );

  const handleClickCompleteLesson = useCallback(
    async (lessonData: ILesson) => {
      try {
        const formData = {
          lesson_id: lessonData.id,
          views: lessonData.userLesson ? lessonData.userLesson.views : 0,
          time: lessonData.userLesson ? lessonData.userLesson.time : '00:00:00',
          completed: true,
        };
        const newModule = { ...module };
        const lessonIndex = newModule.lessons.findIndex(
          (dataLesson) => dataLesson.id === lessonData.id
        );
        if (lessonIndex >= 0) {
          if (newModule.lessons[lessonIndex].userLesson?.completed) {
            formData.completed = false;
          }
        }

        const response = await api.post('users-lessons', formData);

        if (lessonIndex >= 0) {
          newModule.lessons[lessonIndex].userLesson = response.data;
        }

        if (lessonData.id === lesson.id) {
          const newLesson = { ...lesson };
          newLesson.userLesson = response.data;
          setLesson(newLesson);
        }
        setModule(newModule);
        checkCertificate();
      } catch (error) {
        console.log(error);
      }
    },
    [checkCertificate, lesson, module]
  );

  const handleSubmitAnswers = useCallback(
    (questions: IQuestion[], attempt: number) => {
      questions.forEach(async (question) => {
        const formData = {
          question_id: question.id,
          answer_id: question.answer_id,
          correct: !!question.correct,
          attempt,
        };

        await api.post('users-questions', formData);
      });

      const newLesson = { ...lesson };

      if (newLesson.exam) {
        if (newLesson.exam.attempt) {
          newLesson.exam.attempt += 1;
        }
        const newQuestions: IQuestion[] = [];
        for (const question of newLesson.exam.questions) {
          if (!question.correct) {
            const questionSelected = questions.find(
              (questionData) => questionData.id === question.id
            );
            if (questionSelected) {
              question.answer_id = questionSelected.answer_id;
              question.correct = questionSelected.correct;
            }
            newQuestions.push(question);
          }
        }
      }

      setLesson(newLesson);
    },
    [lesson]
  );

  const handleTimeUpdate = useCallback(
    async (e) => {
      try {
        const videoTime = parseInt(e.target.currentTime.toFixed(0), 10);
        const callTime = 10;
        if (time !== videoTime && videoTime % callTime === 0) {
          const h = Math.floor(videoTime / 3600)
            .toFixed(0)
            .padStart(2, '0');
          const m = Math.floor((videoTime % 3600) / 60)
            .toFixed(0)
            .padStart(2, '0');
          const s = Math.floor((videoTime % 3600) % 60)
            .toFixed(0)
            .padStart(2, '0');
          const time_watched = `${h}:${m}:${s}`;

          const formData = {
            lesson_id: lesson.id,
            views: lesson.userLesson ? lesson.userLesson.views : 0,
            time: time_watched,
            completed: lesson.userLesson?.completed,
          };

          await api.post('users-lessons', formData);

          setTime(videoTime);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [lesson.id, lesson.userLesson, time]
  );

  const handleEnded = useCallback(async () => {
    try {
      const formData = {
        lesson_id: lesson.id,
        views: lesson.userLesson ? lesson.userLesson.views : 0,
        time: '00:00:00',
        completed: true,
      };
      const newModule = { ...module };
      const lessonIndex = newModule.lessons.findIndex(
        (dataLesson) => dataLesson.id === lesson.id
      );

      const response = await api.post('users-lessons', formData);

      if (lessonIndex >= 0) {
        newModule.lessons[lessonIndex].userLesson = response.data;
      }
      setModule(newModule);

      checkCertificate();

      if (autoPlay) {
        if (lessonIndex >= 0) {
          if (newModule.lessons[lessonIndex + 1]) {
            Toast.fire({
              icon: 'info',
              title: 'Carregando próxima aula.',
              iconColor: '#009D65',
            }).then(() => {
              history.push(
                `${process.env.PUBLIC_URL}${productSlug}/${path}/${
                  params.slugCourse
                }/${newModule.slug}/${newModule.lessons[lessonIndex + 1].slug}`
              );
            });
          } else if (nextModule) {
            Toast.fire({
              icon: 'info',
              title: 'Carregando próximo módulo.',
              iconColor: '#009D65',
            }).then(() => {
              history.push(
                `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slugCourse}/${nextModule.slug}/${nextModule.lessons[0].slug}`
              );
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    autoPlay,
    checkCertificate,
    history,
    lesson.id,
    lesson.userLesson,
    module,
    nextModule,
    params.slugCourse,
    productSlug,
    path,
  ]);

  const handleClickAutoPlay = useCallback(() => {
    localStorage.setItem('@Leiaut:autoPlay', (!autoPlay).toString());
    setAutoPlay(!autoPlay);
  }, [autoPlay]);

  const handleClickBack = useCallback(() => {
    if (player) {
      if (time - 10 < 0) {
        setTime(0);
      } else {
        setTime(time - 10);
      }
    }
  }, [player, time]);

  const handleClickForward = useCallback(() => {
    if (player) {
      if (time + 10 > player.duration()) {
        setTime(player.duration());
      } else {
        setTime(time + 10);
      }
    }
  }, [player, time]);

  const handleClickExpand = useCallback(() => {
    setExpanded((state) => !state);
  }, []);

  const handleClickPrev = useCallback(() => {
    if (prevModule) {
      history.push(
        `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slugCourse}/${prevModule.slug}/${prevModule.lessons[0].slug}`
      );
    }
  }, [history, params.slugCourse, path, prevModule, productSlug]);

  const handleClickNext = useCallback(() => {
    if (nextModule) {
      history.push(
        `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slugCourse}/${nextModule.slug}/${nextModule.lessons[0].slug}`
      );
    }
  }, [history, nextModule, params.slugCourse, path, productSlug]);

  return Object.keys(lesson).length > 0 ? (
    <Container className="container">
      <div className="row">
        <div className="col-12 py-5 d-flex">
          <Link
            to={`${process.env.PUBLIC_URL}/${path}/${params.slugCourse}`}
            className="btn border-0 bg-transparent"
          >
            <FiArrowLeft size={24} color="#fff" />
          </Link>
          <h1 className="text-white fw-semibold">{lesson.title}</h1>
        </div>
        <LessonContent
          expanded={expanded}
          className={expanded ? 'col-12' : 'col-xl-8'}
        >
          {lesson.video.video_url ? (
            <Video>
              <Player
                src={lesson.video.video_url}
                onReady={setPlayer}
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleEnded}
                className="w-100 player"
                autoPlay={autoPlay}
              />
              <div className="d-flex justify-content-between buttons mt-2">
                <div className="w-50 d-flex">
                  <button
                    type="button"
                    className="btn btn-dark-3 position-relative"
                    onClick={handleClickBack}
                  >
                    <BsArrowCounterclockwise size={30} color="#bbbbbb" />
                    <small className="position-absolute">10</small>
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark-3 position-relative"
                    onClick={handleClickForward}
                  >
                    <BsArrowClockwise size={30} color="#bbbbbb" />
                    <small className="position-absolute">10</small>
                  </button>
                </div>
                <div className="w-50 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn text-white position-relative"
                    onClick={handleClickAutoPlay}
                  >
                    <Toggle checked={autoPlay} />
                    Autoplay
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark-3 position-relative"
                    onClick={handleClickExpand}
                  >
                    {expanded ? (
                      <VscScreenNormal size={30} color="#bbbbbb" />
                    ) : (
                      <VscScreenFull size={30} color="#bbbbbb" />
                    )}
                  </button>
                </div>
              </div>
            </Video>
          ) : (
            <iframe
              src={`https://www.youtube.com/embed/${lesson.video.link}`}
              title={lesson.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-100 player"
            />
          )}
          {lesson.exam && (
            <button
              type="button"
              onClick={handleClickQuiz}
              className="btn btn-dark-4 w-100 py-2 mt-3 mt-xxl-5 mb-xl-4"
            >
              <span className="d-flex justify-content-center align-items-center py-2">
                <IoIosPaper size={24} color="#fff" className="me-4" />
                Realizar QUIZ
              </span>
            </button>
          )}
          {course.exam && percentCourse === 100 && (
            <Link
              to={`${process.env.PUBLIC_URL}/${path}/${params.slugCourse}/prova`}
              className="btn btn-white px-5 py-2 d-flex align-items-center justify-content-center fw-semibold w-100 w-md-auto mb-2 mb-lg-0 mt-3 order-1 order-md-0"
            >
              <IoIosPaper size={24} color="#009D65" className="me-2" />
              Realizar Teste
            </Link>
          )}
          <div className="d-block d-xl-none my-5">
            <Lessons
              slugCourse={params.slugCourse}
              module={module}
              onClickCompleteLesson={handleClickCompleteLesson}
              onClickPrev={handleClickPrev}
              disabledPrev={!prevModule}
              onClickNext={handleClickNext}
              disabledNext={!nextModule}
            />
          </div>
          <div className="mt-4">
            <Tabs className="px-5 py-3">
              <TabsButton
                type="button"
                onClick={handleClickTabsButton}
                className="d-flex d-md-none justify-content-center align-items-center w-100 border-0 bg-transparent"
              >
                {tabSelected === 'about' && 'Sobre'}
                {tabSelected === 'downloads' && 'Downloads'}
                {tabSelected === 'comments' && 'Dúvidas & comentários'}
                <IoIosArrowDown size={24} color="#bbbbbb" className="ms-2" />
              </TabsButton>
              <TabsGroup
                opened={openTabs}
                className="w-100 d-flex flex-column flex-md-row align-items-center"
              >
                <Tab
                  type="button"
                  onClick={() => handleSelectTab('about')}
                  active={tabSelected === 'about'}
                  className="border-0 bg-transparent"
                >
                  Sobre
                </Tab>
                {lesson.resources.length > 0 && (
                  <Tab
                    type="button"
                    onClick={() => handleSelectTab('downloads')}
                    active={tabSelected === 'downloads'}
                    className="border-0 bg-transparent"
                  >
                    Downloads
                  </Tab>
                )}
                {/* <Tab
                  type="button"
                  onClick={() => handleSelectTab('comments')}
                  active={tabSelected === 'comments'}
                  className="border-0 bg-transparent"
                >
                  Dúvidas & comentários
                </Tab> */}
              </TabsGroup>
            </Tabs>
            {tabSelected === 'about' && (
              <About className="px-3 px-lg-5 py-4">
                <p className="text-gray">{lesson.description}</p>
              </About>
            )}
            {tabSelected === 'downloads' && lesson.resources.length > 0 && (
              <Downloads className="py-4">
                {lesson.resources.map((resource) => (
                  <Download
                    key={resource.id}
                    href={resource.resource_url || resource.link}
                    className="d-flex justify-content-between align-items-center w-100 py-3 px-3 px-lg-5 border-0"
                    target="_blank"
                  >
                    <span>{resource.name}</span>
                    <span className="d-flex justify-content-between align-items-center">
                      Baixar
                      <IoIosDownload size={24} color="#fff" className="ms-2" />
                    </span>
                  </Download>
                ))}
              </Downloads>
            )}
            {tabSelected === 'comments' && (
              <Comments className="px-3 px-lg-5 py-4">
                <div className="px-4">
                  <h5>Dúvidas e comentários dessa aula</h5>
                  <Form
                    ref={formCommentRef}
                    onSubmit={handleSubmitComment}
                    className="d-flex mt-5"
                  >
                    <Avatar
                      src={user.avatar ? user.avatar.avatar_url : avatar}
                      className="me-4"
                    />
                    <div className="d-flex align-items-center justify-content-between input px-3 py-1">
                      <Input
                        name="comment"
                        placeholder="Comente aqui"
                        className="border-0 bg-transparent px-0"
                      />
                      <button type="submit" className="border-0 bg-transparent">
                        <IoIosSend size={24} color="#ffffff" />
                      </button>
                    </div>
                  </Form>
                </div>
                <hr className="my-5" />
                <div className="px-4">
                  {commentsData.data.length > 0 ? (
                    <>
                      {commentsData.data.map((comment, index) => (
                        <Comment key={comment.id} className="d-flex flex-wrap">
                          <Avatar
                            src={comment.avatar || avatar}
                            className="me-3"
                          />
                          <div className="content">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h6 className="mb-0 fw-semibold">
                                {comment.name}
                              </h6>
                              <span>{comment.date}</span>
                            </div>
                            <p className="text-gray">{comment.content}</p>
                            <div className="d-flex">
                              <div>
                                <button
                                  type="button"
                                  className="d-flex align-items-center bg-transparent border-0 me-4"
                                  onClick={() => handleClickLikeComment(index)}
                                >
                                  {!comment.doubtLike_id ? (
                                    <IoMdHeartEmpty
                                      size={24}
                                      color="#646464"
                                      className="me-2"
                                    />
                                  ) : (
                                    <IoMdHeart
                                      size={24}
                                      color="#FF333D"
                                      className="me-2"
                                    />
                                  )}
                                  <span>
                                    {!comment.doubtLike_id
                                      ? 'Curtir'
                                      : 'Descurtir'}
                                  </span>
                                </button>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="d-flex align-items-center bg-transparent border-0"
                                  onClick={() => handleClickAnwser(index)}
                                >
                                  <IoArrowUndoOutline
                                    size={24}
                                    color="#646464"
                                    className="me-2"
                                  />
                                  <span>Responder</span>
                                </button>
                              </div>
                            </div>
                            <AnswerForm show={!!comment.showAnswerForm}>
                              <Form
                                onSubmit={handleSubmitAnswer}
                                className="d-flex"
                              >
                                <Avatar
                                  src={
                                    user.avatar
                                      ? user.avatar.avatar_url
                                      : avatar
                                  }
                                  className="me-4"
                                />
                                <div className="d-flex align-items-center justify-content-between input px-3 py-1">
                                  <Input
                                    name="comment"
                                    placeholder="Comente aqui"
                                    className="border-0 bg-transparent px-0"
                                  />
                                  <Input
                                    type="hidden"
                                    name="comment_id"
                                    className="d-none"
                                    value={comment.id}
                                  />
                                  <button
                                    type="submit"
                                    className="border-0 bg-transparent"
                                  >
                                    <IoIosSend size={24} color="#ffffff" />
                                  </button>
                                </div>
                              </Form>
                            </AnswerForm>
                          </div>
                          <div className="answers w-100 ps-5">
                            {comment.answersData.data.map((answer, idx) => (
                              <Answer key={answer.id} className="d-flex">
                                <Avatar
                                  src={answer.avatar || avatar}
                                  className="me-3"
                                />
                                <div className="content">
                                  <div className="d-flex align-items-center justify-content-between mb-4">
                                    <h6 className="mb-0 fw-semibold">
                                      {answer.name}
                                    </h6>
                                    <span>{answer.date}</span>
                                  </div>
                                  <p className="text-gray">{answer.content}</p>
                                  <div className="d-flex">
                                    <div>
                                      <button
                                        type="button"
                                        className="d-flex align-items-center bg-transparent border-0 me-4"
                                        onClick={() =>
                                          handleClickLikeAnswer(index, idx)
                                        }
                                      >
                                        {!answer.doubtAnswerLike_id ? (
                                          <IoMdHeartEmpty
                                            size={24}
                                            color="#646464"
                                            className="me-2"
                                          />
                                        ) : (
                                          <IoMdHeart
                                            size={24}
                                            color="#FF333D"
                                            className="me-2"
                                          />
                                        )}
                                        <span>
                                          {!answer.doubtAnswerLike_id
                                            ? 'Curtir'
                                            : 'Descurtir'}
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Answer>
                            ))}
                            {comment.answersData.data.length <
                              comment.answersData.total && (
                              <button
                                type="button"
                                className="bg-transparent border-0 mt-3"
                                onClick={() => handleClickShowMoreAnswer(index)}
                              >
                                Ver mais respostas
                              </button>
                            )}
                          </div>
                        </Comment>
                      ))}
                      {commentsData.data.length < commentsData.total && (
                        <button
                          type="button"
                          className="bg-transparent border-0 mt-3"
                          onClick={handleClickShowMoreComment}
                        >
                          Ver mais comentários
                        </button>
                      )}
                    </>
                  ) : (
                    <div className="col-12 text-center py-4">
                      <h5 className="text-gray">
                        Essa aula não possui nenhum comentário
                      </h5>
                    </div>
                  )}
                </div>
              </Comments>
            )}
          </div>
        </LessonContent>
        <LessonsGroup
          expanded={expanded}
          className="col-xl-4 d-none d-xl-block px-xxl-4"
        >
          <div>
            <Lessons
              slugCourse={params.slugCourse}
              module={module}
              onClickCompleteLesson={handleClickCompleteLesson}
              onClickPrev={handleClickPrev}
              disabledPrev={!prevModule}
              onClickNext={handleClickNext}
              disabledNext={!nextModule}
            />
          </div>
        </LessonsGroup>
      </div>
      <Modal show={showQuiz} onHide={handleClose} close size="lg">
        <Modal.Header className="border-0" closeButton>
          <h4>Quiz</h4>
        </Modal.Header>
        <Modal.Body>
          {lesson.exam && (
            <Quiz
              questions={lesson.exam.questions}
              showAnswers={lesson.exam.show_answers}
              onSubmitAnswers={handleSubmitAnswers}
              started
            />
          )}
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </Container>
  ) : (
    <></>
  );
};

export default Lesson;
