import React, { useCallback, useEffect, useState } from 'react';

import { AiFillMessage } from 'react-icons/ai';
import { Container } from './styles';
import Search from '~/components/Search';
import api from '~/services/api';

interface IQuestion {
  id: string;
  question: string;
  answer: string;
}

interface IQuestionData {
  current_page: number;
  last_page: number;
  data: IQuestion[];
}

const Support: React.FC = () => {
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadQuestions = useCallback(
    async (pageData: number, searchData?: string) => {
      const response = await api.get<IQuestionData>(`faqs`, {
        params: {
          page: pageData,
          search: searchData,
        },
      });

      if (pageData === 1) {
        setQuestions(response.data.data);
      } else {
        setQuestions((state) => [...state, ...response.data.data]);
      }

      setLastPage(response.data.last_page);
    },
    []
  );

  useEffect(() => {
    loadQuestions(1);
  }, [loadQuestions]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadQuestions(page + 1, search);
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [lastPage, loadQuestions, page, search]);

  const handleSearch = useCallback(
    (value) => {
      loadQuestions(1, value);
      setSearch(value);
    },
    [loadQuestions]
  );

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container py-5">
        <div className="row justify-content-between align-items-center pt-5">
          <div className="col-md-6 mb-5 pb-4">
            <hr className="bg-primary" />
            <h1 className="display-4 display-lg-3 fw-medium text-white my-4">
              Suporte
            </h1>
            <p>COMO PODEMOS AJUDAR?</p>
          </div>
          <div className="col-sm-7 col-md-6 col-lg-5 col-xl-4 mb-5 pb-4 ms-sm-auto ms-md-0">
            <Search onSearch={handleSearch} />
          </div>
        </div>
        <div className="row align-items-center pb-5">
          <div className="col-12 mb-5">
            <hr />
          </div>
          {questions.map((question) => (
            <div key={question.id} className="col-xl-9 mb-4">
              <p className="fw-bold h5">{question.question}</p>
              <p className="text-gray">{question.answer}</p>
            </div>
          ))}
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary rounded-circle p-4 position-fixed"
      >
        <AiFillMessage size={40} color="#fff" />
      </button>
    </Container>
  );
};

export default Support;
