import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  > div {
    transition-duration: 0.3s;
    border-radius: 15px;

    a {
      border-radius: 15px;

      span {
        transition-duration: 0.3s;
        color: #bbbbbb;
      }
    }

    a.active {
      background: #303030;

      span {
        color: #ffffff;
      }
    }

    :hover {
      background: #303030;

      span {
        color: #ffffff;
      }
    }

    button {
      top: 50%;
      right: 100px;
      transform: translateY(-50%);
      height: 100%;
      width: 30px;

      svg {
        position: absolute;
        transition-duration: 0.3s;
        opacity: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      svg.active {
        opacity: 1;
      }

      span {
        transition-duration: 0.3s;
        background-color: #3a3a3a;
        position: absolute;
        width: 300px;
        top: -55%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);

        :after {
          content: '';
          position: absolute;
          background-color: #3a3a3a;
          bottom: -6px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          width: 10px;
          height: 10px;
        }
      }

      span.show {
        opacity: 1;
        visibility: visible;
      }
    }

    + div {
      margin-top: 10px;
    }
  }
`;
