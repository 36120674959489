import React, { useCallback, useEffect, useState } from 'react';
import { IoIosCheckmark } from 'react-icons/io';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';

import { FiArrowLeft } from 'react-icons/fi';
import api from '~/services/api';
import { initializeSocketEvents } from '~/services/socket';
import { formatPrice } from '~/utils/format';

import { Container, Box, BoxProduct } from './styles';

import logo from '~/assets/logos/logo-gray.svg';
import Loading from '~/components/Loading';
import { useAuth } from '~/hooks/Auth';
import { useProduct } from '~/hooks/Product';

interface IPlanResponse {
  id: string;
  name: string;
  description: string;
  features: string;
  recommended: string;
  price: number;
  has_discount: boolean;
  discount?: number;
  checkout_url: string;
}

interface IProductResponse {
  id: string;
  title: string;
  description: string;
  features?: string;
  recommended?: string;
  price?: number;
  has_discount?: boolean;
  discount?: number;
  checkout_url?: string;
  plans?: IPlanResponse[];
  slug: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IPlan {
  id: string;
  name: string;
  description: string;
  features: string[];
  recommended: string;
  checkout_url: string;
  has_discount: boolean;
  discount?: string;
  price: string;
}

interface IProduct {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  features?: string[];
  recommended?: string;
  price?: string;
  has_discount?: boolean;
  discount?: string;
  checkout_url?: string;
  slug: string;
  plans?: IPlan[];
}

interface IParams {
  productSlug: string;
}

const Products: React.FC = () => {
  const { user } = useAuth();
  const { product: productData, setProduct: setProductPaid } = useProduct();
  const params = useParams<IParams>();
  const history = useHistory();
  const [product, setProduct] = useState({} as IProduct);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    api
      .get<IProductResponse>(`products/${params.productSlug}`)
      .then((response) => {
        const plans: IPlan[] | undefined =
          response.data.plans && response.data.plans.length > 0
            ? response.data.plans.map((plan) => ({
                id: plan.id,
                name: plan.name,
                description: plan.description,
                features: plan.features.split(';'),
                recommended: plan.recommended,
                price: formatPrice(plan.price),
                has_discount: plan.has_discount,
                discount: plan.discount
                  ? formatPrice(plan.discount)
                  : undefined,
                checkout_url: plan.checkout_url,
              }))
            : undefined;
        const data: IProduct = {
          id: response.data.id,
          title: response.data.title,
          description: response.data.description,
          thumbnail: response.data.thumbnail.thumbnail_url,
          features: response.data.features
            ? response.data.features.split(';')
            : undefined,
          recommended: response.data.recommended,
          price: response.data.price
            ? formatPrice(response.data.price)
            : undefined,
          has_discount: response.data.has_discount,
          discount: response.data.discount
            ? formatPrice(response.data.discount)
            : undefined,
          plans,
          checkout_url: response.data.checkout_url,
          slug: response.data.slug,
        };

        setProduct(data);
      });
  }, [params.productSlug]);

  const handlePaid = useCallback(() => {
    try {
      setShowLoading(false);
      if (user) {
        if (
          params.productSlug === 'leiaut-flix' ||
          params.productSlug === 'leiautflix'
        ) {
          history.push(`${process.env.PUBLIC_URL}/dashboard`);
        } else {
          history.push(
            `${process.env.PUBLIC_URL}/${params.productSlug}/dashboard`
          );
        }
      } else {
        history.push(`${process.env.PUBLIC_URL}/login`);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro ao logar, verifique seus dados.',
        'error'
      );
    }
  }, [history, params.productSlug, user]);

  const handleCreateProductOrder = useCallback(
    async (dataProduct: IProduct, user_id: string) => {
      try {
        let amount_paid = 0;
        if (dataProduct.has_discount && dataProduct.discount) {
          amount_paid = parseFloat(
            dataProduct.discount
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          );
        } else if (dataProduct.price) {
          amount_paid = parseFloat(
            dataProduct.price
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          );
        }

        const formData = {
          product_id: dataProduct.id,
          user_id,
          payment_type: 'Credit card',
          amount_paid,
          is_recurrent: false,
          status: 'Awaiting Payment',
        };

        const response = await api.post('orders', formData);

        return response;
      } catch (error) {
        console.log(error);
      }

      return undefined;
    },
    []
  );

  const handleClickPayProduct = useCallback(
    async (dataProduct: IProduct) => {
      try {
        if (user && Object.keys(user).length > 0) {
          const responseOrder = await handleCreateProductOrder(
            dataProduct,
            user.id
          );

          if (responseOrder) {
            if (
              (dataProduct.price && dataProduct.price === 'R$ 0,00') ||
              (dataProduct.discount && dataProduct.discount === 'R$ 0,00')
            ) {
              const formData = {
                product_id: responseOrder.data.product_id,
                user_id: responseOrder.data.user_id,
                payment_type: responseOrder.data.payment_type,
                amount_paid: responseOrder.data.amount_paid,
                is_recurrent: responseOrder.data.is_recurrent,
                status: 'Paid',
              };
              await api.put(`orders/${responseOrder.data.id}`, formData);

              const formDataUserProduct = {
                user_id: formData.user_id,
                product_id: formData.product_id,
              };

              await api.post('users-products', formDataUserProduct);

              handlePaid();
            } else {
              setShowLoading(true);
              window.open(dataProduct.checkout_url, '_blank');
              initializeSocketEvents({
                onPaid: handlePaid,
                order_id: responseOrder.data.id,
                user_id: responseOrder.data.user_id,
              });
            }
          }
        } else {
          initializeSocketEvents({
            onPaid: handlePaid,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [handleCreateProductOrder, handlePaid, user]
  );

  const handleCreatePlanOrder = useCallback(
    async (plan: IPlan, user_id: string) => {
      try {
        let amount_paid = 0;
        if (plan.has_discount && plan.discount) {
          amount_paid = parseFloat(
            plan.discount
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          );
        } else {
          amount_paid = parseFloat(
            plan.price.replace('R$', '').replaceAll('.', '').replace(',', '.')
          );
        }

        const formData = {
          product_id: product.id,
          plan_id: plan.id,
          user_id,
          payment_type: 'Credit card',
          amount_paid,
          is_recurrent: true,
          status: 'Awaiting Payment',
        };

        const response = await api.post('orders', formData);

        return response;
      } catch (error) {
        console.log(error);
      }

      return undefined;
    },
    [product.id]
  );

  const handleClickPayPlan = useCallback(
    async (plan: IPlan) => {
      try {
        if (user && Object.keys(user).length > 0) {
          const responseOrder = await handleCreatePlanOrder(plan, user.id);

          if (responseOrder) {
            if (plan.price === 'R$ 0,00' || plan.discount === 'R$ 0,00') {
              const formData = {
                product_id: responseOrder.data.product_id,
                plan_id: responseOrder.data.plan_id,
                user_id: responseOrder.data.user_id,
                payment_type: responseOrder.data.payment_type,
                amount_paid: responseOrder.data.amount_paid,
                is_recurrent: responseOrder.data.is_recurrent,
                status: 'Paid',
              };
              await api.put(`orders/${responseOrder.data.id}`, formData);

              const formDataUserProduct = {
                user_id: formData.user_id,
                product_id: formData.product_id,
                plan_id: formData.plan_id,
              };

              await api.post('users-products', formDataUserProduct);

              setProductPaid({
                id: product.id,
                title: product.title,
                description: product.description,
                price: 0,
                slug: product.slug,
                hasPlans: true,
                lock: false,
                thumbnail: {
                  thumbnail_url: product.thumbnail,
                },
                plan: {
                  id: plan.id,
                  name: plan.name,
                  price: parseFloat(plan.price),
                },
                plans: product.plans as any,
              });

              handlePaid();
            } else {
              setShowLoading(true);
              window.open(plan.checkout_url, '_blank');
              initializeSocketEvents({
                onPaid: handlePaid,
                order_id: responseOrder.data.id,
                user_id: responseOrder.data.user_id,
              });
            }
          }
        } else {
          initializeSocketEvents({
            onPaid: handlePaid,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [handleCreatePlanOrder, handlePaid, product, setProductPaid, user]
  );

  return (
    <>
      <Container
        show
        className="bg-dark-1 d-flex align-items-center justify-content-center py-5"
      >
        <div className="container">
          <div
            className={`row ${
              !product.plans || (product.plans && product.plans.length === 1)
                ? 'justify-content-center'
                : ''
            }`}
          >
            <div className="col-12 mb-4 mb-lg-5 pb-4">
              <div className="d-flex align-items-center">
                <Link to={`${process.env.PUBLIC_URL}/produtos`}>
                  <FiArrowLeft size={24} color="#fff" />
                </Link>
              </div>
            </div>
            <div className="col-12">
              <h2 className="fw-semibold text-center mb-5">
                {product.plans && product.plans.length > 0
                  ? 'Escolha seu plano'
                  : 'Pagamento'}
              </h2>
            </div>
            {product.plans && product.plans.length > 0 ? (
              <>
                {product.plans.map((plan) => (
                  <>
                    {product.plans && product.plans.length === 1 && (
                      <div className="col-lg-3">
                        <BoxProduct
                          src={product.thumbnail}
                          className="w-100 h-100 px-4 px-sm-5 py-3 bg-dark-4 d-flex flex-column justify-content-end"
                        >
                          <p className="h5">{product.title}</p>
                          <p className="text-gray">{product.description}</p>
                        </BoxProduct>
                      </div>
                    )}
                    <div
                      key={plan.id}
                      className={`
                ${
                  ((product.plans && product.plans.length === 1) ||
                    plan.recommended) &&
                  product.plans &&
                  product.plans.length % 3 !== 0
                    ? 'col-lg-8'
                    : 'col-lg-4'
                } ${product.plans && product.plans.length > 3 ? 'mb-4' : ''}`}
                    >
                      <Box
                        className="w-100 h-100 p-4 p-sm-5"
                        recommended={
                          !!(
                            (product.plans && product.plans.length === 1) ||
                            plan.recommended
                          )
                        }
                      >
                        <div className="d-flex flex-column flex-md-row align-items-end align-items-md-start justify-content-between">
                          <div className="align-self-start">
                            <h3 className="fw-semibold">{plan.name}</h3>
                            <p className="text-gray">{plan.description}</p>
                          </div>
                          <span className="btn btn-primary px-3 d-block mt-3 mt-md-0">
                            {plan.price === 'R$ 0,00' ? 'Grátis' : plan.price}
                          </span>
                        </div>
                        <div className="row">
                          {plan.features.map((feature) => (
                            <div key={feature}>
                              <div className="d-flex">
                                <IoIosCheckmark size={24} color="#fff" />
                                <p>{feature}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="d-flex align-items-end justify-content-between">
                          <button
                            type="button"
                            className={`btn fw-semibold ${
                              product.plans && product.plans.length === 1
                                ? 'px-3 px-lg-5'
                                : 'w-100'
                            }`}
                            onClick={() => handleClickPayPlan(plan)}
                            disabled={
                              productData &&
                              productData.plan &&
                              productData.plan.id === plan.id
                            }
                          >
                            {productData &&
                            productData.plan &&
                            productData.plan.id === plan.id ? (
                              'Selecionado'
                            ) : (
                              <>
                                {plan.price === 'R$ 0,00'
                                  ? 'Adquirir grátis'
                                  : `Escolher plano${
                                      product.plans &&
                                      product.plans.length % 3 !== 0
                                        ? ` ${plan.price}`
                                        : ''
                                    }`}
                              </>
                            )}
                          </button>
                          <div className="logo mt-4">
                            <img src={logo} alt="Logo" className="mb-n4" />
                          </div>
                        </div>
                      </Box>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                <div className="col-lg-3 mt-4 mt-lg-0">
                  <BoxProduct
                    src={product.thumbnail}
                    className="w-100 h-100 px-4 px-sm-5 py-3 bg-dark-4 d-flex flex-column justify-content-end"
                  />
                </div>
                <div className="col-lg-7 mt-4 mt-lg-0">
                  <Box
                    recommended
                    className="w-100 h-100 p-4 p-sm-5 bg-dark-4 d-flex flex-column justify-content-between"
                  >
                    <div className="d-flex flex-column flex-md-row align-items-end align-items-md-start justify-content-between">
                      <div className="align-self-start">
                        <h3 className="fw-semibold h1">{product.title}</h3>
                        <p className="text-gray">{product.description}</p>
                      </div>
                      <span className="btn btn-primary px-3 d-block mt-3 mt-md-0">
                        {product.price ? (
                          <>
                            {product.price === 'R$ 0,00'
                              ? 'Grátis'
                              : product.price}
                          </>
                        ) : (
                          'Grátis'
                        )}
                      </span>
                    </div>
                    <div className="row">
                      {product.features &&
                        product.features.map((feature) => (
                          <div key={feature}>
                            <div className="d-flex">
                              <IoIosCheckmark size={24} color="#fff" />
                              <p>{feature}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <button
                        type="button"
                        className="btn px-3 px-lg-5 fw-semibold"
                        onClick={() => handleClickPayProduct(product)}
                      >
                        {product.price ? (
                          <>
                            {product.price === 'R$ 0,00'
                              ? 'Adquirir grátis'
                              : `Escolher plano ${product.price}`}
                          </>
                        ) : (
                          'Adquirir grátis'
                        )}
                      </button>
                      <img src={logo} alt="Logo" />
                    </div>
                  </Box>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
      <Loading
        show={showLoading}
        message="Aguardando a confirmação de pagamento"
      >
        <button
          type="button"
          className="btn btn-primary px-5 py-2 mt-3"
          onClick={() => setShowLoading(false)}
        >
          Cancelar
        </button>
      </Loading>
    </>
  );
};

export default Products;
