import styled from 'styled-components';

interface IAvatar {
  src: string;
}

interface ICategoryCard {
  src: string;
}

export const Container = styled.div`
  .slick-arrow {
    height: 100% !important;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 59px;
  height: 59px;
  border-radius: 50%;
`;

export const CategoryCard = styled.button<ICategoryCard>`
  background: linear-gradient(
      188.52deg,
      rgba(44, 44, 44, 0.38) 33.3%,
      #2b2b2b 95.76%
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 100%;
  height: 300px;
  border-radius: 26px;
  border: none;
  text-align: left;

  + button {
    margin-left: 10px;
  }
`;
