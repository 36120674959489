import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import List from 'react-chatview';
import Lottie from 'react-lottie';
import axios, { CancelTokenSource } from 'axios';

import { IoMdLock } from 'react-icons/io';
import api from '~/services/api';

import { Container, Courses, SearchCourses, AutoComplete } from './styles';
import Search from '~/components/Search';
import Slider from '~/components/Slider';
import Course from '~/components/Course';

import magnifier from '~/assets/animations/magnifier.json';
import { useProduct } from '~/hooks/Product';

interface ICourse {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
  percent?: number;
  lock?: boolean;
}

interface ICourseData {
  current_page: number;
  last_page: number;
  data: ICourse[];
}

interface IParams {
  productSlug: string;
}

let cancelTokenCourse: CancelTokenSource | undefined;
let cancelTokenCourseName: CancelTokenSource | undefined;

const ProductDashboard: React.FC = () => {
  const { product } = useProduct();
  const params = useParams<IParams>();
  const searchRef = useRef<HTMLInputElement>(null);
  const searchCourseRef = useRef<HTMLInputElement>(null);
  const [continueWatchingCourses, setContinueWatchingCourses] = useState<
    ICourse[]
  >([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState('');
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [loadingMoreCourse, setLoadingMoreCourse] = useState(false);
  const [coursesName, setCoursesName] = useState<string[]>([]);
  const [productId, setProductId] = useState('');
  const [productTitle, setProductTitle] = useState('');

  const loadCourses = useCallback(
    (productIdData: string, pageData: number, searchData: string) => {
      cancelTokenCourse = axios.CancelToken.source();
      api
        .get<ICourseData>(`courses/products/${productIdData}`, {
          cancelToken: cancelTokenCourse.token,
          params: {
            page: pageData,
            search: searchData,
            plan: product && product.plan?.id,
          },
        })
        .then((response) => {
          if (pageData === 1) {
            setCourses(response.data.data);
            setPage(1);
          } else {
            setCourses((state) => [...state, ...response.data.data]);
          }
          setLastPage(response.data.last_page);
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            console.log(error);
          }
        });
    },
    [product]
  );

  useEffect(() => {
    api.get(`products/${params.productSlug}`).then(async (response) => {
      const responseContinueWatching = await api.get<ICourse[]>(
        `courses/continue-watching`,
        {
          params: {
            product_id: response.data.id,
          },
        }
      );

      setContinueWatchingCourses(responseContinueWatching.data);

      setProductId(response.data.id);
      setProductTitle(response.data.title);
      loadCourses(response.data.id, 1, '');
    });
  }, [loadCourses, params.productSlug]);

  const loadCourseName = useCallback((value: string, productIdData: string) => {
    cancelTokenCourseName = axios.CancelToken.source();
    if (value.length > 0) {
      api
        .get('courses/name', {
          cancelToken: cancelTokenCourseName.token,
          params: {
            search: value,
            productId: productIdData,
          },
        })
        .then((response) => {
          if (response.data.length) {
            setCoursesName(response.data);
          }
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            console.log(error);
          }
        });
    } else {
      setCoursesName([]);
    }
  }, []);

  const handleSearch = useCallback(
    async (value) => {
      if (cancelTokenCourse) {
        cancelTokenCourse.cancel('canceled');
      }

      if (cancelTokenCourseName) {
        cancelTokenCourseName.cancel('canceled');
      }

      if (searchCourseRef.current && value.length > 0) {
        searchCourseRef.current.focus();
      } else if (searchRef.current) {
        searchRef.current.focus();
      }

      loadCourseName(value, productId);
      loadCourses(productId, 1, value);
      setSearch(value);
    },
    [loadCourseName, loadCourses, productId]
  );

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        setLoadingMoreCourse(true);
        await loadCourses(productId, page + 1, search);
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingMoreCourse(false);
    }
  }, [lastPage, loadCourses, page, productId, search]);

  const handleClickCourseName = useCallback(
    async (value) => {
      if (value.length > 0) {
        const response = await api.get('courses/name', {
          params: {
            search: value,
          },
        });

        if (response.data.length) {
          setCoursesName(response.data);
        }
      } else {
        setCoursesName([]);
      }

      loadCourses(productId, 1, value);
      setSearch(value);
    },
    [loadCourses, productId]
  );

  return (
    <Container
      className="content"
      scrollLoadThreshold={100}
      onInfiniteLoad={handleLoad}
    >
      <div className="container">
        <div className="row align-items-center my-5 py-5">
          <div className="col-lg-5">
            <h1 className="fw-semibold text-white mb-0">{productTitle}</h1>
          </div>
          <div className="col-lg-7">
            <Search
              searchRef={searchRef}
              onSearch={handleSearch}
              value={search}
            />
          </div>
        </div>
        {continueWatchingCourses.length > 0 && (
          <Courses className="mt-4 mt-lg-5">
            <h2 className="text-white h5 ms-3 mb-5">Continue assistindo</h2>
            <Slider>
              {continueWatchingCourses.map((course) => (
                <Course
                  key={course.id}
                  to={`${process.env.PUBLIC_URL}/${params.productSlug}/cursos/${course.slug}`}
                  thumb={course.thumbnail}
                  title={course.title}
                  percent={course.percent || 0}
                />
              ))}
            </Slider>
          </Courses>
        )}
        <div className="row">
          <div className="col-12">
            <h2 className="text-white h5 ms-3 mb-5">Cursos</h2>
          </div>
          {courses.map((course) => (
            <div key={course.id} className="col-lg-4">
              <Course
                key={course.id}
                type={course.lock ? 'upgrade' : 'link'}
                to={`${process.env.PUBLIC_URL}/${params.productSlug}/cursos/${course.slug}`}
                thumb={course.thumbnail}
                title={course.title}
                icon={course.lock ? IoMdLock : undefined}
                message="Curso bloqueado, faça o upgrade de seu plano"
              />
            </div>
          ))}
        </div>
      </div>
      <SearchCourses show={search.length > 0} className="bg-dark-2 py-5">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-3">
              <Search
                searchRef={searchCourseRef}
                onSearch={handleSearch}
                value={search}
              />
              <AutoComplete className="d-flex flex-column align-items-start">
                {coursesName.map((courseName, index) => (
                  <button
                    key={index.toString()}
                    type="button"
                    onClick={() => handleClickCourseName(courseName)}
                    className="border-0 bg-transparent px-3 pt-3 pb-0"
                  >
                    {courseName}
                  </button>
                ))}
              </AutoComplete>
            </div>
            {courses.length > 0 ? (
              <List
                scrollLoadThreshold={100}
                onInfiniteLoad={handleLoad}
                className="col-md-9 custom-height"
              >
                <div className="row">
                  {courses.map((course) => (
                    <div key={course.id} className="col-md-4">
                      <Course
                        key={course.id}
                        type={course.lock ? 'upgrade' : 'link'}
                        to={`${process.env.PUBLIC_URL}/${params.productSlug}/cursos/${course.slug}`}
                        thumb={course.thumbnail}
                        title={course.title}
                        icon={course.lock ? IoMdLock : undefined}
                        message="Curso bloqueado, faça o upgrade de seu plano"
                      />
                    </div>
                  ))}
                </div>
                {loadingMoreCourse && (
                  <div className="row pb-5">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                )}
              </List>
            ) : (
              <div className="col-md-9 h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="animation">
                  <Lottie
                    options={{
                      animationData: magnifier,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={250}
                    width={375}
                  />
                </div>
                <h3 className="mt-4">
                  Desculpe não encontramos cursos para essa busca
                </h3>
              </div>
            )}
          </div>
        </div>
      </SearchCourses>
    </Container>
  );
};

export default ProductDashboard;
