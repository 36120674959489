import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { MdFacebook } from 'react-icons/md';
import { RiEditFill, RiInstagramFill } from 'react-icons/ri';
import { ImLinkedin2, ImYoutube } from 'react-icons/im';
import {
  IoIosAdd,
  IoIosArrowDown,
  IoIosBriefcase,
  IoIosMail,
  IoMdLink,
} from 'react-icons/io';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { HiCamera } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';
import { Form } from '@unform/web';
import { differenceInYears, format, parseISO } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import axios, { AxiosResponse } from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { TiStarFullOutline } from 'react-icons/ti';
import { VscArrowSmallRight } from 'react-icons/vsc';
import getValidationErros from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import mask from '~/utils/mask';
import { formatPrice } from '~/utils/format';

import {
  Container,
  Box,
  Banner,
  Avatar,
  Tabs,
  TabsButton,
  TabsGroup,
  Tab,
  PersonalData,
  Address,
  Payments,
  Notifications,
  Modal,
  SocialMedias,
} from './styles';
import InputToggle from '~/components/InputToggle';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputRadio from '~/components/InputRadio';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import InputPhoto from '~/components/InputPhoto';
import Table, { IColumn } from '~/components/Table';

import avatar from '~/assets/defaults/avatar.png';
import { useProduct } from '~/hooks/Product';

interface IProfileData {
  name: string;
  email: string;
  birthdate: string;
  occupation: string;
  biography?: string;
  socialEmail?: string;
  youtube?: string;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  portfolio?: string;
  twitter?: string;
}

interface IPersonalData {
  email: string;
  password?: string;
  phone?: string;
  foreign: string;
  document?: string;
}

interface IAddressData {
  zipcode?: string;
  street?: string;
  number?: string;
  neighborhood?: string;
  city: string;
  state: string;
  complement?: string;
}

interface INotificationOptionResponse {
  id: string;
  content: string;
  userNotificationOption?: {
    user_id: string;
    notification_option_id: string;
    active?: boolean;
  };
}

interface INotificationOption {
  id: string;
  value: string;
}

interface IOrder {
  id: string;
  amount_paid: number;
  payment_type: string;
  is_recurrent: boolean;
  status: string;
  created_at: string;
  courseSale: {
    course: {
      title: string;
    };
  };
  certificate: {
    course: {
      title: string;
    };
  };
  product: {
    title: string;
  };
  plan: {
    name: string;
  };
}

interface IOrderResponse {
  data: IOrder[];
  from: number;
  to: number;
  total: number;
}

interface IPayment {
  id: number;
  title: string;
  amount_paid: string;
  payment_type: string;
  is_recurrent: string;
  status: string;
  date: string;
}

const Profile: React.FC = () => {
  const location = useLocation();
  const { product } = useProduct();
  const { user, updateUser } = useAuth();
  const formEditProfileRef = useRef<FormHandles>(null);
  const formEditPersonalDataRef = useRef<FormHandles>(null);
  const formEditAddressRef = useRef<FormHandles>(null);
  const [tabSelected, setTabSelected] = useState('personal-data');
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditPersonalData, setShowEditPersonalData] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [showEditBanner, setShowEditBanner] = useState(false);
  const [showEditAvatar, setShowEditAvatar] = useState(false);
  const [showSocialEmail, setShowSocialEmail] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showFacebook, setShowFacebook] = useState(false);
  const [showInstagram, setShowInstagram] = useState(false);
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [showTwitter, setShowTwitter] = useState(false);
  const [openTabs, setOpenTabs] = useState(false);
  const [biographyQtd, setBiographyQtd] = useState(0);
  const [spacesQtd, setSpacesQtd] = useState(0);
  const [foreign, setForeign] = useState(false);
  const [address, setAddress] = useState<IAddressData>({} as IAddressData);
  const [wallpaper, setWallpaper] = useState<File | undefined>(undefined);
  const [avatarData, setAvatarData] = useState<File | undefined>(undefined);
  const [notificationOptions, setNotificationOptions] = useState<
    INotificationOption[]
  >([]);
  const [notificationOptionsSelected, setNotificationOptionsSelected] =
    useState<string[]>([]);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [paymentsFrom, setPaymentsFrom] = useState(0);
  const [paymentsTo, setPaymentsTo] = useState(0);
  const [paymentsTotal, setPaymentsTotal] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (product && product.plan && product.plans) {
      const planData = product.plans.find(
        (plan) => product.plan && plan.id === product.plan.id
      );
      console.log(planData);
    }
  }, [product]);

  useEffect(() => {
    api
      .get<IOrderResponse>('orders/students', {
        params: {
          page,
        },
      })
      .then((response) => {
        const data: IPayment[] = response.data.data.map((payment, index) => {
          let title = '';
          if (payment.courseSale) {
            title = payment.courseSale.course.title;
          }

          if (payment.certificate) {
            title = `Certificado do curso ${payment.certificate.course.title}`;
          }

          if (payment.product) {
            title = payment.product.title;
          }

          if (payment.plan && payment.product) {
            title = `Plano ${payment.plan.name} do produto ${payment.product.title}`;
          }

          let status = 'Aguardando Pagamento';
          if (payment.status === 'Paid') {
            status = 'Pago';
          }

          return {
            id: index + 1,
            title,
            amount_paid:
              payment.amount_paid > 0
                ? formatPrice(payment.amount_paid)
                : 'Grátis',
            payment_type:
              payment.payment_type === 'Credit card'
                ? 'Cartão de Crédito'
                : 'Boleto',
            is_recurrent: payment.is_recurrent ? 'Sim' : 'Não',
            status,
            date: format(parseISO(payment.created_at), 'dd/MM/yyyy'),
          };
        });

        setPayments(data);
        setPaymentsFrom(response.data.from);
        setPaymentsTo(response.data.to);
        setPaymentsTotal(response.data.total);
      });
  }, [page]);

  useEffect(() => {
    if (user.address) {
      setAddress({
        zipcode: user.address.zipcode,
        street: user.address.street,
        number: user.address.number,
        neighborhood: user.address.neighborhood,
        city: user.address.city,
        state: user.address.state,
        complement: user.address.complement,
      });
    }
  }, [user.address]);

  useEffect(() => {
    api
      .get<INotificationOptionResponse[]>('notification-options')
      .then((response) => {
        const data: INotificationOption[] = response.data.map(
          (notificationOption) => ({
            id: notificationOption.id,
            value: notificationOption.content,
          })
        );

        const userNotificationOptionsId = response.data
          .filter(
            (notificationOption) =>
              notificationOption.userNotificationOption &&
              notificationOption.userNotificationOption.active
          )
          .map((notificationOption) => notificationOption.id);

        setNotificationOptionsSelected(userNotificationOptionsId);
        setNotificationOptions(data);
      });
  }, []);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Título',
        selector: 'title',
      },
      {
        name: 'Valor pago',
        selector: 'amount_paid',
      },
      {
        name: 'Tipo de pagamento',
        selector: 'payment_type',
      },
      {
        name: 'É um plano?',
        selector: 'is_recurrent',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Data de pagamento',
        selector: 'date',
      },
    ],
    []
  );

  const age = useMemo(() => {
    return user.birthdate
      ? differenceInYears(new Date(), parseISO(user.birthdate))
      : 0;
  }, [user.birthdate]);

  const profileData = useMemo(() => {
    return {
      name: user.name,
      email: user.email,
      birthdate: user.birthdate
        ? format(parseISO(user.birthdate), 'dd/MM/yyyy')
        : 'Não informado',
      occupation: user.occupation,
      biography: user.biography,
      socialEmail: user.socialNetworks.find(
        (socialNetwork) => socialNetwork.type === 'socialEmail'
      )?.link,
      youtube: user.socialNetworks.find(
        (socialNetwork) => socialNetwork.type === 'youtube'
      )?.link,
      linkedin: user.socialNetworks.find(
        (socialNetwork) => socialNetwork.type === 'linkedin'
      )?.link,
      facebook: user.socialNetworks.find(
        (socialNetwork) => socialNetwork.type === 'facebook'
      )?.link,
      instagram: user.socialNetworks.find(
        (socialNetwork) => socialNetwork.type === 'instagram'
      )?.link,
      portfolio: user.socialNetworks.find(
        (socialNetwork) => socialNetwork.type === 'portfolio'
      )?.link,
      twitter: user.socialNetworks.find(
        (socialNetwork) => socialNetwork.type === 'twitter'
      )?.link,
    };
  }, [
    user.biography,
    user.birthdate,
    user.email,
    user.name,
    user.occupation,
    user.socialNetworks,
  ]);

  const personalData = useMemo(() => {
    setForeign(user.foreign);
    return {
      email: user.email,
      phone: user.phone,
      foreign: user.foreign,
      document: user.document.replaceAll('.', '').replace('-', ''),
    };
  }, [user.document, user.email, user.foreign, user.phone]);

  const states = useMemo<IOption[]>(
    () => [
      {
        id: 0,
        value: 'Selecione',
        selected: !address.state,
        notSelectable: true,
      },
      { id: 'AC', value: 'Acre', selected: address.state === 'AC' },
      { id: 'AL', value: 'Alagoas', selected: address.state === 'AL' },
      { id: 'AP', value: 'Amapá', selected: address.state === 'AP' },
      { id: 'AM', value: 'Amazonas', selected: address.state === 'AM' },
      { id: 'BA', value: 'Bahia', selected: address.state === 'BA' },
      { id: 'CE', value: 'Ceará', selected: address.state === 'CE' },
      { id: 'DF', value: 'Distrito Federal', selected: address.state === 'DF' },
      { id: 'ES', value: 'Espírito Santo', selected: address.state === 'ES' },
      { id: 'GO', value: 'Goiás', selected: address.state === 'GO' },
      { id: 'MA', value: 'Maranhão', selected: address.state === 'MA' },
      { id: 'MT', value: 'Mato Grosso', selected: address.state === 'MT' },
      {
        id: 'MS',
        value: 'Mato Grosso do Sul',
        selected: address.state === 'MS',
      },
      { id: 'MG', value: 'Minas Gerais', selected: address.state === 'MG' },
      { id: 'PA', value: 'Pará', selected: address.state === 'PA' },
      { id: 'PB', value: 'Paraíba', selected: address.state === 'PB' },
      { id: 'PR', value: 'Paraná', selected: address.state === 'PR' },
      { id: 'PE', value: 'Pernambuco', selected: address.state === 'PE' },
      { id: 'PI', value: 'Piauí', selected: address.state === 'PI' },
      { id: 'RJ', value: 'Rio de Janeiro', selected: address.state === 'RJ' },
      {
        id: 'RN',
        value: 'Rio Grande do Norte',
        selected: address.state === 'RN',
      },
      {
        id: 'RS',
        value: 'Rio Grande do Sul',
        selected: address.state === 'RS',
      },
      { id: 'RO', value: 'Rondônia', selected: address.state === 'RO' },
      { id: 'RR', value: 'Roraima', selected: address.state === 'RR' },
      { id: 'SC', value: 'Santa Catarina', selected: address.state === 'SC' },
      { id: 'SP', value: 'São Paulo', selected: address.state === 'SP' },
      { id: 'SE', value: 'Sergipe', selected: address.state === 'SE' },
      { id: 'TO', value: 'Tocantins', selected: address.state === 'TO' },
    ],
    [address.state]
  );

  const portfolioSite = useMemo(() => {
    return user.socialNetworks.find(
      (socialNetwork) => socialNetwork.type === 'portfolio'
    );
  }, [user.socialNetworks]);

  const handleSelectTab = useCallback((value) => {
    setTabSelected(value);
    setOpenTabs(false);
  }, []);

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((state) => !state);
  }, []);

  const handleClose = useCallback(() => {
    setShowEditProfile(false);
    setShowEditPersonalData(false);
    setShowEditAddress(false);
    setShowEditBanner(false);
    setShowEditBanner(false);
    setShowEditAvatar(false);
    setWallpaper(undefined);
    setAvatarData(undefined);
  }, []);

  const handleChangeBiography = useCallback((e) => {
    setBiographyQtd(e.target.value.replace(/\s/g, '').length);
    setSpacesQtd(e.target.value.split(' ').length - 1);
  }, []);

  const handleClickLink = useCallback((socialMedia) => {
    switch (socialMedia) {
      case 'socialEmail':
        setShowSocialEmail(true);
        break;
      case 'youtube':
        setShowYoutube(true);
        break;
      case 'linkedin':
        setShowLinkedin(true);
        break;
      case 'facebook':
        setShowFacebook(true);
        break;
      case 'instagram':
        setShowInstagram(true);
        break;
      case 'portfolio':
        setShowPortfolio(true);
        break;
      default:
        setShowTwitter(true);
        break;
    }
  }, []);

  const handleUnlink = useCallback(
    async (socialMedia) => {
      const newUser = { ...user };
      let { socialNetworks, oldSocialNetworks } = newUser;

      const socialNetworkSelected = socialNetworks.find(
        (socialNetwork) => socialNetwork.type === socialMedia
      );
      socialNetworks = socialNetworks.filter(
        (socialNetwork) => socialNetwork.type !== socialMedia
      );
      oldSocialNetworks = oldSocialNetworks.filter(
        (socialNetwork) => socialNetwork.type !== socialMedia
      );

      if (socialNetworkSelected) {
        await api.delete(`social-networks/${socialNetworkSelected.id}`);
      }

      switch (socialMedia) {
        case 'socialEmail':
          setShowSocialEmail(false);
          break;
        case 'youtube':
          setShowYoutube(false);
          break;
        case 'linkedin':
          setShowLinkedin(false);
          break;
        case 'facebook':
          setShowFacebook(false);
          break;
        case 'instagram':
          setShowInstagram(false);
          break;
        case 'portfolio':
          setShowPortfolio(false);
          break;
        default:
          setShowTwitter(false);
          break;
      }

      newUser.oldSocialNetworks = oldSocialNetworks;
      newUser.socialNetworks = socialNetworks;
      updateUser(newUser);
    },
    [updateUser, user]
  );

  const handleClickUnlink = useCallback(
    (socialMedia, link) => {
      if (link && link.length > 0) {
        Swal.fire({
          text: `Deseja desvincular seu ${socialMedia}?`,
          icon: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          confirmButtonColor: '#e50914',
          cancelButtonColor: '#bbbbbb',
          cancelButtonText: 'Não',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            handleUnlink(socialMedia);
          }
        });
      } else {
        handleUnlink(socialMedia);
      }
    },
    [handleUnlink]
  );

  const handleChangeForeign = useCallback((value) => {
    setForeign(value.id === 'Sim');
  }, []);

  const handleChangeZipcode = useCallback(async (e) => {
    const zipCode = e.target.value.replace('-', '');
    if (zipCode.length === 8) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipCode}/json/`
      );
      setAddress((state) => ({
        zipcode: response.data.cep,
        street: response.data.logradouro,
        number: state.number,
        neighborhood: response.data.bairro,
        city: response.data.localidade,
        state: response.data.uf,
        complement: response.data.complemento,
      }));
    }
  }, []);

  const handleChangeStreet = useCallback((e) => {
    setAddress((state) => ({
      zipcode: state.zipcode,
      street: e.target.value,
      number: state.number,
      neighborhood: state.neighborhood,
      city: state.city,
      state: state.state,
      complement: state.complement,
    }));
  }, []);

  const handleChangeNeighborhood = useCallback((e) => {
    setAddress((state) => ({
      zipcode: state.zipcode,
      street: state.street,
      number: state.number,
      neighborhood: e.target.value,
      city: state.city,
      state: state.state,
      complement: state.complement,
    }));
  }, []);

  const handleChangeCity = useCallback((e) => {
    setAddress((state) => ({
      zipcode: state.zipcode,
      street: state.street,
      number: state.number,
      neighborhood: state.neighborhood,
      city: e.target.value,
      state: state.state,
      complement: state.complement,
    }));
  }, []);

  const handleChangeState = useCallback((value) => {
    setAddress((state) => ({ ...state, state: value.id }));
  }, []);

  const handleChangeWallpaper = useCallback((file) => {
    setWallpaper(file);
  }, []);

  const handleChangeAvatar = useCallback((file) => {
    setAvatarData(file);
  }, []);

  const handleSubmitEditProfile = useCallback(
    async (data: IProfileData) => {
      try {
        formEditProfileRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          birthdate: Yup.string().required('O nascimento é obrigatório'),
          occupation: Yup.string().required('O nascimento é obrigatório'),
          biography: Yup.string(),
          socialEmail: Yup.string(),
          youtube: Yup.string(),
          linkedin: Yup.string(),
          facebook: Yup.string(),
          instagram: Yup.string(),
          portfolio: Yup.string(),
          twitter: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          email,
          birthdate,
          occupation,
          biography,
          socialEmail,
          youtube,
          linkedin,
          facebook,
          instagram,
          portfolio,
          twitter,
        } = data;

        const [day, month, year] = birthdate.split('/');

        const formData = {
          name,
          email: email.toLowerCase(),
          birthdate: new Date(
            parseInt(year, 10),
            parseInt(month, 10) - 1,
            parseInt(day, 10)
          ),
          occupation,
          biography,
        };

        const response = await api.put('users/profile', formData);

        const socialNetworks = user.socialNetworks.slice();
        const oldSocialNetworks = user.oldSocialNetworks.slice();

        if (socialEmail) {
          const checkSocialEmail = user.socialNetworks.find(
            (socialNetwork) => socialNetwork.type === 'socialEmail'
          );
          if (checkSocialEmail) {
            if (checkSocialEmail.link !== socialEmail) {
              const formSocialNewordData = {
                type: checkSocialEmail.type,
                icon: checkSocialEmail.icon,
                link: socialEmail,
              };
              await api.put(
                `social-networks/${checkSocialEmail.id}`,
                formSocialNewordData
              );
              const socialNetworkIndex = socialNetworks.findIndex(
                (socialNetwork) => socialNetwork.id === checkSocialEmail.id
              );
              socialNetworks[socialNetworkIndex].link = socialEmail;
            }
          } else {
            const formSocialNewordData = {
              type: 'socialEmail',
              icon: 'IoIosMail',
              link: socialEmail,
            };
            const responseSocialNetword = await api.post(
              'social-networks',
              formSocialNewordData
            );
            socialNetworks.push(responseSocialNetword.data);
            oldSocialNetworks.push(responseSocialNetword.data);
          }
        }

        if (youtube) {
          const checkYoutube = user.socialNetworks.find(
            (socialNetwork) => socialNetwork.type === 'youtube'
          );
          if (checkYoutube) {
            if (checkYoutube.link !== youtube) {
              const formSocialNewordData = {
                type: checkYoutube.type,
                icon: checkYoutube.icon,
                link: youtube,
              };
              await api.put(
                `social-networks/${checkYoutube.id}`,
                formSocialNewordData
              );
              const socialNetworkIndex = socialNetworks.findIndex(
                (socialNetwork) => socialNetwork.id === checkYoutube.id
              );
              socialNetworks[socialNetworkIndex].link = youtube;
            }
          } else {
            const formSocialNewordData = {
              type: 'youtube',
              icon: 'ImYoutube',
              link: youtube,
            };
            const responseSocialNetword = await api.post(
              'social-networks',
              formSocialNewordData
            );
            socialNetworks.push(responseSocialNetword.data);
            oldSocialNetworks.push(responseSocialNetword.data);
          }
        }

        if (linkedin) {
          const checkLinkedin = user.socialNetworks.find(
            (socialNetwork) => socialNetwork.type === 'linkedin'
          );
          if (checkLinkedin) {
            if (checkLinkedin.link !== linkedin) {
              const formSocialNewordData = {
                type: checkLinkedin.type,
                icon: checkLinkedin.icon,
                link: linkedin,
              };
              await api.put(
                `social-networks/${checkLinkedin.id}`,
                formSocialNewordData
              );
              const socialNetworkIndex = socialNetworks.findIndex(
                (socialNetwork) => socialNetwork.id === checkLinkedin.id
              );
              socialNetworks[socialNetworkIndex].link = linkedin;
            }
          } else {
            const formSocialNewordData = {
              type: 'linkedin',
              icon: 'ImLinkedin2',
              link: linkedin,
            };
            const responseSocialNetword = await api.post(
              'social-networks',
              formSocialNewordData
            );
            socialNetworks.push(responseSocialNetword.data);
            oldSocialNetworks.push(responseSocialNetword.data);
          }
        }

        if (facebook) {
          const checkFacebook = user.socialNetworks.find(
            (socialNetwork) => socialNetwork.type === 'facebook'
          );
          if (checkFacebook) {
            if (checkFacebook.link !== facebook) {
              const formSocialNewordData = {
                type: checkFacebook.type,
                icon: checkFacebook.icon,
                link: facebook,
              };
              await api.put(
                `social-networks/${checkFacebook.id}`,
                formSocialNewordData
              );
              const socialNetworkIndex = socialNetworks.findIndex(
                (socialNetwork) => socialNetwork.id === checkFacebook.id
              );
              socialNetworks[socialNetworkIndex].link = facebook;
            }
          } else {
            const formSocialNewordData = {
              type: 'facebook',
              icon: 'MdFacebook',
              link: facebook,
            };
            const responseSocialNetword = await api.post(
              'social-networks',
              formSocialNewordData
            );
            socialNetworks.push(responseSocialNetword.data);
            oldSocialNetworks.push(responseSocialNetword.data);
          }
        }

        if (instagram) {
          const checkInstagram = user.socialNetworks.find(
            (socialNetwork) => socialNetwork.type === 'instagram'
          );
          if (checkInstagram) {
            if (checkInstagram.link !== instagram) {
              const formSocialNewordData = {
                type: checkInstagram.type,
                icon: checkInstagram.icon,
                link: instagram,
              };
              await api.put(
                `social-networks/${checkInstagram.id}`,
                formSocialNewordData
              );
              const socialNetworkIndex = socialNetworks.findIndex(
                (socialNetwork) => socialNetwork.id === checkInstagram.id
              );
              socialNetworks[socialNetworkIndex].link = instagram;
            }
          } else {
            const formSocialNewordData = {
              type: 'instagram',
              icon: 'RiInstagramFill',
              link: instagram,
            };
            const responseSocialNetword = await api.post(
              'social-networks',
              formSocialNewordData
            );
            socialNetworks.push(responseSocialNetword.data);
            oldSocialNetworks.push(responseSocialNetword.data);
          }
        }

        if (portfolio) {
          const checkPortfolio = user.socialNetworks.find(
            (socialNetwork) => socialNetwork.type === 'portfolio'
          );
          if (checkPortfolio) {
            if (checkPortfolio.link !== portfolio) {
              const formSocialNewordData = {
                type: checkPortfolio.type,
                icon: checkPortfolio.icon,
                link: portfolio,
              };
              await api.put(
                `social-networks/${checkPortfolio.id}`,
                formSocialNewordData
              );
              const socialNetworkIndex = socialNetworks.findIndex(
                (socialNetwork) => socialNetwork.id === checkPortfolio.id
              );
              socialNetworks[socialNetworkIndex].link = portfolio;
            }
          } else {
            const formSocialNewordData = {
              type: 'portfolio',
              icon: 'IoMdLink',
              link: portfolio,
            };
            const responseSocialNetword = await api.post(
              'social-networks',
              formSocialNewordData
            );
            socialNetworks.push(responseSocialNetword.data);
            oldSocialNetworks.push(responseSocialNetword.data);
          }
        }

        if (twitter) {
          const checkTwitter = user.socialNetworks.find(
            (socialNetwork) => socialNetwork.type === 'twitter'
          );
          if (checkTwitter) {
            if (checkTwitter.link !== twitter) {
              const formSocialNewordData = {
                type: checkTwitter.type,
                icon: checkTwitter.icon,
                link: twitter,
              };
              await api.put(
                `social-networks/${checkTwitter.id}`,
                formSocialNewordData
              );
              const socialNetworkIndex = socialNetworks.findIndex(
                (socialNetwork) => socialNetwork.id === checkTwitter.id
              );
              socialNetworks[socialNetworkIndex].link = twitter;
            }
          } else {
            const formSocialNewordData = {
              type: 'twitter',
              icon: 'AiFillTwitterCircle',
              link: twitter,
            };
            const responseSocialNetword = await api.post(
              'social-networks',
              formSocialNewordData
            );
            socialNetworks.push(responseSocialNetword.data);
            oldSocialNetworks.push(responseSocialNetword.data);
          }
        }

        const newUser = { ...user };

        newUser.name = response.data.name;
        newUser.email = response.data.email;
        newUser.birthdate = response.data.birthdate;
        newUser.occupation = response.data.occupation;
        newUser.biography = response.data.biography;
        newUser.socialNetworks = socialNetworks;
        newUser.oldSocialNetworks = oldSocialNetworks;

        updateUser(newUser);

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditProfileRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [handleClose, updateUser, user]
  );

  const handleSubmitEditPersonalData = useCallback(
    async (data: IPersonalData) => {
      try {
        formEditPersonalDataRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string(),
          phone: Yup.string(),
          foreign: Yup.string(),
          document: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          email: data.email.toLowerCase(),
          password: data.password,
          phone:
            data.phone &&
            data.phone
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '')
              .replace('-', ''),
          foreign,
          document: data.document
            ? data.document.replaceAll('.', '').replace('-', '')
            : undefined,
        };

        const response = await api.put('users/profile', formData);

        const newUser = JSON.parse(JSON.stringify(user));
        newUser.email = response.data.email;
        newUser.phone = data.phone
          ? mask({ kind: 'cel-phone', value: data.phone })
          : '-';
        newUser.foreign = response.data.foreign;
        newUser.document = data.document
          ? mask({ kind: 'cpf', value: data.document })
          : '-';

        updateUser(newUser);

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditPersonalDataRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [foreign, handleClose, updateUser, user]
  );

  const handleSubmitEditAddress = useCallback(
    async (data: IAddressData) => {
      try {
        formEditAddressRef.current?.setErrors({});
        const schema = Yup.object().shape({
          zipcode: Yup.string(),
          street: Yup.string(),
          number: Yup.string(),
          neighborhood: Yup.string(),
          city: Yup.string().required('A cidade é obrigatória'),
          state: Yup.string().required('O estado é obrigatório'),
          complement: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          user_id: user.id,
          zipcode: data.zipcode?.replace('-', ''),
          street: data.street,
          number: data.number,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          complement: data.complement,
        };

        if (user.address) {
          const response = await api.put(
            `addresses/${user.address.id}`,
            formData
          );

          const newUser = JSON.parse(JSON.stringify(user));
          newUser.address.zipcode = data.zipcode
            ? mask({ kind: 'zip-code', value: data.zipcode })
            : '-';
          newUser.address.street = response.data.street;
          newUser.address.number = response.data.number;
          newUser.address.neighborhood = response.data.neighborhood;
          newUser.address.city = response.data.city;
          newUser.address.state = response.data.state;
          newUser.address.complement = response.data.complement;
          updateUser(newUser);
        }

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditAddressRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [handleClose, updateUser, user]
  );

  const handleSubmitEditWallpaper = useCallback(async () => {
    try {
      if (wallpaper) {
        const formData = new FormData();
        formData.append('wallpaper', wallpaper);

        const response = await api.post(`wallpapers`, formData);

        const formDataUser = {
          wallpaper_id: response.data.id,
        };
        await api.put('users/profile', formDataUser);

        const newUser = JSON.parse(JSON.stringify(user));
        newUser.wallpaper = response.data;

        updateUser(newUser);
      }

      handleClose();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formEditAddressRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao logar, verifique seus dados.',
          'error'
        );
      }
    }
  }, [handleClose, updateUser, user, wallpaper]);

  const handleSubmitEditAvatar = useCallback(async () => {
    try {
      if (avatarData) {
        const formData = new FormData();
        formData.append('avatar', avatarData);

        let response: AxiosResponse;
        if (user.avatar) {
          response = await api.put(`avatars/${user.avatar.id}`, formData);
        } else {
          response = await api.post(`avatars`, formData);
        }

        const formDataUser = {
          avatar_id: response.data.id,
        };
        await api.put('users/profile', formDataUser);

        const newUser = JSON.parse(JSON.stringify(user));
        newUser.avatar = response.data;

        updateUser(newUser);
      }

      handleClose();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formEditAddressRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao logar, verifique seus dados.',
          'error'
        );
      }
    }
  }, [avatarData, handleClose, updateUser, user]);

  const handleChangeNotificationOption = useCallback(
    (notificationOptionsId: string[]) => {
      const notificationOptionsInactive = notificationOptions.filter(
        (notificationOption) => {
          return !notificationOptionsId.some((notificationOptionId) => {
            return notificationOption.id === notificationOptionId;
          });
        }
      );

      const notificationOptionsActive = notificationOptions.filter(
        (notificationOption) => {
          return notificationOptionsId.some((notificationOptionId) => {
            return notificationOption.id === notificationOptionId;
          });
        }
      );

      notificationOptionsInactive.forEach(async (notificationOption) => {
        const formData = {
          notification_option_id: notificationOption.id,
          active: false,
        };
        await api.post('users-notification-options', formData);
      }, []);

      notificationOptionsActive.forEach(async (notificationOption) => {
        const formData = {
          notification_option_id: notificationOption.id,
          active: true,
        };
        await api.post('users-notification-options', formData);
      }, []);

      setNotificationOptionsSelected(notificationOptionsId);
    },
    [notificationOptions]
  );

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  return (
    <Container>
      <div className="container pt-5">
        <div className="row align-items-center pt-5 pb-5">
          {location.pathname === '/meu-perfil' ? (
            <div className="col-12 mb-5">
              <div className="d-flex align-items-center">
                <Link to={`${process.env.PUBLIC_URL}/produtos`}>
                  <FiArrowLeft size={24} color="#fff" />
                </Link>
                <h1 className="fw-semibold text-white ms-4 mb-0">Meu perfil</h1>
              </div>
            </div>
          ) : (
            <>
              {product && product.plans && product.plans.length > 0 && (
                <div className="col-12 mb-5">
                  <div className="box-plan px-3 py-4">
                    <div className="d-flex align-items-center flex-wrap">
                      <TiStarFullOutline size={27} color="#FF2626" />
                      <p className="ms-2 ms-lg-4 mb-0 me-lg-2 pe-3 pe-sm-0">
                        Meu Plano de Assinatura
                      </p>
                      <span className="px-3 py-1 fw-semibold plan-name">
                        {product.plan && product.plan.name}
                      </span>
                      <Link
                        to={`${process.env.PUBLIC_URL}/${product.slug}`}
                        className="ms-auto"
                      >
                        <span className="fw-medium me-1">Faça um upgrade</span>
                        <VscArrowSmallRight size={24} color="#fff" />
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="col-12">
            <Box>
              <Banner
                type="button"
                src={user.wallpaper ? user.wallpaper.wallpaper_url : ''}
                onClick={() => setShowEditBanner(true)}
              >
                <div className="btn btn-dark-3 rounded-pill m-4">
                  <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                  <span>Editar</span>
                </div>
              </Banner>
              <div className="px-3 px-sm-4 px-lg-5 py-5 position-relative">
                <Avatar
                  src={user.avatar ? user.avatar.avatar_url : avatar}
                  onClick={() => setShowEditAvatar(true)}
                  className="position-absolute"
                >
                  <span>
                    <HiCamera size={16} color="#fff" className="me-1" />
                    Editar
                  </span>
                </Avatar>
                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-sm-start mt-4 mt-sm-2 mt-lg-4">
                  <h1 className="mb-0 h3 h2-lg order-1 order-lg-0 my-5 my-sm-3 my-lg-0">
                    {user.name}
                  </h1>
                  <div className="d-flex align-items-center align-self-sm-end order-0 order-lg-1 mt-3 mt-lg-0">
                    {user.socialNetworks.map((socialNetwork) => (
                      <>
                        {socialNetwork.type !== 'portfolio' && (
                          <a
                            key={socialNetwork.id}
                            href={
                              socialNetwork.type === 'socialEmail'
                                ? `mailto:${socialNetwork.link}`
                                : socialNetwork.link
                            }
                            className="btn btn-dark-3 rounded-circle p-0 social-media d-flex justify-content-center align-items-center"
                          >
                            {socialNetwork.icon && (
                              <socialNetwork.icon size={22} color="#fff" />
                            )}
                          </a>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row align-items-center my-4 mb-sm-5 mb-lg-4">
                  <div className="d-flex align-items-center py-1">
                    <IoIosBriefcase
                      size={20}
                      color="#bbbbbb"
                      className="me-2"
                    />
                    <p className="mb-0 text-gray">{user.occupation}</p>
                  </div>
                  <div className="d-flex align-items-center py-1 borders mx-sm-3 mx-lg-4 px-sm-3 px-lg-4">
                    <FaMapMarkerAlt
                      size={20}
                      color="#bbbbbb"
                      className="me-2"
                    />
                    {user.address && (
                      <p className="mb-0 text-gray">
                        {user.address.city}, {user.address.state}
                      </p>
                    )}
                  </div>
                  <div className="d-flex align-items-center py-1">
                    <p className="mb-0 text-gray">
                      {age === 0 ? 'Idade não informada' : `${age} anos`}
                    </p>
                  </div>
                </div>
                <p className="mb-0 text-gray mb-1">{user.biography}</p>
                <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mt-5">
                  {portfolioSite && (
                    <a href={portfolioSite.link} className="text-white">
                      {portfolioSite.link}
                    </a>
                  )}
                  <button
                    type="button"
                    onClick={() => setShowEditProfile(true)}
                    className="btn btn-dark-3 rounded-pill mt-4 mt-sm-0 ms-auto"
                  >
                    <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                    <span>Editar</span>
                  </button>
                </div>
              </div>
            </Box>
          </div>
          <div className="col-12 mt-5">
            <Box className="px-3 px-lg-5 py-5">
              <Tabs className="px-5 py-3">
                <TabsButton
                  type="button"
                  onClick={handleClickTabsButton}
                  className="d-flex d-md-none justify-content-center align-items-center w-100 border-0 bg-transparent"
                >
                  {tabSelected === 'personal-data' && 'Dados pessoais'}
                  {tabSelected === 'address' && 'Endereço'}
                  {tabSelected === 'payments' && 'Pagamentos'}
                  {tabSelected === 'notifications' && 'Notificações'}
                  <IoIosArrowDown size={24} color="#bbbbbb" className="ms-2" />
                </TabsButton>
                <TabsGroup
                  opened={openTabs}
                  className="w-100 d-flex flex-column flex-md-row justify-content-md-between align-items-center"
                >
                  <Tab
                    type="button"
                    onClick={() => handleSelectTab('personal-data')}
                    active={tabSelected === 'personal-data'}
                    className="border-0 bg-transparent"
                  >
                    Dados pessoais
                  </Tab>
                  <Tab
                    type="button"
                    onClick={() => handleSelectTab('address')}
                    active={tabSelected === 'address'}
                    className="border-0 bg-transparent"
                  >
                    Endereço
                  </Tab>
                  <Tab
                    type="button"
                    onClick={() => handleSelectTab('payments')}
                    active={tabSelected === 'payments'}
                    className="border-0 bg-transparent"
                  >
                    Pagamentos
                  </Tab>
                  <Tab
                    type="button"
                    onClick={() => handleSelectTab('notifications')}
                    active={tabSelected === 'notifications'}
                    className="border-0 bg-transparent"
                  >
                    Notificações
                  </Tab>
                </TabsGroup>
              </Tabs>
              <hr className="mt-3 mb-5" />
              {tabSelected === 'personal-data' && (
                <>
                  <PersonalData className="row px-2 px-md-4 px-lg-5">
                    <div className="col-md-6 col-lg-4 mb-4">
                      <p className="fw-semibold">E-mail</p>
                      <p className="fw-normal text-gray">{user.email}</p>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4">
                      <p className="fw-semibold">Senha</p>
                      <p className="fw-normal text-gray">•••••••</p>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4">
                      <p className="fw-semibold">Telefone</p>
                      <p className="fw-normal text-gray">{user.phone || '-'}</p>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <p className="fw-semibold">Estrangeiro</p>
                      <p className="fw-normal text-gray">
                        {user.foreign ? 'Sim' : 'Não'}
                      </p>
                    </div>
                    {!user.foreign && (
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">CPF</p>
                        <p className="fw-normal text-gray">{user.document}</p>
                      </div>
                    )}
                  </PersonalData>
                  <button
                    type="button"
                    onClick={() => setShowEditPersonalData(true)}
                    className="btn btn-dark-3 rounded-pill d-block ms-auto me-sm-2"
                  >
                    <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                    <span>Editar</span>
                  </button>
                  <Modal
                    show={showEditPersonalData}
                    onHide={handleClose}
                    close
                    size="lg"
                  >
                    <Form
                      ref={formEditPersonalDataRef}
                      initialData={personalData}
                      onSubmit={handleSubmitEditPersonalData}
                    >
                      <Modal.Header className="border-0" closeButton>
                        <h4 className="h6 h5-md h4-lg">
                          Editar dados pessoais
                        </h4>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              E-mail
                              <Input
                                type="email"
                                name="email"
                                className="mt-2"
                              />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Senha
                              <Input
                                type="password"
                                name="password"
                                className="mt-2"
                              />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Telefone
                              <InputMask
                                kind="cel-phone"
                                name="phone"
                                className="mt-2"
                                value={personalData.phone}
                              />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Estrangeiro
                              <InputRadio
                                name="foreign"
                                options={[
                                  {
                                    id: 'Sim',
                                    value: 'Sim',
                                  },
                                  {
                                    id: 'Não',
                                    value: 'Não',
                                  },
                                ]}
                                selected={
                                  foreign
                                    ? { id: 'Sim', value: 'Sim' }
                                    : { id: 'Não', value: 'Não' }
                                }
                                onChange={handleChangeForeign}
                                className="mt-3 justify-content-start"
                              />
                            </label>
                          </div>
                          {!foreign && (
                            <div className="col-lg-6 mb-4">
                              <label className="w-100 fw-medium">
                                CPF
                                <InputMask
                                  kind="cpf"
                                  name="document"
                                  className="mt-2"
                                  value={personalData.document}
                                />
                              </label>
                            </div>
                          )}
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="border-0">
                        <button
                          type="submit"
                          className="btn btn-primary px-5 py-2"
                        >
                          Salvar
                        </button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                </>
              )}
              {tabSelected === 'address' && (
                <>
                  {user.address && (
                    <Address className="row px-2 px-md-4 px-lg-5">
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">CEP</p>
                        <p className="fw-normal text-gray">
                          {user.address.zipcode || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Endereço</p>
                        <p className="fw-normal text-gray">
                          {user.address.street || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Nº</p>
                        <p className="fw-normal text-gray">
                          {user.address.number || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-4">
                        <p className="fw-semibold">Bairro</p>
                        <p className="fw-normal text-gray">
                          {user.address.neighborhood || '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">Cidade</p>
                        <p className="fw-normal text-gray">
                          {user.address.city}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">Estado</p>
                        <p className="fw-normal text-gray">
                          {user.address.state}
                        </p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <p className="fw-semibold">Complemento</p>
                        <p className="fw-normal text-gray">
                          {user.address.complement || '-'}
                        </p>
                      </div>
                    </Address>
                  )}
                  <button
                    type="button"
                    className="btn btn-dark-3 rounded-pill d-block ms-auto me-sm-2"
                    onClick={() => setShowEditAddress(true)}
                  >
                    <RiEditFill size={18} color="#FFFFFF" className="me-2" />
                    <span>Editar</span>
                  </button>
                  <Modal
                    show={showEditAddress}
                    onHide={handleClose}
                    close
                    size="lg"
                  >
                    <Form
                      ref={formEditAddressRef}
                      initialData={address}
                      onSubmit={handleSubmitEditAddress}
                    >
                      <Modal.Header className="border-0" closeButton>
                        <h4 className="h6 h5-md h4-lg">Editar endereço</h4>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              CEP
                              <InputMask
                                kind="zip-code"
                                name="zipcode"
                                className="mt-2"
                                value={address.zipcode}
                                onChange={handleChangeZipcode}
                              />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Endereço
                              <Input
                                name="street"
                                className="mt-2"
                                value={address.street}
                                onChange={handleChangeStreet}
                              />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Nº
                              <Input name="number" className="mt-2" />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Bairro
                              <Input
                                name="neighborhood"
                                className="mt-2"
                                value={address.neighborhood}
                                onChange={handleChangeNeighborhood}
                              />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Cidade
                              <Input
                                name="city"
                                className="mt-2"
                                value={address.city}
                                onChange={handleChangeCity}
                              />
                            </label>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="w-100 fw-medium">
                              Estado
                              <Select
                                name="state"
                                options={states}
                                className="mt-2"
                                onChange={handleChangeState}
                              />
                            </label>
                          </div>
                          <div className="col-12 mb-4">
                            <label className="w-100 fw-medium">
                              Complemento
                              <Input name="complement" className="mt-2" />
                            </label>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="border-0">
                        <button
                          type="submit"
                          className="btn btn-primary px-5 py-2"
                        >
                          Salvar
                        </button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                </>
              )}
              {tabSelected === 'payments' && (
                <>
                  <Payments className="row px-2 px-md-4 px-lg-5">
                    <Table
                      data={payments}
                      columns={columns}
                      className="table-users"
                      fromData={paymentsFrom}
                      toData={paymentsTo}
                      totalData={paymentsTotal}
                      pagination
                      selectedPage={page}
                      onChangePage={handleChangePage}
                    />
                  </Payments>
                </>
              )}
              {tabSelected === 'notifications' && (
                <Notifications className="row px-2 px-md-4 px-lg-5">
                  <InputToggle
                    name="notification"
                    options={notificationOptions}
                    onChange={handleChangeNotificationOption}
                    checkedValues={notificationOptionsSelected}
                  />
                </Notifications>
              )}
            </Box>
          </div>
        </div>
      </div>
      <Modal show={showEditProfile} onHide={handleClose} close size="lg">
        <Form
          ref={formEditProfileRef}
          initialData={profileData}
          onSubmit={handleSubmitEditProfile}
        >
          <Modal.Header className="border-0" closeButton>
            <h4 className="h6 h5-md h4-lg">Editar perfil</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  Nome Completo
                  <Input name="name" className="mt-2" />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  E-mail
                  <Input type="email" name="email" className="mt-2" />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  Data de nascimento
                  <InputMask
                    kind="datetime"
                    options={{ format: 'DD/MM/YYYY' }}
                    name="birthdate"
                    className="mt-2"
                    value={profileData.birthdate}
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100 fw-medium">
                  Profissão
                  <Input name="occupation" className="mt-2" />
                </label>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100 fw-medium">
                  Biografia
                  <Textarea
                    name="biography"
                    rows={4}
                    maxLength={450 + spacesQtd}
                    className="mt-2"
                    onChange={handleChangeBiography}
                  />
                </label>
                <span className="small text-gray d-block text-end px-3 mt-3">
                  {biographyQtd}/450
                </span>
              </div>
            </div>
            <SocialMedias className="row">
              <div className="col-12 mb-3 mb-lg-0">
                <h4 className="h6 h5-md h4-lg">Redes sociais</h4>
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <MdFacebook size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Facebook:</p>
                </div>
                {profileData.facebook || showFacebook ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="facebook"
                      className="bg-transparent border-0"
                      placeholder="https://facebook.com"
                      disabled={
                        !!(
                          profileData.facebook &&
                          profileData.facebook.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('facebook', profileData.facebook)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('facebook')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <RiInstagramFill size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Instagram:</p>
                </div>
                {profileData.instagram || showInstagram ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="instagram"
                      className="bg-transparent border-0"
                      placeholder="https://www.instagram.com"
                      disabled={
                        !!(
                          profileData.instagram &&
                          profileData.instagram.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('instagram', profileData.instagram)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('instagram')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <ImLinkedin2 size={18} color="#fff" />
                  </div>
                  <p className="mb-0">LinkedIn:</p>
                </div>
                {profileData.linkedin || showLinkedin ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="linkedin"
                      className="bg-transparent border-0"
                      placeholder="https://www.linkedin.com"
                      disabled={
                        !!(
                          profileData.linkedin &&
                          profileData.linkedin.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('linkedin', profileData.linkedin)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('linkedin')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <IoIosMail size={18} color="#fff" />
                  </div>
                  <p className="mb-0">E-mail:</p>
                </div>
                {profileData.socialEmail || showSocialEmail ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="email"
                      name="socialEmail"
                      className="bg-transparent border-0"
                      placeholder="example@example.com"
                      disabled={
                        !!(
                          profileData.socialEmail &&
                          profileData.socialEmail.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink(
                          'socialEmail',
                          profileData.socialEmail
                        )
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('socialEmail')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <AiFillTwitterCircle size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Twitter:</p>
                </div>
                {profileData.twitter || showTwitter ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="twitter"
                      className="bg-transparent border-0"
                      placeholder="https://twitter.com"
                      disabled={
                        !!(
                          profileData.twitter && profileData.twitter.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('twitter', profileData.twitter)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('twitter')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <ImYoutube size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Youtube:</p>
                </div>
                {profileData.youtube || showYoutube ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="youtube"
                      className="bg-transparent border-0"
                      placeholder="https://youtube.com"
                      disabled={
                        !!(
                          profileData.youtube && profileData.youtube.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('youtube', profileData.youtube)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('youtube')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-12 px-lg-5 d-flex flex-column flex-lg-row justify-content-between mb-3 mb-lg-0">
                <div className="d-flex align-items-center py-lg-3">
                  <div className="icon-circle me-2 bg-dark-3 rounded-circle d-flex align-items-center justify-content-center">
                    <IoMdLink size={18} color="#fff" />
                  </div>
                  <p className="mb-0">Site/ Portifólio:</p>
                </div>
                {profileData.portfolio || showPortfolio ? (
                  <div className="d-flex align-items-center w-100 w-lg-75 input-border py-3">
                    <Input
                      type="url"
                      name="portfolio"
                      className="bg-transparent border-0"
                      placeholder="https://example.com"
                      disabled={
                        !!(
                          profileData.portfolio &&
                          profileData.portfolio.length > 0
                        )
                      }
                    />
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() =>
                        handleClickUnlink('portfolio', profileData.portfolio)
                      }
                    >
                      <CgClose size={24} color="#bbb" />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-end w-100 w-lg-75 link-border py-3">
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => handleClickLink('portfolio')}
                    >
                      <span>
                        <IoIosAdd size={24} color="#bbbbbb" /> Vincular
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </SocialMedias>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary px-5 py-2">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showEditBanner}
        onHide={handleClose}
        close
        backdrop="static"
        size="lg"
      >
        <Form onSubmit={handleSubmitEditWallpaper}>
          <Modal.Header className="border-0" closeButton>
            <h4 className="h6 h5-md h4-lg">Editar capa</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <InputPhoto
                  name="wallpaper"
                  value={user.wallpaper ? user.wallpaper.wallpaper_url : ''}
                  cropImage
                  aspect={9.3 / 2.08}
                  cropOptions={
                    !wallpaper
                      ? {
                          unit: 'px',
                          width: 200,
                          height: 44.8,
                          x: 0,
                          y: 0,
                        }
                      : undefined
                  }
                  onChange={handleChangeWallpaper}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary px-5 py-2">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showEditAvatar}
        onHide={handleClose}
        close
        backdrop="static"
        size="lg"
      >
        <Form onSubmit={handleSubmitEditAvatar}>
          <Modal.Header className="border-0" closeButton>
            <h4 className="h6 h5-md h4-lg">Editar avatar</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <InputPhoto
                  name="avatar"
                  value={user.avatar ? user.avatar.avatar_url : avatar}
                  cropImage
                  takePhoto
                  aspect={1}
                  cropOptions={
                    !avatarData
                      ? {
                          unit: 'px',
                          width: 200,
                          height: 200,
                          x: 0,
                          y: 0,
                        }
                      : undefined
                  }
                  onChange={handleChangeAvatar}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary px-5 py-2">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Profile;
