import React, { createContext, useState, useContext, useCallback } from 'react';

interface IPlan {
  id: string;
  name: string;
  price: number;
}

interface IProduct {
  id: string;
  title: string;
  description: string;
  slug: string;
  price: number;
  hasPlans: boolean;
  lock: boolean;
  thumbnail: {
    thumbnail_url: string;
  };
  plan?: IPlan;
  plans?: IPlan[];
}

interface ProductContextData {
  product: IProduct | undefined;
  setProduct(product?: IProduct): void;
}

export const ProductContext = createContext<ProductContextData>(
  {} as ProductContextData
);

export const ProductProvider: React.FC = ({ children }) => {
  const [product, setProduct] = useState<IProduct | undefined>(() => {
    if (localStorage.getItem('@Leiaut:product')) {
      const productData: IProduct = JSON.parse(
        localStorage.getItem('@Leiaut:product') as string
      );

      return productData;
    }

    return undefined;
  });

  const handleChangeProduct = useCallback((data: IProduct | undefined) => {
    if (data) {
      localStorage.setItem('@Leiaut:product', JSON.stringify(data));
    } else {
      localStorage.removeItem('@Leiaut:product');
    }
    setProduct(data);
  }, []);

  return (
    <ProductContext.Provider
      value={{ product, setProduct: handleChangeProduct }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export function useProduct(): ProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within an ProductProvider');
  }

  return context;
}
