import React from 'react';
import Lottie from 'react-lottie';

import { Container } from './styles';

import loading from '~/assets/animations/loading.json';

interface ILoadingProps {
  show: boolean;
  message?: string;
}

const Loading: React.FC<ILoadingProps> = ({ show, message, children }) => {
  return (
    <Container show={show}>
      <Lottie
        options={{
          animationData: loading,
          autoplay: true,
          loop: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={200}
        width={200}
      />
      {message && <h4 className="text-white tw-semibold">{message}</h4>}
      <div className="content">{children}</div>
    </Container>
  );
};

export default Loading;
