import React, { useCallback, useEffect, useState } from 'react';
import { IoIosArrowRoundForward, IoMdPlay } from 'react-icons/io';
import { IconType } from 'react-icons/lib';
import { useHistory } from 'react-router-dom';
import { useProduct } from '~/hooks/Product';

import { Container, CourseLink, CourseButton } from './styles';

interface ICourse {
  type?: 'link' | 'button' | 'upgrade';
  to?: string;
  thumb: string;
  title: string;
  percent?: number;
  icon?: IconType;
  onClick?(): void;
  className?: string;
  message?: string;
}

const Course: React.FC<ICourse> = ({
  type = 'link',
  to,
  thumb,
  title,
  percent,
  icon: Icon,
  onClick,
  className,
  message,
}) => {
  const history = useHistory();
  const { product } = useProduct();
  const [CourseContent, setCourseContent] = useState<any>(CourseButton);

  useEffect(() => {
    if (type === 'link' && to) {
      setCourseContent(CourseLink);
    }
  }, [to, type]);

  const handleMouseEnter = useCallback((e) => {
    const element = e.target.closest('.course').querySelector('.icon');
    element.classList.add('hover');
  }, []);

  const handleMouseLeave = useCallback((e) => {
    const element = e.target.closest('.course').querySelector('.icon');
    element.classList.remove('hover');
  }, []);

  const handleClickIcon = useCallback(
    (data) => {
      if (data === 'upgrade' && product) {
        history.push(`${process.env.PUBLIC_URL}/${product.slug}`);
      }
    },
    [history, product]
  );

  return (
    <Container className={`px-2 px-lg-3 pb-xl-4 ${className}`}>
      <CourseContent
        to={to}
        percent={percent}
        className="d-block p-relative w-100 course pb-4"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {Icon && (
          <button
            type="button"
            className=" border-0 flag"
            onClick={() => handleClickIcon(type)}
          >
            <Icon size={24} color="#fff" />
            {message && <span className="d-flex flex-column">{message}</span>}
          </button>
        )}
        <div className="thumb mb-4">
          <img src={thumb} alt={title} />
          <div className="progress" />
        </div>
        <div className="d-flex justify-content-between align-items-center course-title">
          <span className="text-gray me-4">{title}</span>
          <div className="icon">
            {type === 'link' && <IoMdPlay size={20} color="#fff" />}
            {type === 'button' && (
              <IoIosArrowRoundForward size={24} color="#fff" />
            )}
            {type === 'upgrade' && <IoMdPlay size={20} color="#fff" />}
          </div>
        </div>
      </CourseContent>
    </Container>
  );
};

export default Course;
