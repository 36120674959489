import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdAttachMoney } from 'react-icons/md';
import Swal from 'sweetalert2';

import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { useAuth } from '~/hooks/Auth';
import { initializeSocketEvents } from '~/services/socket';

import { Container } from './styles';
import Resource from '~/components/Resource';
import Loading from '~/components/Loading';

interface ICertificate {
  id: string;
  certificate_id: string;
  title: string;
  certificate_url: string;
  checkout_url?: string;
  price?: number;
  purchased: boolean;
}

interface IParams {
  productSlug?: string;
}

const Certificates: React.FC = () => {
  const { user } = useAuth();
  const params = useParams<IParams>();
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    api
      .get<ICertificate[]>('users-certificates', {
        params: {
          productSlug: params.productSlug,
        },
      })
      .then((response) => {
        setCertificates(response.data);
      });
  }, [params.productSlug]);

  const handleCreateCertificateOrder = useCallback(
    async (certificate: ICertificate, user_id: string) => {
      try {
        const formData = {
          certificate_id: certificate.certificate_id,
          user_id,
          payment_type: 'Credit card',
          amount_paid: certificate.price,
          is_recurrent: false,
          status: 'Awaiting Payment',
        };

        return await api.post('orders', formData);
      } catch (error) {
        console.log(error);
      }

      return undefined;
    },
    []
  );

  const handlePaid = useCallback(
    (certificate) => {
      try {
        const newCertificates = certificates.slice();
        const certificateIndex = newCertificates.findIndex(
          (certificateData) => certificateData.id === certificate.id
        );
        if (certificateIndex >= 0) {
          newCertificates[certificateIndex].purchased = true;
          setCertificates(newCertificates);
        }
        window.open(certificate.certificate_url, '_blank');
        setShowLoading(false);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao logar, verifique seus dados.',
          'error'
        );
      }
    },
    [certificates]
  );

  const handleClickBuy = useCallback(
    async (certificate: ICertificate) => {
      const responseOrder = await handleCreateCertificateOrder(
        certificate,
        user.id
      );
      if (responseOrder) {
        if (certificate.price === 0) {
          const formData = {
            certificate_id: responseOrder.data.certificate_id,
            user_id: responseOrder.data.user_id,
            payment_type: responseOrder.data.payment_type,
            amount_paid: responseOrder.data.amount_paid,
            is_recurrent: responseOrder.data.is_recurrent,
            status: 'Paid',
          };
          await api.put(`orders/${responseOrder.data.id}`, formData);
          handlePaid(certificate);
        } else {
          setShowLoading(true);
          window.open(certificate.checkout_url, '_blank');
          initializeSocketEvents({
            onPaid: () => handlePaid(certificate),
            order_id: responseOrder.data.id,
            user_id: responseOrder.data.user_id,
          });
        }
      }
    },
    [handleCreateCertificateOrder, handlePaid, user.id]
  );

  return (
    <>
      <Container>
        <div className="container pt-5">
          <div className="row align-items-center pt-4 pt-lg-5 pb-5">
            <div className="col-12 mb-4 mb-lg-5 pb-4">
              <h1 className="fw-semibold text-white ms-4">Certificados</h1>
            </div>
            {certificates.map((certificate) => (
              <div key={certificate.id} className="col-md-6 col-xl-4 mb-3">
                <Resource
                  type="pdf"
                  kind={!certificate.purchased ? 'button' : 'link'}
                  to={certificate.certificate_url}
                  thumb={certificate.certificate_url}
                  title={certificate.title}
                  icon={!certificate.purchased ? MdAttachMoney : undefined}
                  message={
                    !certificate.purchased && certificate.price
                      ? `Valor: ${formatPrice(certificate.price)}`
                      : undefined
                  }
                  onClick={
                    !certificate.purchased
                      ? () => handleClickBuy(certificate)
                      : undefined
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
      <Loading
        show={showLoading}
        message="Aguardando a confirmação de pagamento"
      >
        <button
          type="button"
          className="btn btn-primary px-5 py-2 mt-3"
          onClick={() => setShowLoading(false)}
        >
          Cancelar
        </button>
      </Loading>
    </>
  );
};

export default Certificates;
