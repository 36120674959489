import styled from 'styled-components';

interface IResourceLink {
  href: string;
  src: string;
}
interface IResourceButton {
  src: string;
}

export const Container = styled.div``;

export const ResourceLink = styled.a<IResourceLink>`
  text-decoration: none;
  transition-duration: 0.3s;

  div.thumb {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    display: flex;
    align-items: end;
  }

  div.resource-title {
    transition-duration: 0.3s;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .flag {
    position: absolute;
    right: 20px;
    top: 0;
    width: 54px;
    height: 69px;
    background: #e50914;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 15px;
    }

    > span {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      width: max-content;
      position: absolute;
      top: -45px;
      background: #e50914;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;

      :after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #e50914;
        z-index: -1;
        bottom: -9px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
      }
    }

    :hover {
      > span {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  :hover {
    background: #242526;
    box-shadow: 1px 14px 13px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    div.resource-title {
      padding: 0 1rem;
    }
  }
`;

export const ResourceButton = styled.button<IResourceButton>`
  text-decoration: none;
  transition-duration: 0.3s;

  div.thumb {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    display: flex;
    align-items: end;
  }

  div.resource-title {
    transition-duration: 0.3s;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .flag {
    position: absolute;
    right: 20px;
    top: 0;
    width: 54px;
    height: 69px;
    background: #e50914;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 15px;
    }

    > span {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      width: max-content;
      position: absolute;
      top: -45px;
      background: #e50914;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;

      :after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #e50914;
        z-index: -1;
        bottom: -9px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
      }
    }

    :hover {
      > span {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  :hover {
    background: #242526;
    box-shadow: 1px 14px 13px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    div.resource-title {
      padding: 0 1rem;
    }
  }
`;
