import React from 'react';
import { Switch, Route as RouteComponent } from 'react-router-dom';

import Route from './Route';

import Product from '~/pages/Product';

import ProductDashboard from '~/pages/ProductDashboard';
import Certificates from '~/pages/Certificates';
import Course from '~/pages/Course';
import Test from '~/pages/Course/Test';
import Lesson from '~/pages/Course/Lesson';
import Profile from '~/pages/Profile';
import Support from '~/pages/Support';

const ProductRoutes: React.FC = () => {
  return (
    <Switch>
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/:productSlug`}
        exact
        component={Product}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/:productSlug/dashboard`}
        isPrivate
        exact
        component={ProductDashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/:productSlug/cursos/:slug`}
        isPrivate
        exact
        component={Course}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/:productSlug/cursos/:slug/prova`}
        isPrivate
        component={Test}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/:productSlug/cursos/:slugCourse/:slugModule/:slug`}
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/:productSlug/certificados`}
        isPrivate
        component={Certificates}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/:productSlug/perfil`}
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/:productSlug/suporte`}
        isPrivate
        component={Support}
      />
    </Switch>
  );
};

export default ProductRoutes;
