import styled from 'styled-components';

interface IAvatar {
  src?: string;
}

interface IProductCard {
  src: string;
}

interface IMenu {
  show: boolean;
}

export const Container = styled.div`
  .slick-arrow {
    height: 100% !important;
  }

  .btn-avatar {
    z-index: 1;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 59px;
  height: 59px;
  border-radius: 50%;
`;

export const Menu = styled.div<IMenu>`
  transition-duration: 0.3s;
  width: 185px;
  height: ${(props) => (props.show ? '204px' : '0')};
  padding-top: ${(props) => (props.show ? '1rem' : '0')};
  padding-bottom: ${(props) => (props.show ? '1rem' : '0')};
  overflow: hidden;
  border-radius: 10px;
  background: #303030;
  top: 75px;
  z-index: 1000;

  a,
  button {
    text-align: center;
    width: 100%;

    + a,
    + button {
      border-top: 1px solid #3a3a3a !important;
    }
  }

  a {
    color: #bbbbbb;

    :hover {
      color: #ffffff;

      svg {
        fill: #ffffff;
      }
    }
  }
`;

export const ProductCard = styled.button<IProductCard>`
  background: linear-gradient(
      188.52deg,
      rgba(44, 44, 44, 0.38) 33.3%,
      #2b2b2b 95.76%
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 100%;
  height: 300px;
  border-radius: 26px;
  border: none;
  text-align: left;

  .flag {
    position: absolute;
    right: 20px;
    top: 0;
    width: 54px;
    height: 69px;
    background: #e50914;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 15px;
    }

    > span {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      width: max-content;
      position: absolute;
      top: -70px;
      background: #e50914;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;

      :after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #e50914;
        z-index: -1;
        bottom: -9px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
      }
    }

    :hover {
      > span {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  + button {
    margin-left: 10px;
  }
`;
