import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IBanner {
  src: string;
}

interface IProgressBar {
  percent: number;
}

interface IModule {
  opened: boolean;
  size: number;
}

export const Container = styled.div`
  position: relative;
`;

export const Banner = styled.div<IBanner>`
  position: absolute;
  background: linear-gradient(0deg, #202020 10.56%, rgba(1, 1, 1, 0) 95.75%),
    linear-gradient(0deg, rgba(32, 32, 32, 0.4), rgba(32, 32, 32, 0.4)),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  z-index: -1;
`;

export const ProgressBar = styled.div<IProgressBar>`
  width: 100%;
  height: 6px;
  background: #3a3a3a;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  :after {
    content: '';
    width: ${(props) => props.percent}%;
    height: 6px;
    border-radius: 10px;
    background: #009d65;
    position: absolute;
    transition-duration: 0.3s;
  }
`;

export const Module = styled.div<IModule>`
  button {
    background: #303030;
    border-radius: 10px;

    svg {
      transition-duration: 0.3s;
      transform: rotateZ(${(props) => (props.opened ? -180 : 0)}deg);
    }
  }

  > div {
    transition-duration: 0.3s;
    height: ${(props) => (props.opened ? props.size : 0)}px;
    overflow: hidden;

    a {
      .title {
        width: calc(100% - 70px);

        > span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
        }
      }

      .box {
        background: #303030;
        border-radius: 24px;
      }

      + a {
        border-top: 2px solid #303030;
      }
    }
  }

  + div {
    margin-top: 35px;
  }
`;

export const Courses = styled.div`
  .slick-arrow {
    margin-top: 3rem !important;
  }

  .slick-list {
    padding-top: 3rem !important;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 0rem;
    overflow: hidden;

    .player {
      height: 400px;

      > div {
        height: 100% !important;
      }
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;
      z-index: 2;

      .sr-only {
        display: none;
      }
    }
  }
`;
