import React, { useCallback } from 'react';
import { IoIosSearch } from 'react-icons/io';

import { Container } from './styles';

interface ISearch {
  searchRef?: React.LegacyRef<HTMLInputElement>;
  onSearch(value: string): void;
  value?: string;
  className?: string;
  onFocus?(): void;
  onBlur?(): void;
}

const Search: React.FC<ISearch> = ({
  searchRef,
  onSearch,
  value,
  className,
  onFocus,
  onBlur,
}) => {
  const handleChange = useCallback(
    (e) => {
      onSearch(e.target.value);
    },
    [onSearch]
  );

  return (
    <Container className={className}>
      <input
        ref={searchRef}
        type="text"
        name="search"
        placeholder="Buscar"
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
      />
      <IoIosSearch size={33} color="#BBBBBB" />
    </Container>
  );
};

export default Search;
