import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCertificate } from 'react-icons/fa';
import { IoIosStar, IoMdLock } from 'react-icons/io';
import List from 'react-chatview';
import Lottie from 'react-lottie';
import axios, { CancelTokenSource } from 'axios';

import { useAuth } from '~/hooks/Auth';
import { useProduct } from '~/hooks/Product';
import api from '~/services/api';

import {
  Container,
  Categories,
  Courses,
  SearchCourses,
  AutoComplete,
} from './styles';
import Search from '~/components/Search';
import Slider from '~/components/Slider';
import Course from '~/components/Course';
import Skeleton from '~/components/Skeleton';

import magnifier from '~/assets/animations/magnifier.json';

interface ICourse {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
  percent?: number;
  lock?: boolean;
  usersCourses: {
    user_id: string;
  }[];
}

interface ICourseData {
  current_page: number;
  last_page: number;
  data: ICourse[];
}

interface ICategory {
  id: string;
  name: string;
  icon: string;
  slug: string;
  courses: ICourse[];
}

interface ICategoryData {
  current_page: number;
  last_page: number;
  data: ICategory[];
}

let cancelTokenCourse: CancelTokenSource | undefined;
let cancelTokenCourseName: CancelTokenSource | undefined;

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const { product } = useProduct();
  const searchRef = useRef<HTMLInputElement>(null);
  const searchCourseRef = useRef<HTMLInputElement>(null);
  const [categoriesHighlights, setCategoriesHighlights] = useState<ICategory[]>(
    []
  );
  const [continueWatchingCourses, setContinueWatchingCourses] = useState<
    ICourse[]
  >([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [search, setSearch] = useState('');
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [pageCourse, setPageCourse] = useState(1);
  const [lastPageCourse, setLastPageCourse] = useState(0);
  const [loadingMoreCourse, setLoadingMoreCourse] = useState(false);
  const [coursesName, setCoursesName] = useState<string[]>([]);
  const [loadingHighlights, setLoadingHighlights] = useState(true);
  const [loadingContinueWatching, setLoadingContinueWatching] = useState(true);
  const [loading, setLoading] = useState(true);

  const loadCategory = useCallback(
    async (pageData: number) => {
      const response = await api.get<ICategoryData>(`categories/courses`, {
        params: {
          page: pageData,
          plan: product && product.plan?.id,
          usersCourses: true,
        },
      });

      const data = response.data.data.map((category) => {
        const coursesData = category.courses.map((course) => {
          let { lock } = course;
          if (course.usersCourses) {
            const userCourse = course.usersCourses.find(
              (userCourseData: any) => userCourseData.user_id === user.id
            );
            if (lock) {
              lock = !userCourse;
            }
          }
          return {
            ...course,
            lock,
          };
        });

        return {
          ...category,
          courses: coursesData,
        };
      });

      if (pageData === 1) {
        setCategories(data);
        setPageCourse(1);
      } else {
        setCategories((state) => [...state, ...data]);
      }
      setLastPage(response.data.last_page);
    },
    [product, user.id]
  );

  const loadCourses = useCallback(
    (pageData: number, searchData: string) => {
      cancelTokenCourse = axios.CancelToken.source();
      api
        .get<ICourseData>(`courses`, {
          cancelToken: cancelTokenCourse.token,
          params: {
            page: pageData,
            search: searchData,
            plan: product && product.plan?.id,
            usersCourses: true,
          },
        })
        .then((response) => {
          const data = response.data.data.map((course: any) => {
            let { lock } = course;
            if (course.usersCourses) {
              const userCourse = course.usersCourses.find(
                (userCourseData: any) => userCourseData.user_id === user.id
              );
              if (lock) {
                lock = !userCourse;
              }
            }
            return {
              ...course,
              lock,
            };
          });
          if (pageData === 1) {
            setCourses(data);
            setPageCourse(1);
          } else {
            setCourses((state) => [...state, ...data]);
          }
          setLastPageCourse(response.data.last_page);
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            console.log(error);
          }
        });
    },
    [product, user.id]
  );

  useEffect(() => {
    if (user.way_id) {
      loadCategory(1)
        .then(async () => {
          const response = await api.get<ICategory[]>('categories/highlights');
          const responseContinueWatching = await api.get<ICourse[]>(
            'courses/continue-watching'
          );
          setContinueWatchingCourses(responseContinueWatching.data);
          setCategoriesHighlights(response.data);
        })
        .finally(() => {
          setLoadingHighlights(false);
          setLoadingContinueWatching(false);
          setLoading(false);
        });
    }
  }, [loadCategory, user.way_id]);

  const loadCourseName = useCallback((value: string) => {
    cancelTokenCourseName = axios.CancelToken.source();
    if (value.length > 0) {
      api
        .get('courses/name', {
          cancelToken: cancelTokenCourseName.token,
          params: {
            search: value,
          },
        })
        .then((response) => {
          if (response.data.length) {
            setCoursesName(response.data);
          }
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            console.log(error);
          }
        });
    } else {
      setCoursesName([]);
    }
  }, []);

  const handleSearch = useCallback(
    async (value) => {
      if (cancelTokenCourse) {
        cancelTokenCourse.cancel('canceled');
      }

      if (cancelTokenCourseName) {
        cancelTokenCourseName.cancel('canceled');
      }

      if (searchCourseRef.current && value.length > 0) {
        searchCourseRef.current.focus();
      } else if (searchRef.current) {
        searchRef.current.focus();
      }

      loadCourseName(value);
      loadCourses(1, value);
      setSearch(value);
    },
    [loadCourseName, loadCourses]
  );

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadCategory(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [lastPage, loadCategory, page]);

  const handleLoadCourses = useCallback(async () => {
    try {
      if (pageCourse < lastPageCourse) {
        setLoadingMoreCourse(true);
        await loadCourses(pageCourse + 1, search);
        setPageCourse(pageCourse + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingMoreCourse(false);
    }
  }, [lastPageCourse, loadCourses, pageCourse, search]);

  const handleClickCourseName = useCallback(
    async (value) => {
      if (value.length > 0) {
        const response = await api.get('courses/name', {
          params: {
            search: value,
          },
        });

        if (response.data.length) {
          setCoursesName(response.data);
        }
      } else {
        setCoursesName([]);
      }

      loadCourses(1, value);
      setSearch(value);
    },
    [loadCourses]
  );

  return (
    <Container
      className="content"
      scrollLoadThreshold={100}
      onInfiniteLoad={handleLoad}
    >
      <div className="container">
        <div className="row align-items-center mt-5">
          <div className="col-lg-5">
            <h1 className="fw-semibold text-white mb-5">Olá, {user.name}</h1>
          </div>
        </div>
        <div className="row align-items-center mb-4 mb-lg-5">
          <div className="col-md-5">
            <Search
              searchRef={searchRef}
              onSearch={handleSearch}
              value={search}
            />
          </div>
          <div className="col-md-7 pt-5 pt-md-0">
            <div className="row justify-content-end">
              <div className="col-6 col-md-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/certificados`}
                  className="d-flex justify-content-start text-white w-100"
                >
                  <FaCertificate size={24} color="#fff" className="me-3" />
                  <span>Certificados</span>
                </Link>
              </div>
              <div className="col-6 col-md-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/favoritos`}
                  className="d-flex justify-content-start text-white w-100"
                >
                  <IoIosStar size={24} color="#fff" className="me-3" />
                  <span>Favoritos</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xl px-0 px-lg-3 pb-5">
        <Categories className="row pt-4 pt-lg-5">
          <div className="col-12 overflow-auto pb-2">
            <div className="d-flex mx-auto categories h-100">
              {!loadingHighlights ? (
                <>
                  {categoriesHighlights.map((category) => (
                    <div key={category.id} className="mx-2 h-100">
                      <Link
                        to={`${process.env.PUBLIC_URL}/categorias/${category.slug}`}
                        className="d-flex flex-column align-items-center bg-dark-2 text-white text-center py-4 px-2 h-100"
                      >
                        <img
                          src={category.icon}
                          alt={category.name}
                          className="mb-2"
                        />
                        <span className="text-gray">{category.name}</span>
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="mx-2 h-100">
                    <Skeleton width="100%" height={132} radius={10} />
                  </div>
                  <div className="mx-2 h-100">
                    <Skeleton width="100%" height={132} radius={10} />
                  </div>
                  <div className="mx-2 h-100">
                    <Skeleton width="100%" height={132} radius={10} />
                  </div>
                  <div className="mx-2 h-100">
                    <Skeleton width="100%" height={132} radius={10} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-12 mt-3 px-3 px-md-5 px-lg-4 d-flex justify-content-end">
            <Link
              to={`${process.env.PUBLIC_URL}/categorias`}
              className="text-gray fw-medium more"
            >
              Ver outras categorias
            </Link>
          </div>
        </Categories>
        {continueWatchingCourses.length > 0 && (
          <Courses className="mt-4 mt-lg-5">
            <h2 className="text-white h5 ms-3 mb-5">Continue assistindo</h2>
            {!loadingContinueWatching ? (
              <Slider>
                {continueWatchingCourses.map((course) => (
                  <Course
                    key={course.id}
                    to={`${process.env.PUBLIC_URL}/cursos/${course.slug}`}
                    thumb={course.thumbnail}
                    title={course.title}
                    percent={course.percent || 0}
                  />
                ))}
              </Slider>
            ) : (
              <Slider>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
              </Slider>
            )}
          </Courses>
        )}
        {!loading ? (
          <>
            {categories.map((category) => (
              <Courses key={category.id} className="mt-4 mt-lg-5">
                <h2 className="text-white h5 ms-3">{category.name}</h2>
                <Slider>
                  {category.courses.map((course) => (
                    <Course
                      key={course.id}
                      type={course.lock ? 'upgrade' : 'link'}
                      to={`${process.env.PUBLIC_URL}/cursos/${course.slug}`}
                      thumb={course.thumbnail}
                      title={course.title}
                      icon={course.lock ? IoMdLock : undefined}
                      message="Curso bloqueado, faça o upgrade de seu plano"
                    />
                  ))}
                </Slider>
              </Courses>
            ))}
          </>
        ) : (
          <>
            <Courses className="mt-4 mt-lg-5">
              <div className="px-2 px-lg-3 pb-xl-4">
                <Skeleton width={250} height={24} radius={5} />
              </div>
              <Slider>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
              </Slider>
            </Courses>
            <Courses className="mt-4 mt-lg-5">
              <div className="px-2 px-lg-3 pb-xl-4">
                <Skeleton width={250} height={24} radius={5} />
              </div>
              <Slider>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
              </Slider>
            </Courses>
            <Courses className="mt-4 mt-lg-5">
              <div className="px-2 px-lg-3 pb-xl-4">
                <Skeleton width={250} height={24} radius={5} />
              </div>
              <Slider>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
              </Slider>
            </Courses>
            <Courses className="mt-4 mt-lg-5">
              <div className="px-2 px-lg-3 pb-xl-4">
                <Skeleton width={250} height={24} radius={5} />
              </div>
              <Slider>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
                <div className="px-2 px-lg-3 pb-xl-4">
                  <Skeleton width="100%" height={286} radius={10} />
                </div>
              </Slider>
            </Courses>
          </>
        )}
      </div>
      <SearchCourses show={search.length > 0} className="bg-dark-2 py-5">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-3">
              <Search
                searchRef={searchCourseRef}
                onSearch={handleSearch}
                value={search}
              />
              <AutoComplete className="d-flex flex-column align-items-start">
                {coursesName.map((courseName, index) => (
                  <button
                    key={index.toString()}
                    type="button"
                    onClick={() => handleClickCourseName(courseName)}
                    className="border-0 bg-transparent px-3 pt-3 pb-0"
                  >
                    {courseName}
                  </button>
                ))}
              </AutoComplete>
            </div>
            {courses.length > 0 ? (
              <List
                scrollLoadThreshold={100}
                onInfiniteLoad={handleLoadCourses}
                className="col-md-9 custom-height"
              >
                <div className="row">
                  {courses.map((course) => (
                    <div key={course.id} className="col-md-4">
                      <Course
                        key={course.id}
                        type={course.lock ? 'upgrade' : 'link'}
                        to={`${process.env.PUBLIC_URL}/cursos/${course.slug}`}
                        thumb={course.thumbnail}
                        title={course.title}
                        icon={course.lock ? IoMdLock : undefined}
                        message="Curso bloqueado, faça o upgrade de seu plano"
                      />
                    </div>
                  ))}
                </div>
                {loadingMoreCourse && (
                  <div className="row pb-5">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                )}
              </List>
            ) : (
              <div className="col-md-9 h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="animation">
                  <Lottie
                    options={{
                      animationData: magnifier,
                      autoplay: true,
                      loop: true,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={250}
                    width={375}
                  />
                </div>
                <h3 className="mt-4">
                  Desculpe não encontramos cursos para essa busca
                </h3>
              </div>
            )}
          </div>
        </div>
      </SearchCourses>
    </Container>
  );
};

export default Dashboard;
