import styled from 'styled-components';

interface INotifications {
  opened: boolean;
}

export const Container = styled.div<INotifications>`
  transition-duration: 0.3s;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  visibility: ${(props) => (props.opened ? 'visible' : 'hidden')};
  z-index: 1000;

  > button {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    cursor: default !important;
  }

  > div {
    transition-duration: 0.3s;
    position: relative;
    right: ${(props) => (props.opened ? 0 : '-1000px')};
    width: 320px;
    height: 100%;
    overflow: auto;
  }
`;

export const Notification = styled.div`
  background: #303030;
  border: 1px solid #3a3a3a;
  border-radius: 10px;

  + div {
    margin-top: 20px;
  }
`;
