import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosDownload } from 'react-icons/io';
import Pdf from '@mikecousins/react-pdf';
import { IconType } from 'react-icons/lib';

import { Container, ResourceLink, ResourceButton } from './styles';

interface IResource {
  type?: 'img' | 'pdf';
  kind?: 'button' | 'link';
  to: string;
  thumb: string;
  title: string;
  icon?: IconType;
  onClick?(): void;
  className?: string;
  message?: string;
}

const Resource: React.FC<IResource> = ({
  type = 'img',
  kind = 'link',
  to,
  thumb,
  title,
  icon: Icon,
  message,
  onClick,
  className,
}) => {
  const pdfRef = useRef<HTMLCanvasElement>(null);
  const [ResourceContent, setResourceContent] = useState<any>(ResourceLink);
  const [pdfImg, setPdfImg] = useState('');

  useEffect(() => {
    if (kind === 'button') {
      setResourceContent(ResourceButton);
    } else if (kind === 'link') {
      setResourceContent(ResourceLink);
    }
  }, [kind]);

  const handlePageLoadSuccess = useCallback(async () => {
    try {
      const pdfBlob: Blob = await new Promise((resolve: any) => {
        if (pdfRef.current) {
          pdfRef.current.toBlob(resolve);
        } else {
          resolve();
        }
      });

      if (pdfBlob) {
        setPdfImg(URL.createObjectURL(pdfBlob));
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container className={`px-lg-3 pb-4 ${className}`}>
      {type === 'pdf' && (
        <Pdf
          ref={pdfRef}
          file={thumb}
          page={1}
          onPageRenderSuccess={handlePageLoadSuccess}
          className="d-none"
        />
      )}
      <ResourceContent
        href={to}
        src={type === 'pdf' ? pdfImg : thumb}
        className="d-block position-relative w-100 pb-4 border-0 bg-transparent"
        target="_blank"
        onClick={onClick}
      >
        {Icon && (
          <div className="flag">
            <Icon size={24} color="#fff" />
            {message && <span className="d-flex flex-column">{message}</span>}
          </div>
        )}
        <div className="thumb mb-4" />
        <div className="d-flex justify-content-between align-items-center resource-title">
          <span className="text-gray me-4">{title}</span>
          <div className="icon">
            <IoIosDownload size={36} color="#fff" />
          </div>
        </div>
      </ResourceContent>
    </Container>
  );
};

export default Resource;
