import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { VscGitCompare } from 'react-icons/vsc';

import {
  IoMdArrowDropdown,
  IoMdHelpCircleOutline,
  IoMdNotifications,
} from 'react-icons/io';
import { RiStore2Line, RiUser3Line } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { useAuth } from '~/hooks/Auth';
import {
  Container,
  MenuButton,
  Menu,
  MenuItem,
  Profile,
  Avatar,
  ProfileOptions,
  Spacer,
} from './styles';
import Notifications from './Notifications';

import logo from '~/assets/logos/logo.svg';
import avatar from '~/assets/defaults/avatar.png';

interface IParams {
  productSlug: string;
}

const Header: React.FC = () => {
  const { user, signOut } = useAuth();
  const location = useLocation();
  const params = useParams<IParams>();
  const [menuOpened, setMenuOpened] = useState(false);
  const [profileOpened, setProfileOpened] = useState(false);
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);

  useEffect(() => {
    setProfileOpened(false);
    setMenuOpened(false);
  }, [location.pathname]);

  const handleClickProfile = useCallback(() => {
    setProfileOpened((state) => !state);
  }, []);

  const handleClickNotifications = useCallback(() => {
    setNotificationOpened((state) => !state);
  }, []);

  const handleClickMenu = useCallback(() => {
    setMenuOpened((state) => !state);
  }, []);

  const handleClickSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <>
      <Container className="bg-dark-2">
        <div className="container d-flex align-items-center justify-content-between flex-lg-row">
          <div className="logo px-lg-5">
            <Link
              to={`${process.env.PUBLIC_URL}${
                params.productSlug ? `/${params.productSlug}` : ''
              }/dashboard`}
            >
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="d-block d-lg-none d-flex align-items-center">
            <button
              type="button"
              onClick={handleClickNotifications}
              className="bg-transparent border-0 me-3 position-relative btn-notification"
            >
              <IoMdNotifications size={24} color="#BBBBBB" />
              {hasUnread && <span className="news-notifications" />}
            </button>
            <MenuButton opened={menuOpened} onClick={handleClickMenu}>
              <span />
              <span />
              <span />
            </MenuButton>
          </div>
          <Menu
            opened={menuOpened}
            className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start px-5"
          >
            <MenuItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}${
                  params.productSlug ? `/${params.productSlug}` : ''
                }/dashboard`}
                activeClassName="active"
              >
                Home
              </NavLink>
            </MenuItem>
            {params.productSlug ? (
              <MenuItem>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/${params.productSlug}/certificados`}
                  activeClassName="active"
                >
                  Certificados
                </NavLink>
              </MenuItem>
            ) : (
              <>
                <MenuItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/favoritos`}
                    activeClassName="active"
                  >
                    Favoritos
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/downloads`}
                    activeClassName="active"
                  >
                    Downloads
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  {/* <NavLink
                    to={`${process.env.PUBLIC_URL}/forum`}
                    activeClassName="active"
                  >
                    Fórum
                  </NavLink> */}
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/certificados`}
                    activeClassName="active"
                  >
                    Certificados
                  </NavLink>
                </MenuItem>
              </>
            )}
            <MenuItem>
              <Profile className="d-flex">
                <button
                  type="button"
                  className="bg-transparent border-0 d-none d-lg-flex align-items-center"
                  onClick={handleClickProfile}
                >
                  <Avatar src={user.avatar ? user.avatar.avatar_url : avatar} />
                  <IoMdArrowDropdown size={18} color="#fff" className="ms-1" />
                </button>
                <button
                  type="button"
                  onClick={handleClickNotifications}
                  className="bg-transparent border-0 d-none d-lg-block ms-4 position-relative btn-notification"
                >
                  <IoMdNotifications size={24} color="#BBBBBB" />
                  {hasUnread && <span className="news-notifications" />}
                </button>
                <ProfileOptions
                  open={profileOpened}
                  className="d-flex flex-column bg-dark-2 px-lg-3"
                >
                  {!params.productSlug && (
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/caminhos`}
                      className="py-lg-2 position-relative way"
                    >
                      {user.way?.title}
                      <small className="text-gray d-block">
                        Trocar <VscGitCompare size={11} color="#BBBBBB" />
                      </small>
                    </NavLink>
                  )}
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/produtos`}
                    className="py-lg-2 position-relative"
                    activeClassName="active"
                  >
                    <RiStore2Line size={24} color="#BBBBBB" /> Produtos
                  </NavLink>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}${
                      params.productSlug ? `/${params.productSlug}` : ''
                    }/perfil`}
                    className="py-lg-2 position-relative"
                    activeClassName="active"
                  >
                    <RiUser3Line size={24} color="#BBBBBB" /> Perfil
                  </NavLink>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}${
                      params.productSlug ? `/${params.productSlug}` : ''
                    }/suporte`}
                    className="py-lg-2 position-relative"
                    activeClassName="active"
                  >
                    <IoMdHelpCircleOutline size={24} color="#BBBBBB" /> Suporte
                  </NavLink>
                  <button
                    type="button"
                    onClick={handleClickSignOut}
                    className="border-0 bg-transparent text-start pt-5 pt-lg-2 pb-lg-3 text-primary position-relative"
                  >
                    <FiLogOut size={24} color="#E50914" /> Sair
                  </button>
                </ProfileOptions>
              </Profile>
            </MenuItem>
          </Menu>
        </div>
        <Notifications
          opened={notificationOpened}
          onClose={() => setNotificationOpened(false)}
          setHasUnread={setHasUnread}
        />
      </Container>
      <Spacer />
    </>
  );
};

export default Header;
