import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Header from '~/components/Header';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

interface IParams {
  productSlug?: string;
}

const DefaultLayout: React.FC = ({ children }) => {
  const { user, signOut } = useAuth();
  const params = useParams<IParams>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (
      !user.way_id &&
      location.pathname !== '/meu-perfil' &&
      location.pathname !== '/produtos' &&
      location.pathname !== '/caminhos' &&
      !location.pathname.includes('/meus-cursos') &&
      !params.productSlug
    ) {
      history.push(`${process.env.PUBLIC_URL}/caminhos`);
    }
  }, [location.pathname, user.way_id, history, params.productSlug]);

  useEffect(() => {
    api.get('check-login-student').catch(() => {
      signOut();
    });
  }, [signOut]);

  return (
    <>
      {((location.pathname !== '/meu-perfil' &&
        location.pathname !== '/produtos' &&
        !location.pathname.includes('/meus-cursos') &&
        user.way_id) ||
        params.productSlug) && <Header />}
      {children}
    </>
  );
};

export default DefaultLayout;
