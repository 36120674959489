import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Carousel, { Settings } from 'react-slick';

import { ButtonLeft, ButtonRight } from './styles';

interface IButtonProps {
  className?: string;
  onClick?(): void;
}

interface ISliderProps {
  onAfterChange?(index: number): void;
}

const PrevArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonLeft
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <FaChevronLeft />
      </div>
    </ButtonLeft>
  );
};

const NextArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonRight
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <FaChevronRight />
      </div>
    </ButtonRight>
  );
};

const Slider: React.FC<ISliderProps> = ({ onAfterChange, children }) => {
  const [width, setWidth] = useState(0);
  const [auxElements, setAuxElements] = useState<number[]>([]);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    const elements = children as React.ReactNode[];
    let qtd = elements.length;
    const elementQtd = [];
    if (width >= 1200) {
      if (elements.length < 4) {
        while (qtd < 4) {
          elementQtd.push(qtd);
          qtd += 1;
        }
      }
    } else if (width >= 992) {
      if (elements.length < 2) {
        while (qtd < 2) {
          elementQtd.push(qtd);
          qtd += 1;
        }
      }
    }
    setAuxElements(elementQtd);
  }, [children, width]);

  const settings: Settings = useMemo(
    () => ({
      infinite: false,
      slidesToShow: 4,
      centerPadding: '0px',
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: true,
      touchMove: true,
      afterChange: onAfterChange,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 4,
            centerPadding: '0px',
            infinite: false,
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            centerPadding: '0px',
            infinite: false,
          },
        },
        {
          breakpoint: 991,
          settings: {
            arrows: false,
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '225px',
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '150px',
            infinite: false,
          },
        },
        {
          breakpoint: 575,
          settings: {
            arrows: false,
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '25px',
            infinite: false,
          },
        },
      ],
    }),
    [onAfterChange]
  );

  return (
    <Carousel {...settings}>
      {children}
      {auxElements.map((auxElement) => (
        <div key={auxElement} />
      ))}
    </Carousel>
  );
};

export default Slider;
