import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  IoIosPlay,
  IoMdPlayCircle,
  IoIosTime,
  IoIosArrowDown,
  IoMdCheckmarkCircle,
  IoIosPaper,
  IoIosReturnLeft,
  IoMdBookmark,
  IoMdLock,
} from 'react-icons/io';
import { formatDuration } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Swal from 'sweetalert2';

import { FiArrowLeft } from 'react-icons/fi';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { initializeSocketEvents } from '~/services/socket';
import { useAuth } from '~/hooks/Auth';

import {
  Container,
  Banner,
  ProgressBar,
  Module,
  Courses,
  Modal,
} from './styles';
import Slider from '~/components/Slider';
import CourseContent from '~/components/Course';
import Loading from '~/components/Loading';
import { useProduct } from '~/hooks/Product';
import Player from '~/components/Player';

interface IParams {
  slug: string;
  productSlug?: string;
}

interface ILesson {
  id: string;
  title: string;
  duration: string;
  slug: string;
  userLesson?: {
    id: string;
    views: number;
    time: string;
    completed: boolean;
  };
}

interface IModule {
  id: string;
  title: string;
  slug: string;
  lessons: ILesson[];
}

interface IFavorite {
  id: string;
}

interface ICourseCategory {
  category_id: string;
}

interface IOrder {
  id: string;
  status: string;
}

interface ICourseSale {
  id: string;
  price: number;
  has_discount?: boolean;
  discount?: number;
  checkout_url: string;
  orders?: IOrder[];
}

interface ICourseResponse {
  id: string;
  coursesCategories: ICourseCategory[];
  title: string;
  description: string;
  workload: string;
  wallpaper: {
    wallpaper_url: string;
  };
  video: {
    video_url: string;
    link: string;
  };
  courseSale?: ICourseSale;
  favorites?: IFavorite[];
  modules: IModule[];
  exam?: {
    id: string;
  };
}

interface ISimilar {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
  lock?: boolean;
}

interface ICourse {
  id: string;
  favorite_id?: string;
  title: string;
  description: string;
  workload: string;
  wallpaper: string;
  trailer: string;
  trailerLink: string;
  videos: number;
  watching: boolean;
  completed: boolean;
  percent: number;
  modules: IModule[];
  similars: ISimilar[];
  courseSale?: ICourseSale;
  exam?: {
    id: string;
  };
  purchased?: boolean;
}

interface IUserCourse {
  id: string;
  user_id: string;
  course_id: string;
  watching?: boolean;
  completed?: boolean;
}

const Course: React.FC = () => {
  const { user } = useAuth();
  const { product } = useProduct();
  const history = useHistory();
  const params = useParams<IParams>();
  const location = useLocation();
  const [course, setCourse] = useState({} as ICourse);
  const [moduleSelected, setModuleSelected] = useState('');
  const [showTrailer, setShowTrailer] = useState(false);
  const [userCourse, setUserCourse] = useState({} as IUserCourse);
  const [productSlug, setProductSlug] = useState('');
  const [showLoading, setShowLoading] = useState(false);

  const path = useMemo(() => {
    return location.pathname.includes('meus-cursos') ? 'meus-cursos' : 'cursos';
  }, [location.pathname]);

  useEffect(() => {
    if (params.productSlug) {
      setProductSlug(`/${params.productSlug}`);
    }
  }, [params.productSlug]);

  useEffect(() => {
    api
      .get<ICourseResponse>(`courses/${params.slug}`)
      .then(async (response) => {
        const responseUserCourse = await api.get<IUserCourse>(
          `users-courses/${response.data.id}`
        );

        let similars: ISimilar[] = [];
        if (
          !responseUserCourse.data.watching &&
          !responseUserCourse.data.completed
        ) {
          const categories_id = response.data.coursesCategories.map(
            (courseCategory) => courseCategory.category_id
          );
          const responseSimilars = await api.get<ISimilar[]>(
            `courses/similars/${response.data.id}`,
            {
              params: {
                categories_id,
                productSlug: params.productSlug,
                plan: product && product.plan?.id,
              },
            }
          );
          similars = responseSimilars.data;
        }

        const videos = response.data.modules.reduce(
          (acumulador: number, currentValue: IModule) => {
            return acumulador + currentValue.lessons.length;
          },
          0
        );
        const [hours, minutes, seconds] = response.data.workload.split(':');
        let workload = formatDuration(
          {
            hours: parseInt(hours, 10),
            minutes: parseInt(minutes, 10),
            seconds: parseInt(seconds, 10),
          },
          {
            delimiter: ', ',
            locale: ptBR,
          }
        );

        workload = workload.replace(/,([^,]*)$/, ' e$1');

        let courseSale: ICourseSale | undefined;

        if (response.data.courseSale) {
          courseSale = {
            id: response.data.courseSale.id,
            price: response.data.courseSale.price,
            has_discount: response.data.courseSale.has_discount,
            discount: response.data.courseSale.discount,
            checkout_url: response.data.courseSale.checkout_url,
          };
        }

        let qtdLessonsComplete = 0;
        response.data.modules.forEach((module) => {
          qtdLessonsComplete = module.lessons.reduce(
            (acumulador: number, currentValue: ILesson) => {
              const value =
                currentValue.userLesson && currentValue.userLesson.completed
                  ? 1
                  : 0;
              return acumulador + value;
            },
            qtdLessonsComplete
          );
        });

        const percent = (qtdLessonsComplete * 100) / videos;

        let purchased = false;
        if (response.data.courseSale && response.data.courseSale.orders) {
          const order = response.data.courseSale.orders.find(
            (orderData) => orderData.status === 'Paid'
          );

          purchased = !!order;
        }

        setCourse({
          id: response.data.id,
          favorite_id:
            response.data.favorites && response.data.favorites.length > 0
              ? response.data.favorites[0].id
              : undefined,
          title: response.data.title,
          description: response.data.description,
          workload,
          wallpaper: response.data.wallpaper.wallpaper_url,
          trailer: response.data.video.video_url,
          trailerLink: response.data.video.link,
          videos,
          watching: !!responseUserCourse.data.watching,
          completed: percent === 100 || !!responseUserCourse.data.completed,
          modules: response.data.modules,
          similars,
          courseSale,
          percent,
          exam: response.data.exam,
          purchased,
        });
        setUserCourse(responseUserCourse.data);
      });
  }, [params.productSlug, params.slug, product]);

  const handleClose = useCallback(() => {
    setShowTrailer(false);
  }, []);

  const handleClickModule = useCallback(
    (value) => {
      if (value === moduleSelected) {
        setModuleSelected('');
      } else {
        setModuleSelected(value);
      }
    },
    [moduleSelected]
  );

  const handleClickPlay = useCallback(() => {
    const formData = {
      user_id: userCourse.user_id,
      course_id: userCourse.course_id,
      watching: true,
      completed: userCourse.completed,
    };
    api.put(`users-courses/${userCourse.id}`, formData);

    const data = {
      id: userCourse.id,
      ...formData,
    };

    setCourse({
      id: course.id,
      title: course.title,
      description: course.description,
      workload: course.workload,
      wallpaper: course.wallpaper,
      trailer: course.trailer,
      trailerLink: course.trailerLink,
      videos: course.videos,
      watching: data.watching,
      completed: !!data.completed,
      modules: course.modules,
      similars: course.similars,
      courseSale: course.courseSale,
      percent: course.percent,
    });

    setUserCourse(data);

    history.push(
      `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slug}/${course.modules[0].slug}/${course.modules[0].lessons[0].slug}`
    );
  }, [
    course,
    history,
    params.slug,
    userCourse.completed,
    userCourse.course_id,
    userCourse.id,
    userCourse.user_id,
    productSlug,
    path,
  ]);

  const handleClickFavorite = useCallback(async () => {
    if (course.favorite_id) {
      await api.delete(`favorites/${course.favorite_id}`);
      const newCourse = { ...course, favorite_id: undefined };
      setCourse(newCourse);
    } else {
      const response = await api.post('favorites', {
        course_id: course.id,
      });
      const newCourse = { ...course, favorite_id: response.data.id };
      setCourse(newCourse);
    }
  }, [course]);

  const handleClickContinue = useCallback(() => {
    let moduleSelectedData: IModule | undefined;
    let lessonSelected: ILesson | undefined;
    course.modules.forEach((module) => {
      if (!lessonSelected) {
        lessonSelected = module.lessons.find(
          (lesson) =>
            !lesson.userLesson ||
            (lesson.userLesson && !lesson.userLesson.completed)
        );
        moduleSelectedData = module;
      }
    });

    if (moduleSelectedData && lessonSelected) {
      history.push(
        `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slug}/${moduleSelectedData.slug}/${lessonSelected.slug}`
      );
    } else if (moduleSelectedData) {
      history.push(
        `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slug}/${moduleSelectedData.slug}/${moduleSelectedData.lessons[0].slug}`
      );
    } else {
      history.push(
        `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slug}/${course.modules[0].slug}/${course.modules[0].lessons[0].slug}`
      );
    }
  }, [course.modules, history, params.slug, productSlug, path]);

  const handleClickWhatchAgain = useCallback(async () => {
    try {
      course.modules.forEach((module) => {
        module.lessons.forEach((lesson) => {
          if (lesson.userLesson) {
            const formData = {
              lesson_id: lesson.id,
              views: lesson.userLesson ? lesson.userLesson.views : 0,
              time: lesson.userLesson ? lesson.userLesson.time : '00:00:00',
              completed: false,
            };

            api.post('users-lessons', formData);
          }
        });
      });

      const formData = {
        user_id: userCourse.user_id,
        course_id: userCourse.course_id,
        watching: true,
        completed: userCourse.completed,
      };
      api.put(`users-courses/${userCourse.id}`, formData);

      history.push(
        `${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slug}/${course.modules[0].slug}/${course.modules[0].lessons[0].slug}`
      );
    } catch (error) {
      console.log(error);
    }
  }, [course.modules, history, params.slug, productSlug, userCourse, path]);

  const handleCreateCourseOrder = useCallback(
    async (courseSale: ICourseSale, user_id: string) => {
      try {
        let amount_paid = courseSale.price;
        if (courseSale.has_discount && courseSale.discount) {
          amount_paid = courseSale.discount;
        }

        const formData = {
          course_sale_id: courseSale.id,
          user_id,
          payment_type: 'Credit card',
          amount_paid,
          is_recurrent: false,
          status: 'Awaiting Payment',
        };

        return await api.post('orders', formData);
      } catch (error) {
        console.log(error);
      }

      return undefined;
    },
    []
  );

  const handlePaid = useCallback(() => {
    try {
      setCourse((state) => ({ ...state, purchased: true }));
      setShowLoading(false);
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro ao logar, verifique seus dados.',
        'error'
      );
    }
  }, []);

  const handleClickBuy = useCallback(async () => {
    if (course.courseSale) {
      const responseOrder = await handleCreateCourseOrder(
        course.courseSale,
        user.id
      );
      if (responseOrder) {
        if (course.courseSale.price === 0 || course.courseSale.discount === 0) {
          const formData = {
            course_id: responseOrder.data.course_id,
            user_id: responseOrder.data.user_id,
            payment_type: responseOrder.data.payment_type,
            amount_paid: responseOrder.data.amount_paid,
            is_recurrent: responseOrder.data.is_recurrent,
            status: 'Paid',
          };
          await api.put(`orders/${responseOrder.data.id}`, formData);
          handlePaid();
        } else {
          setShowLoading(true);
          window.open(course.courseSale.checkout_url, '_blank');
          initializeSocketEvents({
            onPaid: handlePaid,
            order_id: responseOrder.data.id,
            user_id: responseOrder.data.user_id,
          });
        }
      }
    }
  }, [course, handleCreateCourseOrder, handlePaid, user.id]);

  return Object.keys(course).length > 0 ? (
    <>
      <Container>
        <Banner src={course.wallpaper} />
        <div className="container pt-5">
          <div className="row align-items-center align-items-lg-start justify-content-between pt-5 pb-lg-5">
            <div className="col-lg-8 col-xl-6">
              <div className="d-flex my-4">
                <Link
                  to={`${process.env.PUBLIC_URL}/${
                    path === 'meus-cursos' ? path : 'dashboard'
                  }`}
                  className="btn border-0 bg-transparent"
                >
                  <FiArrowLeft size={24} color="#fff" />
                </Link>
                <h1 className="fw-semibold text-white">{course.title}</h1>
              </div>
              <p className="text-gray">{course.description}</p>
            </div>
            {course.courseSale && !course.purchased && (
              <div className="col-lg-4">
                <h2 className="fw-semibold text-white text-end">
                  {course.courseSale.has_discount && course.courseSale.discount
                    ? formatPrice(course.courseSale.discount)
                    : formatPrice(course.courseSale.price)}
                </h2>
              </div>
            )}
            <div className="col-12 mt-3">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex justify-content-start w-100 w-lg-auto mb-4 mb-lg-0">
                  <div className="d-flex me-4">
                    <IoMdPlayCircle size={24} color="#fff" className="me-2" />
                    <p className="mb-0">{course.videos} videos</p>
                  </div>
                  <div className="d-flex ms-4">
                    <IoIosTime size={24} color="#fff" className="me-2" />
                    <p className="mb-0">{course.workload}</p>
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between justify-content-md-end align-items-center w-100 w-lg-auto">
                  {((course.courseSale && !course.purchased) ||
                    (!course.watching && !course.completed)) && (
                    <button
                      type="button"
                      onClick={() => setShowTrailer(true)}
                      className="btn btn-gray px-5 py-2 d-flex align-items-center justify-content-center fw-semibold w-100 w-md-auto my-2 my-lg-0 order-1 order-md-0"
                    >
                      <IoIosPlay size={24} color="#202020" className="me-2" />
                      Trailer
                    </button>
                  )}
                  {(course.completed || course.percent === 100) && course.exam && (
                    <Link
                      to={`${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slug}/prova`}
                      className="btn btn-white px-5 py-2 d-flex align-items-center justify-content-center fw-semibold w-100 w-md-auto my-2 my-lg-0 order-1 order-md-0"
                    >
                      <IoIosPaper size={24} color="#009D65" className="me-2" />
                      Realizar Teste
                    </Link>
                  )}
                  {(!course.courseSale || course.purchased) &&
                    !course.watching &&
                    !course.completed && (
                      <button
                        type="button"
                        className="btn btn-primary px-5 py-2 d-flex align-items-center justify-content-center fw-semibold w-100 w-md-auto my-2 my-lg-0 ms-md-3 order-0"
                        onClick={handleClickPlay}
                      >
                        <IoIosPlay size={24} color="#fff" className="me-2" />
                        Iniciar
                      </button>
                    )}
                  {course.watching && (
                    <button
                      type="button"
                      className="btn btn-primary px-5 py-2 d-flex align-items-center justify-content-center fw-semibold w-100 w-md-auto my-2 my-lg-0 ms-md-3 order-0"
                      onClick={handleClickContinue}
                    >
                      <IoIosPlay size={24} color="#fff" className="me-2" />
                      Continuar
                    </button>
                  )}
                  {!course.watching && course.completed && (
                    <button
                      type="button"
                      className="btn btn-gray px-5 py-2 d-flex align-items-center justify-content-center fw-semibold w-100 w-md-auto my-2 my-lg-0 ms-lg-3 order-0"
                      onClick={handleClickWhatchAgain}
                    >
                      <IoIosReturnLeft
                        size={24}
                        color="#202020"
                        className="me-2"
                      />
                      Assistir novamente
                    </button>
                  )}
                  {course.courseSale && !course.purchased && (
                    <button
                      type="button"
                      className="btn btn-primary px-5 py-2 d-flex align-items-center justify-content-center fw-semibold w-100 w-md-auto my-2 my-lg-0 ms-md-3 order-0"
                      onClick={handleClickBuy}
                    >
                      Comprar
                    </button>
                  )}
                </div>
              </div>
            </div>
            {!productSlug && path === 'cursos' && (
              <div className="col-12 mt-4">
                <button
                  type="button"
                  className="d-flex align-items-center border-0 bg-transparent"
                  onClick={handleClickFavorite}
                >
                  <IoMdBookmark
                    size={30}
                    color={course.favorite_id ? '#E50914' : '#bbbbbb'}
                    className="me-2"
                  />{' '}
                  {course.favorite_id ? (
                    <span className="text-primary">Salvo nos favoritos</span>
                  ) : (
                    <span className="text-gray">Adicionar nos favoritos</span>
                  )}
                </button>
              </div>
            )}
          </div>
          {(course.watching || course.completed) && (
            <div className="row justify-content-center pt-5 pb-5">
              <div className="col-12 mt-3 mb-5 pb-4 pb-lg-5">
                <ProgressBar percent={course.percent} className="mb-3" />
                <div className="d-flex justify-content-between">
                  <p className="mb-0 text-gray fw-medium">Progresso do curso</p>
                  <p className="mb-0 text-gray fw-medium">{course.percent}%</p>
                </div>
              </div>
              <div className="col-lg-6">
                {course.modules.map((module) => (
                  <Module
                    key={module.id}
                    opened={moduleSelected === module.title}
                    size={48 + 66 * module.lessons.length}
                  >
                    <button
                      type="button"
                      className="border-0 w-100 py-3 d-flex align-items-center justify-content-between px-5"
                      onClick={() => handleClickModule(module.title)}
                    >
                      <span>{module.title}</span>
                      <span>
                        {
                          module.lessons.filter(
                            (lesson) =>
                              lesson.userLesson && lesson.userLesson.completed
                          ).length
                        }
                        /{module.lessons.length}
                        <IoIosArrowDown
                          size={35}
                          color="#BBBBBB"
                          className="ms-3"
                        />
                      </span>
                    </button>
                    <div className="px-1 px-lg-3">
                      <div className="py-4">
                        {module.lessons.map((lesson) => (
                          <Link
                            key={lesson.id}
                            to={`${process.env.PUBLIC_URL}${productSlug}/${path}/${params.slug}/${module.slug}/${lesson.slug}`}
                            className="d-flex justify-content-between align-items-center p-3"
                          >
                            <span className="text-gray d-flex align-items-center title">
                              <IoMdCheckmarkCircle
                                size={30}
                                color="#009D65"
                                className={`me-1 me-md-3 ${
                                  lesson.userLesson &&
                                  lesson.userLesson.completed
                                    ? 'opacity-1'
                                    : 'opacity-0'
                                }`}
                              />
                              <span>{lesson.title}</span>
                            </span>
                            <span className="box text-white d-block px-3 py-1">
                              {lesson.duration}
                            </span>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </Module>
                ))}
              </div>
            </div>
          )}
        </div>
        {!course.watching && !course.completed && (
          <div className="container-fluid px-0 px-lg-3 pb-5">
            <Courses className="mt-5">
              <h2 className="text-white h5 ms-3">Semelhantes</h2>
              <Slider>
                {course.similars.map((similarCourse) => (
                  <CourseContent
                    key={similarCourse.id}
                    type={similarCourse.lock ? 'upgrade' : 'link'}
                    to={`${process.env.PUBLIC_URL}/${path}/${similarCourse.slug}`}
                    thumb={similarCourse.thumbnail}
                    title={similarCourse.title}
                    icon={similarCourse.lock ? IoMdLock : undefined}
                    message={
                      path === 'meus-cursos'
                        ? 'Assista esse curso assinando Leiautflix!'
                        : 'Curso bloqueado, faça o upgrade de seu plano'
                    }
                  />
                ))}
              </Slider>
            </Courses>
          </div>
        )}
        <Modal show={showTrailer} onHide={handleClose} close size="lg">
          <Modal.Header className="border-0 p-0" closeButton />
          <Modal.Body className="p-0">
            {course.trailer ? (
              <Player src={course.trailer} className="w-100 player" />
            ) : (
              <iframe
                src={`https://www.youtube.com/embed/${course.trailerLink}`}
                title={course.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-100 player mb-n2"
              />
            )}
          </Modal.Body>
        </Modal>
      </Container>
      <Loading
        show={showLoading}
        message="Aguardando a confirmação de pagamento"
      >
        <button
          type="button"
          className="btn btn-primary px-5 py-2 mt-3"
          onClick={() => setShowLoading(false)}
        >
          Cancelar
        </button>
      </Loading>
    </>
  ) : (
    <></>
  );
};

export default Course;
