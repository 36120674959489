import React, { useCallback, useLayoutEffect, useState } from 'react';

import Slider from '../Slider';

interface ISliderMobile {
  className?: string;
  onAfterChange?(index: number): void;
}

const SliderMobile: React.FC<ISliderMobile> = ({
  onAfterChange,
  className,
  children,
}) => {
  const [width, setWidth] = useState(0);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  return width <= 1199 ? (
    <Slider onAfterChange={onAfterChange}>{children}</Slider>
  ) : (
    <div className={className}>{children}</div>
  );
};

export default SliderMobile;
