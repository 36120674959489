import styled from 'styled-components';

export const ButtonLeft = styled.button`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.disabled ? 0 : 1)};
  visibility: ${(props) => (props.disabled ? 'hidden' : 'visible')};
  position: absolute;
  left: -2%;
  z-index: 1;
  background: linear-gradient(
    90deg,
    #202020 0%,
    #202020 20%,
    rgba(32, 32, 32, 0) 80%,
    rgba(32, 32, 32, 0) 100%
  ) !important;
  height: 100% !important;
  width: 9%;
  top: 0;
  transform: translateY(0%);

  div {
    background: rgba(32, 32, 32, 0.9);
    border: 1px solid #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #fff;
  }

  ::before {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    background: linear-gradient(
      90deg,
      #202020 0%,
      #202020 40%,
      rgba(32, 32, 32, 0) 100%
    ) !important;
    height: 200px !important;
  }
`;

export const ButtonRight = styled.button`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.disabled ? 0 : 1)};
  visibility: ${(props) => (props.disabled ? 'hidden' : 'visible')};
  position: absolute;
  right: -2%;
  z-index: 1;
  background: linear-gradient(
    270deg,
    #202020 0%,
    #202020 20%,
    rgba(32, 32, 32, 0) 80%,
    rgba(32, 32, 32, 0) 100%
  ) !important;
  height: 100% !important;
  width: 9%;
  top: 0;
  transform: translateY(0%);
  margin-right: 16px;

  div {
    background: rgba(32, 32, 32, 0.9);
    border: 1px solid #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #fff;
  }

  ::before {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    background: linear-gradient(
      270deg,
      #202020 0%,
      #202020 40%,
      rgba(32, 32, 32, 0) 100%
    ) !important;
    height: 200px !important;
  }
`;
