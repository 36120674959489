import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

interface IParams {
  hash: string;
}

const AutoLogin: React.FC = () => {
  const params = useParams<IParams>();
  const { autoSignIn } = useAuth();

  useEffect(() => {
    autoSignIn(params.hash);
  }, [autoSignIn, params.hash]);

  return <div />;
};

export default AutoLogin;
