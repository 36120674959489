import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import { Container } from './styles';

interface ICategory {
  id: string;
  name: string;
  icon: string;
  slug: string;
}

interface ICategoryData {
  current_page: number;
  last_page: number;
  data: ICategory[];
}

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadCategory = useCallback(async (pageData: number) => {
    const response = await api.get<ICategoryData>(`categories`, {
      params: {
        page: pageData,
      },
    });

    setCategories((state) => [...state, ...response.data.data]);
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadCategory(1);
  }, [loadCategory]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadCategory(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [lastPage, loadCategory, page]);

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row align-items-center pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4">
            <h1 className="fw-semibold text-white ms-4">Categorias</h1>
          </div>
          {categories.map((category) => (
            <div key={category.id} className="col-md-6 col-xl-3 mb-3 order-1">
              <Link
                to={`${process.env.PUBLIC_URL}/categorias/${category.slug}`}
                className="d-flex flex-column align-items-center bg-dark-2 text-white text-center py-4 px-2 w-100 h-100"
              >
                <img
                  src={category.icon}
                  alt={category.name}
                  className="mt-3 mb-4"
                />
                <span className="text-gray">{category.name}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Categories;
