import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { NavLink, useHistory } from 'react-router-dom';
import { IoMdLock } from 'react-icons/io';
import { FiLogOut } from 'react-icons/fi';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import { useProduct } from '~/hooks/Product';
import { formatPrice } from '~/utils/format';

import { Container, Avatar, Menu, ProductCard } from './styles';
import Skeleton from '~/components/Skeleton';

import avatar from '~/assets/defaults/avatar.png';

interface IPlan {
  id: string;
  name: string;
  price: number;
}

interface IProductUser {
  user_id: string;
  plan_id: string;
}

interface IProduct {
  id: string;
  title: string;
  description: string;
  slug: string;
  price: number;
  hasPlans: boolean;
  lock: boolean;
  thumbnail: {
    thumbnail_url: string;
  };
  plans: IPlan[];
  plan?: IPlan;
  usersProducts: IProductUser[];
}

const Products: React.FC = () => {
  const { user, signOut } = useAuth();
  const { setProduct } = useProduct();
  const history = useHistory();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [userName, setUserName] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setProduct(undefined);
  }, [setProduct]);

  useEffect(() => {
    const nameParts = user.name.split(' ');
    setUserName(`${nameParts[0]} ${nameParts[1]}`);
  }, [user.name]);

  useEffect(() => {
    api
      .get<IProduct[]>('products', {
        params: {
          noPagination: true,
          user_id: user.id,
        },
      })
      .then((response) => {
        const data = response.data.map((product) => {
          const hasProduct = product.usersProducts.find(
            (userProduct) => userProduct.user_id === user.id
          );

          if (hasProduct) {
            return { ...product, lock: false };
          }
          return { ...product, lock: true };
        });
        setProducts(data);
      })
      .finally(() => setLoading(false));
  }, [user.id]);

  const handleSelectProduct = useCallback(
    (product: IProduct) => {
      if (!product.lock) {
        let planSelected: IPlan | undefined;
        if (product.hasPlans && product.plans.length > 0) {
          const hasProduct = product.usersProducts.find(
            (userProduct) => userProduct.user_id === user.id
          );
          if (hasProduct) {
            planSelected = product.plans.find(
              (plan) => plan.id === hasProduct.plan_id
            );
          }
        }

        setProduct({ ...product, plan: planSelected });

        if (product.slug !== 'leiaut-flix' && product.slug !== 'leiautflix') {
          history.push(`${process.env.PUBLIC_URL}/${product.slug}/dashboard`);
        } else if (user.way_id) {
          history.push(`${process.env.PUBLIC_URL}/dashboard`);
        } else {
          history.push(`${process.env.PUBLIC_URL}/caminhos`);
        }
      } else {
        history.push(`${process.env.PUBLIC_URL}/${product.slug}`);
      }
    },
    [history, setProduct, user.id, user.way_id]
  );

  const handleClickAvatar = useCallback(() => {
    setShowMenu((state) => !state);
  }, []);

  const handleClickSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <div className="container">
        <div className="row align-items-center justify-content-between pt-5 pb-4">
          <div className="col-sm-8 col-lg-6 order-1 order-sm-0 my-3 my-sm-0">
            <p className="h5 mb-3 text-gray fw-medium">Bem-vindo</p>
            <h1 className="fw-semibold">Olá, {userName}</h1>
          </div>
          <div className="col-sm-4 col-lg-6 order-0 order-sm-0 d-flex justify-content-end">
            <div className="position-relative d-flex flex-column align-items-end">
              <button
                type="button"
                onClick={handleClickAvatar}
                className="rounded-circle border-0 position-relative btn-avatar"
              >
                <Avatar
                  src={user.avatar ? user.avatar?.avatar_url : avatar}
                  className="ms-auto"
                />
              </button>
              <Menu
                show={showMenu}
                className="position-absolute d-flex flex-column align-items-center px-3"
              >
                <NavLink
                  to={`${process.env.PUBLIC_URL}/meu-perfil`}
                  className="py-3"
                  activeClassName="active"
                >
                  Meu Perfil
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/meus-cursos`}
                  className="py-3"
                  activeClassName="active"
                >
                  Meus Cursos
                </NavLink>
                <button
                  type="button"
                  onClick={handleClickSignOut}
                  className="border-0 bg-transparent text-primary py-3"
                >
                  <FiLogOut size={24} color="#E50914" /> <span>S</span>
                  <span>a</span>
                  <span>i</span>
                  <span>r</span>
                </button>
              </Menu>
            </div>
          </div>
          <div className="col-12 mt-4">
            <p className="h5 fw-normal mb-5 text-gray">Selecione um produto</p>
          </div>
        </div>
      </div>
      <div className="container-xxl px-0 px-lg-3 px-xxl-0">
        <div className="row align-items-center py-5">
          {!loading ? (
            <>
              {products.map((product) => (
                <div key={product.id} className="col-lg-4 px-2 px-lg-3 mb-4">
                  <ProductCard
                    src={product.thumbnail.thumbnail_url}
                    onClick={() => handleSelectProduct(product)}
                    className="px-4 py-5 d-flex align-items-end position-relative"
                  >
                    {product.lock && (
                      <div className="flag">
                        <IoMdLock size={24} color="#fff" />
                        <span className="d-flex flex-column">
                          <span>
                            {product.hasPlans
                              ? 'Planos a partir de'
                              : 'No valor de'}
                            :
                          </span>
                          <b>
                            {product.hasPlans && product.plans.length > 0 ? (
                              <>
                                {product.plans[0].price > 0
                                  ? formatPrice(product.plans[0].price)
                                  : 'Grátis'}
                              </>
                            ) : (
                              <>
                                {product.price > 0
                                  ? formatPrice(product.price)
                                  : 'Grátis'}
                              </>
                            )}
                          </b>
                        </span>
                      </div>
                    )}
                    <div className="d-flex justify-content-between align-items-end w-100">
                      <div className="w-75">
                        <h2 className="h5 mb-3">{product.title}</h2>
                        <p className="small mb-0">{product.description}</p>
                      </div>
                      <FaArrowRight size={18} color="#fff" />
                    </div>
                  </ProductCard>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="col-lg-4 px-2 px-lg-3 mb-4">
                <Skeleton width="100%" height={300} />
              </div>
              <div className="col-lg-4 px-2 px-lg-3 mb-4">
                <Skeleton width="100%" height={300} />
              </div>
              <div className="col-lg-4 px-2 px-lg-3 mb-4">
                <Skeleton width="100%" height={300} />
              </div>
              <div className="col-lg-4 px-2 px-lg-3 mb-4">
                <Skeleton width="100%" height={300} />
              </div>
              <div className="col-lg-4 px-2 px-lg-3 mb-4">
                <Skeleton width="100%" height={300} />
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Products;
