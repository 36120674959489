import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdOutlineFileUpload, MdCameraAlt } from 'react-icons/md';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import Compressor from 'compressorjs';
import Webcam from 'react-webcam';

import { BsTrashFill } from 'react-icons/bs';
import {
  Container,
  PhotoContainer,
  UploadPhoto,
  TakePhoto,
  Modal,
} from './styles';
import Input from '~/components/Input';

interface IInputPhoto {
  name: string;
  className?: string;
  onChange?(value: File): void;
  value?: string;
  cropImage?: boolean;
  takePhoto?: boolean;
  aspect?: number;
  cropOptions?: Crop;
}

const TO_RADIANS = Math.PI / 180;

const InputPhoto: React.FC<IInputPhoto> = ({
  name,
  className,
  onChange,
  value,
  cropImage,
  takePhoto,
  aspect,
  cropOptions,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [valuePreview, setValuePreview] = useState('');
  const [fileData, setFileData] = useState<File>({} as File);
  const [cropData, setCropData] = useState<Crop>({} as Crop);
  const [showTakePhoto, setShowTakePhoto] = useState(false);

  useEffect(() => {
    if (value) {
      setValuePreview(value);
    }
  }, [value]);

  useEffect(() => {
    if (cropOptions) {
      setCropData(cropOptions);
    }
  }, [cropOptions]);

  const handleChange = useCallback(
    (e) => {
      if (e.target.files.length > 0) {
        new Compressor(e.target.files[0], {
          quality: 0.75,
          maxWidth: 1920,
          maxHeight: 1920,
          success: (compressedResult) => {
            setFileData(compressedResult as File);
            setValuePreview(URL.createObjectURL(compressedResult));
            if (onChange && !cropImage) {
              onChange(e.target.files[0]);
            }
          },
        });
      } else {
        setValuePreview('');
      }
    },
    [cropImage, onChange]
  );

  const blobToFile = useCallback((theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }, []);

  const toBlob = useCallback((canvas: HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve: any) => {
      canvas.toBlob(resolve);
    });
  }, []);

  const canvasPreview = useCallback(
    (
      image: HTMLImageElement,
      canvas: HTMLCanvasElement,
      crop: PixelCrop,
      scale = 1,
      rotate = 0
    ) => {
      const newCanvas = canvas;
      const ctx = newCanvas.getContext('2d');

      if (!ctx) {
        throw new Error('No 2d context');
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;

      newCanvas.width = Math.floor(crop.width * scaleX * pixelRatio);
      newCanvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = 'high';

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const rotateRads = rotate * TO_RADIANS;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      ctx.translate(-cropX, -cropY);
      ctx.translate(centerX, centerY);
      ctx.rotate(rotateRads);
      ctx.scale(scale, scale);
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      ctx.restore();
    },
    []
  );

  const handleCompleteCrop = useCallback(
    async (e) => {
      if (e.width && e.height && imgRef.current) {
        const canvas = document.createElement('canvas');
        canvasPreview(imgRef.current, canvas, e, 1, 0);
        const blob = await toBlob(canvas);
        let fileName = name;
        if (!fileData.name) {
          const [, type] = blob.type.split('/');
          fileName = `${fileName}.${type}`;
        }
        const file = blobToFile(blob, fileData.name || fileName);
        if (onChange) {
          onChange(file);
        }
      }
    },
    [blobToFile, canvasPreview, fileData.name, name, onChange, toBlob]
  );

  const handleClickRemoveFile = useCallback(() => {
    setValuePreview('');
    setFileData({} as File);
  }, []);

  const handleClose = useCallback(() => {
    setShowTakePhoto(false);
  }, []);

  const handleClickTakePhoto = useCallback(() => {
    setShowTakePhoto(true);
  }, []);

  const dataUrlToFile = useCallback(
    async (dataUrl: string, fileName: string): Promise<File> => {
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();
      return new File([blob], fileName, { type: 'image/jpeg' });
    },
    []
  );

  const handleTakePhoto = useCallback(
    async (imageSrc) => {
      const date = new Date().getTime();
      const file = await dataUrlToFile(imageSrc, `photo-${date}.jpg`);
      setFileData(file);
      setValuePreview(URL.createObjectURL(file));
    },
    [dataUrlToFile]
  );

  const handleClickSavePhoto = useCallback(() => {
    const fileFormData = {
      target: {
        files: [fileData],
      },
    };

    handleChange(fileFormData);
    handleClose();
  }, [fileData, handleChange, handleClose]);

  return (
    <Container>
      {(!valuePreview || !cropImage) && (
        <PhotoContainer src={valuePreview || ''} className={className}>
          {!valuePreview && (
            <>
              <UploadPhoto htmlFor={name}>
                <div className="camera">
                  <MdOutlineFileUpload size={24} color="#d9d9d9" />
                  <span>Clique aqui para inserir uma foto</span>
                </div>
                <Input
                  type="file"
                  id={name}
                  name={name}
                  className="d-none"
                  onChange={handleChange}
                  accept="image/*,capture=camera"
                  capture="camera"
                />
              </UploadPhoto>
              {takePhoto && (
                <>
                  <span className="align-self-center d-block mx-3">OU</span>
                  <TakePhoto type="button" onClick={handleClickTakePhoto}>
                    <div className="camera">
                      <MdCameraAlt size={24} color="#d9d9d9" />
                      <span>Clique aqui para tirar uma foto</span>
                    </div>
                  </TakePhoto>
                </>
              )}
            </>
          )}
        </PhotoContainer>
      )}
      {valuePreview && cropImage && (
        <ReactCrop
          crop={cropData}
          onChange={(c) => setCropData(c)}
          onComplete={handleCompleteCrop}
          aspect={aspect}
          className="position-relative"
        >
          <img
            ref={imgRef}
            src={valuePreview}
            alt="file"
            className="w-100"
            crossOrigin="anonymous"
          />
        </ReactCrop>
      )}
      <button
        type="button"
        onClick={handleClickRemoveFile}
        className="border-0 bg-transparent btn-remove-photo"
      >
        Remover foto
        <BsTrashFill size={20} color="#FC5D4A" className="ms-2" />
      </button>
      <Modal show={showTakePhoto} onHide={handleClose} close size="lg">
        <Modal.Header className="border-0 ps-4 pt-4" closeButton>
          <h4>Tirar foto</h4>
        </Modal.Header>
        <Modal.Body className="mb-4">
          {!valuePreview ? (
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              className="bg-white w-100 h-100 position-relative"
              videoConstraints={{
                width: 1280,
                height: 720,
                facingMode: 'user',
              }}
            >
              {({ getScreenshot }) => (
                <button
                  type="button"
                  className="btn-take-photo mx-auto d-block"
                  onClick={() => handleTakePhoto(getScreenshot())}
                >
                  <MdCameraAlt size={30} color="#ffffff" />
                </button>
              )}
            </Webcam>
          ) : (
            <div className="px-5">
              <img src={valuePreview} alt="Foto" className="d-block w-100" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div
            className={`buttons-group d-flex ${
              valuePreview ? 'justify-content-between' : 'justify-content-end'
            } w-100`}
          >
            {valuePreview && (
              <button
                type="button"
                className="btn btn-dark-4 me-auto w-auto px-3"
                onClick={handleClickRemoveFile}
              >
                Não gostei, tirar outra
              </button>
            )}
            <div>
              <button
                type="button"
                className="btn btn-dark-3 me-2"
                onClick={handleClose}
              >
                Fechar
              </button>
              <button
                type="button"
                onClick={handleClickSavePhoto}
                className="btn btn-primary ms-2"
              >
                Salvar
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default InputPhoto;
