import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';

import {
  Container,
  Countdown,
  QuestionButton,
  Question,
  Buttons,
} from './styles';
import InputRadio, { IOption } from '~/components/InputRadio';

interface IAnswer {
  id: string;
  content: string;
  correct: boolean;
}

interface IQuestionResponse {
  id: string;
  content: string;
  correct?: boolean;
  answer_id?: string;
  answers: IAnswer[];
}

interface IQuestion {
  id: string;
  content: string;
  correct?: boolean;
  answer_id?: string;
  answers: IOption[];
}

interface IFormData {
  [key: string]: string;
}

interface IQuizProps {
  questions: IQuestionResponse[];
  showAnswers?: boolean;
  duration?: string;
  onSubmitAnswers?(questions: IQuestionResponse[], attempt: number): void;
  started?: boolean;
}

const Quiz: React.FC<IQuizProps> = ({
  questions,
  showAnswers,
  duration,
  onSubmitAnswers,
  started,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [questionsResponse, setQuestionsResponse] = useState<
    IQuestionResponse[]
  >([]);
  const [questionsData, setQuestionsData] = useState<IQuestion[]>([]);
  const [questionSelected, setQuestionSelected] = useState(1);
  const [error, setError] = useState('');
  const [attempt, setAttempt] = useState(1);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (started) {
      if (countdown > 0) {
        setTimeout(() => {
          setCountdown((state) => state - 1);
        }, 1000);
      } else if (formRef.current) {
        formRef.current.submitForm();
      }
    }
  }, [countdown, started]);

  useEffect(() => {
    const data: IQuestion[] = questions.map((question) => {
      const answers: IOption[] = question.answers.map((answer) => ({
        id: answer.id,
        value: answer.content,
      }));

      return {
        id: question.id,
        content: question.content,
        answers,
        answer_id: question.answer_id,
        correct: question.correct,
      };
    });

    setQuestionsResponse(questions);
    setQuestionsData(data);
  }, [questions]);

  useEffect(() => {
    if (duration) {
      const [hours, minutes, seconds] = duration.split(':');
      let totalSeconds = parseInt(hours, 10) * 3600;
      totalSeconds += parseInt(minutes, 10) * 60;
      totalSeconds += parseInt(seconds, 10);
      setCountdown(totalSeconds);
    }
  }, [duration]);

  const handleClickQuestion = useCallback((value) => {
    setQuestionSelected(value);
  }, []);

  const handleClickBack = useCallback(() => {
    setQuestionSelected((state) => state - 1);
  }, []);

  const handleClickNext = useCallback(() => {
    setQuestionSelected((state) => state + 1);
  }, []);

  const handleSubmit = useCallback(
    (data: IFormData) => {
      try {
        setError('');
        const answers = Object.values(data).filter((answer) => answer !== '');
        if (!duration || countdown > 0) {
          if (answers.length !== questionsResponse.length) {
            throw new Error('É preciso responder todas as perguntas');
          }
        }

        const newQuestions = questionsData.slice();

        answers.forEach((answer, index) => {
          newQuestions[index].correct = questionsResponse[index].answers.find(
            (answerData) => answerData.id === answer
          )?.correct;
          newQuestions[index].answer_id = answer;
        });

        if (onSubmitAnswers) {
          onSubmitAnswers(
            newQuestions as unknown as IQuestionResponse[],
            attempt
          );
        }

        setAttempt(attempt + 1);
        setQuestionsData(newQuestions);
      } catch (err: any) {
        if (err.message === 'É preciso responder todas as perguntas') {
          setError(err.message);
        }
      }
    },
    [
      attempt,
      countdown,
      duration,
      onSubmitAnswers,
      questionsData,
      questionsResponse,
    ]
  );

  const hours = useMemo(() => {
    return (Math.floor(countdown / (60 * 60)) % 24).toString().padStart(2, '0');
  }, [countdown]);

  const minutes = useMemo(() => {
    return (Math.floor(countdown / 60) % 60).toString().padStart(2, '0');
  }, [countdown]);

  const seconds = useMemo(() => {
    return (countdown % 60).toString().padStart(2, '0');
  }, [countdown]);

  return (
    <Container ref={formRef} onSubmit={handleSubmit}>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-lg-center">
        <div className="questions-group overflow-auto">
          <div className="d-flex">
            {questionsData.map((question, index) => (
              <QuestionButton
                type="button"
                active={questionSelected === index + 1}
                onClick={() => handleClickQuestion(index + 1)}
                key={index.toString()}
              >
                {(index + 1).toString().padStart(2, '0')}
                {showAnswers && typeof question.correct === 'boolean' && (
                  <>
                    {question.correct ? (
                      <RiCheckboxCircleFill size={18} color="#009D65" />
                    ) : (
                      <RiCloseCircleFill size={18} color="#FF333D" />
                    )}
                  </>
                )}
              </QuestionButton>
            ))}
          </div>
        </div>
        {showAnswers &&
          questionsData.length > 0 &&
          typeof questionsData[questionSelected - 1].correct === 'boolean' && (
            <>
              {questionsData[questionSelected - 1].correct ? (
                <span className="text-white bg-correct mt-4 mt-md-0 align-self-end align-self-md-start">
                  Correto
                </span>
              ) : (
                <span className="text-white bg-error mt-4 mt-md-0 align-self-end align-self-md-start">
                  {questionsData[questionSelected - 1].answer_id
                    ? 'Errado'
                    : 'Não respondida'}
                </span>
              )}
            </>
          )}
      </div>
      <div className="my-4 my-lg-5">
        {questionsData.map((question, index) => (
          <Question active={questionSelected === index + 1}>
            <p className="mb-4 fw-medium pb-4">{question.content}</p>
            <InputRadio
              name={`question${index + 1}`}
              options={question.answers}
              selected={question.answers.find(
                (answer) => answer.id === question.answer_id
              )}
              className="flex-column"
            />
          </Question>
        ))}
      </div>
      <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-end">
        <div className="d-flex flex-column mb-3 mb-lg-0">
          <span className="small text-error error mx-auto mx-lg-0 mb-3 d-block">
            {error}
          </span>
          {duration && (
            <Countdown className="btn rounded-pill">
              {hours}:{minutes}:{seconds}
            </Countdown>
          )}
        </div>
        <Buttons className="d-flex justify-content-end w-100 w-lg-auto">
          {questionSelected > 1 && (
            <button
              type="button"
              onClick={handleClickBack}
              className="btn btn-dark-3"
            >
              Voltar
            </button>
          )}
          {questionSelected < questionsData.length && (
            <button
              type="button"
              onClick={handleClickNext}
              className="btn btn-dark-4"
            >
              Próximo
            </button>
          )}
          {questionSelected === questionsData.length && (
            <button
              type={duration && countdown <= 0 ? 'button' : 'submit'}
              className="btn btn-dark-4"
              disabled={!!(duration && countdown <= 0)}
            >
              Enviar
            </button>
          )}
        </Buttons>
      </div>
    </Container>
  );
};

export default Quiz;
