import React from 'react';
import { Switch, Route as RouteComponent } from 'react-router-dom';

import Route from './Route';
import ProductRoutes from './product.routes';

import AutoLogin from '~/pages/AutoLogin';
import Login from '~/pages/Login';
import SignUp from '~/pages/SignUp';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';

import Products from '~/pages/Products';
import Ways from '~/pages/Ways';
import Dashboard from '~/pages/Dashboard';
import Categories from '~/pages/Categories';
import Category from '~/pages/Categories/Category';
import Favorites from '~/pages/Favorites';
import Certificates from '~/pages/Certificates';
import Downloads from '~/pages/Downloads';
import Course from '~/pages/Course';
import Test from '~/pages/Course/Test';
import Lesson from '~/pages/Course/Lesson';
import Profile from '~/pages/Profile';
import Support from '~/pages/Support';

import MyCourses from '~/pages/MyCourses';

const routes: React.FC = () => {
  return (
    <Switch>
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/auto-login/:hash`}
        component={AutoLogin}
      />
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/cadastre-se`}
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resetar-senha/:token`}
        exact
        component={ResetPassword}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/produtos`}
        isPrivate
        component={Products}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/caminhos`}
        isPrivate
        component={Ways}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        isPrivate
        component={Dashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias`}
        isPrivate
        exact
        component={Categories}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/:slug`}
        isPrivate
        component={Category}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/favoritos`}
        isPrivate
        component={Favorites}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/certificados`}
        isPrivate
        component={Certificates}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/downloads`}
        isPrivate
        component={Downloads}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slug`}
        isPrivate
        exact
        component={Course}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slug/prova`}
        isPrivate
        component={Test}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cursos/:slugCourse/:slugModule/:slug`}
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/perfil`}
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/suporte`}
        isPrivate
        component={Support}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/meu-perfil`}
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/meus-cursos`}
        isPrivate
        exact
        component={MyCourses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/meus-cursos/:slug`}
        isPrivate
        exact
        component={Course}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/meus-cursos/:slug/prova`}
        isPrivate
        component={Test}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/meus-cursos/:slugCourse/:slugModule/:slug`}
        isPrivate
        component={Lesson}
      />

      <RouteComponent
        path={`${process.env.PUBLIC_URL}/:productSlug`}
        component={ProductRoutes}
      />
    </Switch>
  );
};

export default routes;
