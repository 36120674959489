import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Container, Banner } from './styles';
import Resource from '~/components/Resource';

import api from '~/services/api';
import Skeleton from '~/components/Skeleton';

interface IResource {
  id: string;
  name: string;
  resource_url: string;
  isBanner?: string;
  link?: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IResourceData {
  current_page: number;
  last_page: number;
  data: IResource[];
}

const Downloads: React.FC = () => {
  const [resources, setResources] = useState<IResource[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const loadResources = useCallback(async (pageData: number) => {
    const response = await api.get<IResourceData>(`resources`, {
      params: {
        page: pageData,
      },
    });

    setResources((state) => [...state, ...response.data.data]);
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadResources(1).finally(() => {
      setLoading(false);
    });
  }, [loadResources]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadResources(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [lastPage, loadResources, page]);

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row align-items-center pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4">
            <h1 className="fw-semibold text-white ms-4">Downloads</h1>
          </div>
          {!loading ? (
            <>
              {resources.length > 0 ? (
                <>
                  {resources.map((resource) => (
                    <Fragment key={resource.id}>
                      {resource.isBanner ? (
                        <div className="col-12 mb-5 pb-4 order-0">
                          <Banner
                            src={resource.thumbnail.thumbnail_url}
                            className="p-4 p-lg-5"
                          >
                            <div className="d-flex flex-column py-4">
                              <h2 className="h5 h4-md h3-lg fw-normal w-md-75 w-lg-50 mb-5">
                                {resource.name}
                              </h2>
                              <a
                                href={resource.link}
                                className="btn btn-primary px-5 py-2 fw-bold align-self-end mb-n4"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Conferir
                              </a>
                            </div>
                          </Banner>
                        </div>
                      ) : (
                        <div className="col-md-6 col-xl-4 mb-3 order-1">
                          <Resource
                            to={resource.link || resource.resource_url}
                            thumb={resource.thumbnail.thumbnail_url}
                            title={resource.name}
                          />
                        </div>
                      )}
                    </Fragment>
                  ))}
                </>
              ) : (
                <div className="col-12 text-center py-5">
                  <h2 className="h3 text-gray">
                    Ainda não existe nenhum download disponível
                  </h2>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
              <div className="col-md-6 col-xl-4 mb-3">
                <Skeleton width="100%" height={286} radius={10} />
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Downloads;
