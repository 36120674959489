import styled from 'styled-components';
import List from 'react-chatview';

interface ISearchCourses {
  show: boolean;
}

export const Container = styled(List)`
  height: calc(100vh - 61px);
`;

export const Categories = styled.div`
  .categories {
    width: max-content;

    a:not(.more) {
      border: 1px solid #3a3a3a;
      box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 200px;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .categories {
      width: 100%;

      > div {
        width: 25%;
        a:not(.more) {
          width: unset;
        }
      }
    }
  }
`;

export const Courses = styled.div`
  .slick-arrow {
    margin-top: 3rem !important;
  }

  .slick-list {
    padding-top: 3rem !important;
  }
`;

export const SearchCourses = styled.div<ISearchCourses>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  position: fixed;
  bottom: ${(props) => (props.show ? 0 : '-1000px')};
  left: 0;
  border: 1px solid #3a3a3a;
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  z-index: 2;

  .custom-height {
    height: calc(100vh - 3rem);
  }

  .animation {
    pointer-events: none;
  }
`;

export const AutoComplete = styled.div``;
