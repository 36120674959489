import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface ContainerProps {
  height?: string;
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 10px;
  border: 1px solid #454545;
  background-color: #3a3a3a;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: ${({ height }) => height || '44px'};

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    position: relative;
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #fff;
    transition-duration: 0.2s;

    ::placeholder {
      color: #bbb;
    }

    :focus {
      border: none;
      outline: 0;
    }

    :disabled {
      opacity: 0.7;
    }
  }

  .options {
    border-radius: 0 0 10px 10px;
    background-color: #3a3a3a;
    border: 1px solid #454545;
    width: calc(100% + 2px);
    left: -1px;
    top: 43px;
    max-height: 200px;
    overflow: auto;
    z-index: 100;

    button {
      border: none;
      border-bottom: 1px solid #454545;
      background: transparent;
      transition-duration: 0.3s;
      color: #fff;
      min-height: 52px;

      :hover {
        background-color: ${darken(0.02, '#3a3a3a')};
      }
    }
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #ff333d;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #606060;
      border-color: #606060;
      border-radius: 10px 10px 0 0 !important;
    `}
`;
