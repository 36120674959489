import React, { useCallback, useEffect, useState } from 'react';

import { Container, Checkbox } from './styles';

export interface IOption {
  id: string | number;
  value: string;
}

interface IInputToggle {
  name: string;
  options: IOption[];
  className?: string;
  onChange?(checkedValues: string[]): void;
  checkedValues?: string[];
}

const InputToggle: React.FC<IInputToggle> = ({
  name,
  options,
  className,
  checkedValues,
  onChange,
}) => {
  const [checkedValuesData, setCheckedValuesData] = useState<string[]>([]);

  useEffect(() => {
    if (checkedValues) {
      setCheckedValuesData(checkedValues);
    }
  }, [checkedValues]);

  const handleChange = useCallback(
    (value) => {
      let newCheckedValuesData = checkedValuesData.slice();
      const checkValue = newCheckedValuesData.find(
        (checkedValue) => checkedValue === value.id
      );

      if (checkValue) {
        newCheckedValuesData = newCheckedValuesData.filter(
          (checkedValue) => checkedValue !== value.id
        );
      } else {
        newCheckedValuesData.push(value.id);
      }

      setCheckedValuesData(newCheckedValuesData);

      if (onChange) {
        onChange(newCheckedValuesData);
      }
    },
    [checkedValuesData, onChange]
  );

  return (
    <>
      <Container className={className}>
        {options.map((option, index) => (
          <Checkbox
            htmlFor={`${name}_${index}`}
            checked={
              !!checkedValuesData.find((checkValue) => checkValue === option.id)
            }
          >
            <div />
            <input
              type="checkbox"
              id={`${name}_${index}`}
              name={`${name}[]`}
              value={option.id}
              onChange={() => handleChange(option)}
            />
            <span>{option.value}</span>
          </Checkbox>
        ))}
      </Container>
    </>
  );
};

export default InputToggle;
