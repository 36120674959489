import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ILessonsGroup {
  expanded: boolean;
}

interface ITabsGroup {
  opened: boolean;
}

interface ITab {
  active: boolean;
}

interface IAvatar {
  src: string;
}

interface IAnswerForm {
  show: boolean;
}

interface IToggle {
  checked: boolean;
}

interface ILessonContent {
  expanded: boolean;
}

export const Container = styled.div``;

export const LessonContent = styled.div<ILessonContent>`
  transition-duration: 0.3s;

  .player {
    transition-duration: 0.3s;
    height: ${(props) => (props.expanded ? '600px' : '400px')};
    border-radius: 20px;
    overflow: hidden;

    > div {
      height: 100% !important;
    }
  }
`;

export const Video = styled.div`
  .buttons {
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      small {
        font-size: 10px;
        color: #bbb;
      }

      + button {
        margin-left: 10px;
      }
    }
  }
`;

export const Toggle = styled.div<IToggle>`
  width: 50px;
  height: 25px;
  border-radius: 25px;
  margin-right: 8px;
  transition-duration: 0.3s;
  background: ${(props) => (props.checked ? '#E50914' : '#3A3A3A')};
  border: ${(props) =>
    props.checked ? '2px solid #E50914' : '2px solid #3A3A3A;'};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  :after {
    content: '';
    transition-duration: 0.3s;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    left: ${(props) => (props.checked ? 98 : 4)}%;
    transform: translateX(${(props) => (props.checked ? -100 : 0)}%);
  }
`;

export const LessonsGroup = styled.div<ILessonsGroup>`
  transition-duration: 0.3s;
  overflow-x: hidden;

  @media screen and (min-width: 1200px) {
    width: ${(props) => (props.expanded ? '0' : '33.33333333%')};
    padding-right: ${(props) => (props.expanded ? '0' : '1rem')}!important;
    padding-left: ${(props) => (props.expanded ? '0' : '1rem')}!important;

    > div {
      width: 392px;
    }
  }

  @media screen and (min-width: 1400px) {
    padding-right: ${(props) => (props.expanded ? '0' : '1.5rem')}!important;
    padding-left: ${(props) => (props.expanded ? '0' : '1.5rem')}!important;
  }
`;

export const Tabs = styled.div`
  border: 1px solid #333333;
  border-radius: 15px;
`;

export const TabsButton = styled.button`
  border: 1px solid #333333;
  border-radius: 15px;
`;

export const TabsGroup = styled.div<ITabsGroup>`
  transition-duration: 0.3s;
  height: ${(props) => (props.opened ? '150px' : '0')};
  overflow: hidden;
  padding-top: ${(props) => (props.opened ? '20px' : '0')};
  margin-top: ${(props) => (props.opened ? '20px' : '0')};
  border-top: ${(props) => (props.opened ? '1px solid #333333' : 'none')};

  @media screen and (min-width: 768px) {
    height: unset;
    overflow: unset;
  }
`;

export const Tab = styled.button<ITab>`
  position: relative;
  transition-duration: 0.3s;
  color: ${(props) => (props.active ? '#ffffff' : '#bbbbbb')};

  :after {
    content: '';
    transition-duration: 0.3s;
    background: ${(props) => (props.active ? '#FF0000' : '#FF000000')};
    width: 80%;
    height: 1px;
    border-radius: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  :hover {
    color: #ffffff;

    :after {
      background: #ff0000;
    }
  }

  + button {
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    + button {
      margin-top: 0;
      margin-left: 40px;
    }
  }
`;

export const About = styled.div`
  p {
    line-height: 33.5px;
  }
`;

export const Downloads = styled.div``;

export const Download = styled.a`
  transition-duration: 0.3s;
  background-color: #161616;
  border-radius: 20px;
  color: #ffffff;

  :hover {
    color: #ffffff;
    background-color: ${darken(0.03, '#161616')};
  }

  + a {
    margin-top: 15px;
  }
`;

export const Comments = styled.div`
  form {
    .input {
      width: calc(100% - 70px);
      background: #303030;
      border-radius: 15px;
    }
  }

  hr {
    background-color: #303030;
    height: 2px;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.4);
`;

export const Comment = styled.div`
  .content {
    width: calc(100% - 65px);
    span {
      color: #646464;
    }

    p {
      font-size: 20px;
      line-height: 34px;
    }
  }

  + div {
    margin-top: 60px;
  }
`;

export const AnswerForm = styled.div<IAnswerForm>`
  transition-duration: 0.3s;
  overflow: hidden;
  height: ${(props) => (props.show ? '52px' : '0px')};
  margin-top: ${(props) => (props.show ? '52px' : '0px')};

  form {
    .input {
      width: calc(100% - 70px);
      background: #303030;
      border-radius: 15px;
    }
  }
`;

export const Answer = styled.div`
  margin-top: 40px;

  .content {
    width: calc(100% - 65px);

    span {
      color: #646464;
    }

    p {
      font-size: 20px;
      line-height: 34px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #242526;
    border-radius: 10px;
    padding: 1rem;

    h4 {
      font-weight: 600;
      color: #ffffff;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .modal-content {
      padding: 1.5rem;
    }
  }
`;
