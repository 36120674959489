/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoIosArrowBack, IoMdLock } from 'react-icons/io';
import { FaChevronDown } from 'react-icons/fa';

import {
  Container,
  Banner,
  Courses,
  CoursesSlider,
  Line,
  ButtonDown,
} from './styles';
import Course from '~/components/Course';

import api from '~/services/api';
import { useProduct } from '~/hooks/Product';

interface IParams {
  slug: string;
}

interface ICategory {
  id: string;
  name: string;
  description: string;
  icon: string;
  slug: string;
  wallpaper: {
    wallpaper_url: string;
  };
}
interface ICourse {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
  lock?: boolean;
}

interface ICourseData {
  current_page: number;
  last_page: number;
  data: ICourse[];
  total: number;
}

interface ISubCategory {
  id: string;
  name: string;
  coursesData: ICourseData;
}

interface ISubCategoryData {
  last_page: number;
  data: ISubCategory[];
  total: number;
}

const Category: React.FC = () => {
  const { product } = useProduct();
  const params = useParams<IParams>();
  const [totalSubCategories, setTotalSubCategories] = useState(0);
  const [category, setCategory] = useState({} as ICategory);
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadCourses = useCallback(
    async (pageData: number, category_id: string) => {
      const response = await api.get<ICourseData>(
        `courses/categories/${category_id}`,
        {
          params: {
            page: pageData,
            plan: product && product.plan?.id,
          },
        }
      );

      return response;
    },
    [product]
  );

  const loadSubCategories = useCallback(
    async (pageData: number, category_id: string) => {
      const response = await api.get<ISubCategoryData>(
        `categories/subcategories/${category_id}`,
        {
          params: {
            page: pageData,
          },
        }
      );

      const data: ISubCategory[] = [];

      for (const subCategory of response.data.data) {
        const responseCourse = await loadCourses(1, subCategory.id);

        const newSubCategory = { ...subCategory };
        newSubCategory.coursesData = responseCourse.data;
        data.push(newSubCategory);
      }

      setSubCategories((state) => [...state, ...data]);
      setLastPage(response.data.last_page);
      setTotalSubCategories(response.data.total);
    },
    [loadCourses]
  );

  useEffect(() => {
    api.get<ICategory>(`categories/${params.slug}`).then(async (response) => {
      api.post(`users-categories/${response.data.id}`);
      await loadSubCategories(1, response.data.id);
      console.log(response.data.id);
      setCategory(response.data);
    });
  }, [loadSubCategories, params.slug]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadSubCategories(page + 1, category.id);
        setPage(page + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [category.id, lastPage, loadSubCategories, page]);

  const handleClickLoadMoreCourses = useCallback(
    async (subCategoryIndex: number) => {
      try {
        const newSubCategories = subCategories.slice();
        const { id, coursesData } = newSubCategories[subCategoryIndex];
        if (coursesData.current_page < coursesData.last_page) {
          const response = await loadCourses(coursesData.current_page + 1, id);
          newSubCategories[subCategoryIndex].coursesData.current_page =
            response.data.current_page;
          newSubCategories[subCategoryIndex].coursesData.data.push(
            ...response.data.data
          );
          setSubCategories(newSubCategories);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [loadCourses, subCategories]
  );

  const handleAfterChange = useCallback(
    async (subCategoryIndex: number, courseIndex: number) => {
      const newSubCategories = subCategories.slice();
      const { id, coursesData } = newSubCategories[subCategoryIndex];
      if (coursesData.current_page < coursesData.last_page) {
        if (coursesData.data.length === courseIndex + 2) {
          const response = await loadCourses(coursesData.current_page + 1, id);
          newSubCategories[subCategoryIndex].coursesData.current_page =
            response.data.current_page;
          newSubCategories[subCategoryIndex].coursesData.data.push(
            ...response.data.data
          );
          setSubCategories(newSubCategories);
        }
      }
    },
    [loadCourses, subCategories]
  );

  return Object.keys(category).length > 0 ? (
    <Container
      scrollLoadThreshold={200}
      onInfiniteLoad={handleLoad}
      className="pb-5"
    >
      <Banner src={category.wallpaper.wallpaper_url} />
      <div className="container px-4 px-lg-3 pt-5">
        <div className="row align-items-center pt-5 pb-5">
          <div className="col-lg-8 col-xl-6 pt-5 pt-lg-0">
            <img src={category.icon} alt={category.name} />
            <div className="d-flex my-4">
              <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                <IoIosArrowBack size={45} color="#fff" />
              </Link>
              <h1 className="fw-semibold text-white ms-4">{category.name}</h1>
            </div>
            <p className="description">{category.description}</p>
          </div>
        </div>
      </div>
      <div className="container-xl px-0 px-lg-3 pb-5">
        {subCategories.map((subCategory, index) => (
          <Courses key={subCategory.id} className="mt-4 mt-lg-5">
            <h2 className="text-white h5 ms-3 mb-5">{subCategory.name}</h2>
            <CoursesSlider
              onAfterChange={(courseIndex) =>
                handleAfterChange(index, courseIndex)
              }
              className="row"
            >
              {subCategory.coursesData.data.map((course) => (
                <div key={course.id} className="col-md-6 col-xl-4 mb-3">
                  <Course
                    type={course.lock ? 'upgrade' : 'link'}
                    to={`${process.env.PUBLIC_URL}/cursos/${course.slug}`}
                    thumb={course.thumbnail}
                    title={course.title}
                    icon={course.lock ? IoMdLock : undefined}
                    message="Curso bloqueado, faça o upgrade de seu plano"
                  />
                </div>
              ))}
            </CoursesSlider>
            <div className="d-none d-xl-block">
              {(index + 1 < totalSubCategories ||
                subCategory.coursesData.data.length <
                  subCategory.coursesData.total) && (
                <Line>
                  {subCategory.coursesData.data.length <
                    subCategory.coursesData.total && (
                    <ButtonDown
                      type="button"
                      onClick={() => handleClickLoadMoreCourses(index)}
                    >
                      <div>
                        <FaChevronDown />
                      </div>
                    </ButtonDown>
                  )}
                </Line>
              )}
            </div>
          </Courses>
        ))}
      </div>
    </Container>
  ) : (
    <></>
  );
};

export default Category;
