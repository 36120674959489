import styled from 'styled-components';

interface ICheckbox {
  checked?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Checkbox = styled.label<ICheckbox>`
  display: flex;
  align-items: center;
  margin: 10px 20px 10px 0;
  cursor: pointer;

  span {
    color: #828282;
    font-weight: 500;
    width: calc(100% - 50px);
  }

  > div {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    margin-top: 8px;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) => (props.checked ? '#E50914' : '#3A3A3A')};
    border: ${(props) =>
      props.checked ? '2px solid #E50914' : '2px solid #3A3A3A;'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    :after {
      content: '';
      transition-duration: 0.3s;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ffffff;
      left: ${(props) => (props.checked ? 98 : 4)}%;
      transform: translateX(${(props) => (props.checked ? -100 : 0)}%);
    }
  }

  input {
    display: none;
  }
`;
