import React from 'react';

import { AuthProvider } from './Auth';
import { ProductProvider } from './Product';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ProductProvider>{children}</ProductProvider>
  </AuthProvider>
);

export default AppProvider;
