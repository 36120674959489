import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IoMdRadioButtonOff,
  IoMdCheckmarkCircle,
  IoMdPlayCircle,
} from 'react-icons/io';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

import { Container, Content } from './styles';

interface ILesson {
  id: string;
  title: string;
  description: string;
  duration: string;
  slug: string;
  watching?: boolean;
  video: {
    video_url: string;
    link: string;
  };
  userLesson?: {
    id: string;
    views: number;
    time: string;
    completed: boolean;
  };
}

interface IModule {
  id: string;
  title: string;
  slug: string;
  lessons: ILesson[];
}

interface ILessonsProps {
  slugCourse: string;
  module: IModule;
  onClickCompleteLesson?(lesson: ILesson): void;
  onClickPrev(): void;
  disabledPrev?: boolean;
  onClickNext(): void;
  disabledNext?: boolean;
}

interface IParams {
  productSlug?: string;
}

const Lessons: React.FC<ILessonsProps> = ({
  slugCourse,
  module,
  onClickCompleteLesson,
  onClickPrev,
  disabledPrev,
  onClickNext,
  disabledNext,
}) => {
  const params = useParams<IParams>();
  const location = useLocation();
  const [showMsg, setShowMsg] = useState(false);

  const path = useMemo(() => {
    return location.pathname.includes('meus-cursos') ? 'meus-cursos' : 'cursos';
  }, [location.pathname]);

  const productSlug = useMemo(() => {
    if (params.productSlug) {
      return `/${params.productSlug}`;
    }

    return '';
  }, [params.productSlug]);

  useEffect(() => {
    if (
      Object.keys(module).length > 0 &&
      module.lessons.find(
        (lesson) =>
          !lesson.userLesson ||
          (lesson.userLesson && !lesson.userLesson.completed)
      )
    ) {
      setTimeout(() => {
        setShowMsg(true);
        setTimeout(() => {
          setShowMsg(false);
        }, 5000);
      }, 2000);
    }
  }, [module, module.lessons]);

  const handleClick = useCallback(
    (lesson) => {
      if (onClickCompleteLesson) {
        onClickCompleteLesson(lesson);
      }
    },
    [onClickCompleteLesson]
  );

  const handleClickPrev = useCallback(() => {
    if (onClickPrev) {
      onClickPrev();
    }
  }, [onClickPrev]);

  const handleClickNext = useCallback(() => {
    if (onClickNext) {
      onClickNext();
    }
  }, [onClickNext]);

  return Object.keys(module).length > 0 ? (
    <Container key={module.id}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <button
          type="button"
          className="bg-transparent border-0"
          disabled={disabledPrev}
          onClick={handleClickPrev}
        >
          <MdNavigateBefore
            size={30}
            color={!disabledPrev ? '#fff' : '#ffffff55'}
          />
        </button>
        <h2 className="mb-0">{module.title}</h2>
        <button
          type="button"
          className="bg-transparent border-0"
          disabled={disabledNext}
          onClick={handleClickNext}
        >
          <MdNavigateNext
            size={30}
            color={!disabledNext ? '#fff' : '#ffffff55'}
          />
        </button>
      </div>
      <Content>
        {module.lessons.map((lesson, index) => (
          <div className="position-relative">
            <NavLink
              key={lesson.id}
              to={`${process.env.PUBLIC_URL}${productSlug}/${path}/${slugCourse}/${module.slug}/${lesson.slug}`}
              className="d-flex justify-content-between align-items-center p-3"
              activeClassName="active"
              exact
            >
              <span className="d-flex justify-content-between align-items-center pe-5">
                {lesson.watching && (
                  <IoMdPlayCircle size={24} color="#fff" className="me-3" />
                )}
                {lesson.title}
              </span>
              <span className="d-flex justify-content-between align-items-center">
                {lesson.duration}
              </span>
            </NavLink>
            <button
              type="button"
              className="position-absolute border-0 bg-transparent"
              onClick={() => handleClick(lesson)}
            >
              <IoMdCheckmarkCircle
                size={22}
                color="#009D65"
                className={
                  lesson.userLesson && lesson.userLesson.completed
                    ? 'active'
                    : ''
                }
              />
              <IoMdRadioButtonOff
                size={22}
                color="#bbbbbb55"
                className={
                  !lesson.userLesson ||
                  (lesson.userLesson && !lesson.userLesson.completed)
                    ? 'active'
                    : ''
                }
              />
              {index === 0 && (
                <span
                  className={`small py-2 text-center rounded-pill ${
                    showMsg ? 'show' : 'hide'
                  }`}
                >
                  Clique para marcar como concluído
                </span>
              )}
            </button>
          </div>
        ))}
      </Content>
    </Container>
  ) : (
    <></>
  );
};

export default Lessons;
