import React from 'react';

import { Container } from './styles';

interface ISkeleton {
  width: number | string;
  height: number | string;
  radius?: number;
}

const Skeleton: React.FC<ISkeleton> = ({ width, height, radius }) => {
  return <Container width={width} height={height} radius={radius} />;
};

export default Skeleton;
