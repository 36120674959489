import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import api from '~/services/api';

import { Container } from './styles';
import Course from '~/components/Course';

interface ICourse {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
}

const MyCourses: React.FC = () => {
  const [courses, setCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    api.get<ICourse[]>(`courses/my`).then((response) => {
      setCourses(response.data);
    });
  }, []);

  return (
    <Container>
      <div className="container pt-5">
        <div className="row align-items-center pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4">
            <div className="d-flex align-items-center">
              <Link to={`${process.env.PUBLIC_URL}/produtos`}>
                <FiArrowLeft size={24} color="#fff" />
              </Link>
              <h1 className="fw-semibold text-white ms-4 mb-0">Meus cursos</h1>
            </div>
          </div>
          {courses.map((course) => (
            <div key={course.id} className="col-md-6 col-xl-4 mb-3 order-1">
              <Course
                key={course.id}
                to={`${process.env.PUBLIC_URL}/meus-cursos/${course.slug}`}
                thumb={course.thumbnail}
                title={course.title}
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default MyCourses;
