import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { IoIosArrowBack } from 'react-icons/io';
import Lottie from 'react-lottie';

import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { initializeSocketEvents } from '~/services/socket';

import {
  Container,
  Background,
  Content,
  Step,
  AwaitingPayment,
} from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import Plans, { IPlan } from '~/components/Plans';

import logo from '~/assets/logos/logo-white.svg';
import loading from '~/assets/animations/loading.json';

interface IFormData {
  name: string;
  email: string;
  password: string;
  foreign: string;
  birthdate: string;
  document: string;
  occupation: string;
  city: string;
  state: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const [nameError, setNameError] = useState(false);
  const [step, setStep] = useState(1);
  const [stepTitle, setStepTitle] = useState('Bem-vindo!');
  const [stepMessage, setStepMessage] = useState(
    'Para iniciar, complete os campos desejados'
  );

  const states = useMemo<IOption[]>(
    () => [
      { id: 0, value: 'Selecione', selected: true, notSelectable: true },
      { id: 'AC', value: 'Acre', selected: false },
      { id: 'AC', value: 'Acre', selected: false },
      { id: 'AL', value: 'Alagoas', selected: false },
      { id: 'AP', value: 'Amapá', selected: false },
      { id: 'AM', value: 'Amazonas', selected: false },
      { id: 'BA', value: 'Bahia', selected: false },
      { id: 'CE', value: 'Ceará', selected: false },
      { id: 'DF', value: 'Distrito Federal', selected: false },
      { id: 'ES', value: 'Espírito Santo', selected: false },
      { id: 'GO', value: 'Goiás', selected: false },
      { id: 'MA', value: 'Maranhão', selected: false },
      { id: 'MT', value: 'Mato Grosso', selected: false },
      { id: 'MS', value: 'Mato Grosso do Sul', selected: false },
      { id: 'MG', value: 'Minas Gerais', selected: false },
      { id: 'PA', value: 'Pará', selected: false },
      { id: 'PB', value: 'Paraíba', selected: false },
      { id: 'PR', value: 'Paraná', selected: false },
      { id: 'PE', value: 'Pernambuco', selected: false },
      { id: 'PI', value: 'Piauí', selected: false },
      { id: 'RJ', value: 'Rio de Janeiro', selected: false },
      { id: 'RN', value: 'Rio Grande do Norte', selected: false },
      { id: 'RS', value: 'Rio Grande do Sul', selected: false },
      { id: 'RO', value: 'Rondônia', selected: false },
      { id: 'RR', value: 'Roraima', selected: false },
      { id: 'SC', value: 'Santa Catarina', selected: false },
      { id: 'SP', value: 'São Paulo', selected: false },
      { id: 'SE', value: 'Sergipe', selected: false },
      { id: 'TO', value: 'Tocantins', selected: false },
    ],
    []
  );

  const handleChangeName = useCallback((e) => {
    if (formRef.current) {
      formRef.current.setErrors({});
    }
    const nameParts = e.target.value.trim().split(' ');
    if (nameParts.length === 1) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, []);

  const handleClickChangeStep = useCallback(
    (newStep: number) => {
      if (!nameError) {
        switch (newStep) {
          case 2:
            setStepTitle('Queremos te conhecer!');
            setStepMessage(
              'Para sabermos melhor o seu perfil, insira os campos'
            );
            break;
          case 3:
            setStepTitle('Localização');
            setStepMessage('Em qual estado reside?');
            break;

          default:
            setStepTitle('Bem-vindo!');
            setStepMessage('Para iniciar, complete os campos desejados');
            break;
        }
        setStep(newStep);
      } else if (formRef.current) {
        formRef.current.setErrors({
          name: 'Preencha seu nome completo',
        });
      }
    },
    [nameError]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
          birthdate: Yup.string().required(
            'A data de nascimento é obrigatória'
          ),
          document: Yup.string().required('O CPF é obrigatório'),
          occupation: Yup.string().required('A profissão é obrigatória'),
          city: Yup.string().required('A cidade é obrigatória'),
          state: Yup.string().required('O estado é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const [day, month, year] = data.birthdate.split('/');
        const formData = {
          name: data.name,
          email: data.email.toLowerCase(),
          password: data.password,
          foreign: !!data.foreign,
          birthdate: new Date(
            parseInt(year, 10),
            parseInt(month, 10) - 1,
            parseInt(day, 10)
          ),
          document: data.document.replaceAll('.', '').replace('-', ''),
          occupation: data.occupation,
        };

        const response = await api.post('users', formData);

        const formDataAddress = {
          user_id: response.data.id,
          city: data.city,
          state: data.state,
        };

        await api.post('addresses', formDataAddress);

        signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          if (
            Object.keys(errors).find(
              (key) => key === 'name' || key === 'email' || key === 'password'
            )
          ) {
            setStep(1);
            setStepTitle('Bem-vindo!');
            setStepMessage('Para iniciar, complete os campos desejados');
          } else if (
            Object.keys(errors).find(
              (key) =>
                key === 'birthdate' ||
                key === 'document' ||
                key === 'occupation'
            )
          ) {
            setStep(2);
            setStepTitle('Queremos te conhecer!');
            setStepMessage(
              'Para sabermos melhor o seu perfil, insira os campos'
            );
          } else if (Object.keys(errors).find((key) => key === 'state')) {
            setStep(3);
            setStepTitle('Localização');
            setStepMessage('Em qual estado reside?');
          }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [signIn]
  );

  return (
    <Container className="container container-lg-fluid">
      <div className="row">
        <Content className="bg-dark-1 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div className="mb-5">
              {step > 1 && (
                <button
                  type="button"
                  onClick={() => handleClickChangeStep(step - 1)}
                  className="bg-transparent border-0 mb-3"
                >
                  <IoIosArrowBack size={24} color="#fff" />
                </button>
              )}
              <h1 className="h2 fw-semibold mb-0">{stepTitle}</h1>
              <p className="text-gray">{stepMessage}</p>
            </div>
            <Step active={step === 1}>
              <div className="mb-4">
                <label htmlFor="email" className="mb-2 fw-medium">
                  Nome Completo
                </label>
                <Input
                  placeholder="Ex: João Silva"
                  name="name"
                  onChange={handleChangeName}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="mb-2 fw-medium">
                  E-mail
                </label>
                <Input
                  type="email"
                  placeholder="email@example.com"
                  name="email"
                />
              </div>
              <div className="mb-5">
                <label htmlFor="email" className="mb-2 fw-medium">
                  Senha
                </label>
                <Input type="password" placeholder="******" name="password" />
              </div>
            </Step>
            <Step active={step === 2}>
              <div className="mb-4">
                <label htmlFor="birthdate" className="mb-2 fw-medium">
                  Data de nascimento
                </label>
                <InputMask
                  kind="datetime"
                  options={{
                    format: 'DD/MM/YYYY',
                  }}
                  name="birthdate"
                  placeholder="00/00/0000"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="document" className="mb-2 fw-medium">
                  CPF
                </label>
                <InputMask
                  kind="cpf"
                  name="document"
                  placeholder="000.000.000-00"
                />
              </div>
              <div className="mb-5">
                <label htmlFor="occupation" className="mb-2 fw-medium">
                  Profissão
                </label>
                <Input name="occupation" />
              </div>
            </Step>
            <Step active={step === 3}>
              <div className="mb-4">
                <label htmlFor="city" className="mb-2 fw-medium">
                  Cidade
                </label>
                <Input name="city" />
              </div>
              <div className="mb-5">
                <label htmlFor="state" className="mb-2 fw-medium">
                  Estado
                </label>
                <Select name="state" options={states} />
              </div>
            </Step>
            <div>
              {step === 3 && (
                <button
                  type="submit"
                  className="btn btn-primary mb-5 w-100 fw-semibold"
                >
                  Continuar
                </button>
              )}
              {step !== 3 && (
                <button
                  type="button"
                  className="btn btn-primary mb-5 w-100 fw-semibold"
                  onClick={() => handleClickChangeStep(step + 1)}
                >
                  Continuar
                </button>
              )}

              <p className="text-gray text-center">
                Já possui conta?{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="text-gray fw-medium"
                >
                  Entrar
                </Link>
              </p>
            </div>
          </Form>
        </Content>
        <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4">
          <div className="px-5 pb-5">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Background>
      </div>
    </Container>
  );
};

export default SignUp;
