import styled from 'styled-components';
import List from 'react-chatview';

export const Container = styled(List)`
  height: calc(100vh - 61px);

  hr.bg-primary {
    width: 65px;
    height: 8px;
    border-radius: 10px;
    opacity: 1;
  }

  button.position-fixed {
    right: 20px;
    bottom: 20px;
  }
`;
