import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
// import ReactPlayer from 'react-player';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';

import { Container } from './styles';

interface playerProps {
  src: string;
  onPlayerReady?(player: HTMLVideoElement): void;
  onTimeUpdate?(player: HTMLVideoElement): void;
  onEnded?(player: HTMLVideoElement): void;
  srcUpdate?: string;
  className?: string;
  onReload?(): void;
  autoPlay?: boolean;
  options?: any;
  onReady?: any;
}

const Player: React.FC<playerProps> = ({
  src,
  onPlayerReady,
  onTimeUpdate,
  onEnded,
  srcUpdate,
  className,
  onReload,
  autoPlay,
  onReady,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);
  // const playerRef = useRef<HTMLVideoElement>(null);
  const [elements, setElements] = useState(document.querySelectorAll('video'));
  const [paused, setPaused] = useState(false);
  const [videoJS, setVideoJs] = useState<VideoJsPlayer>({} as VideoJsPlayer);
  const [currentTime, setCurrentTime] = useState(0);

  const options = useMemo<VideoJsPlayerOptions>(
    () => ({
      fluid: true,
      preload: 'auto',
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },
      autoplay: autoPlay,
      controls: true,
      sources: [
        {
          src,
          type: 'video/mp4',
        },
      ],
    }),
    [autoPlay, src]
  );

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach((el) =>
        el.addEventListener('contextmenu', (e) => e.preventDefault())
      );
    } else {
      setElements(document.querySelectorAll('video'));
    }
  }, [elements]);

  useEffect(() => {
    if (videoRef.current) {
      const player = videojs(videoRef.current, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });
      setVideoJs(player);
    }
  }, [onReady, options]);

  useEffect(() => {
    if (onPlayerReady && playerRef.current) {
      onPlayerReady(playerRef.current as unknown as HTMLVideoElement);
    }
  }, [onPlayerReady]);

  useEffect(() => {
    if (srcUpdate) {
      setPaused(false);
      videoJS.src({ src: srcUpdate, type: 'video/mp4' });
      videoJS.currentTime(currentTime);
      videoJS.play();
    }
  }, [currentTime, srcUpdate, videoJS]);

  const handlePlay = useCallback(
    (e) => {
      if (paused) {
        setCurrentTime(e.target.currentTime);
        if (onReload) {
          onReload();
        }
        setPaused(false);
      }
    },
    [onReload, paused]
  );

  const handlePause = useCallback((e) => {
    setPaused(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    console.log(e.target);
  }, []);

  const handleTimeUpdate = useCallback(
    (e) => {
      if (onTimeUpdate) {
        onTimeUpdate(e);
      }
    },
    [onTimeUpdate]
  );

  const handleEnded = useCallback(
    (e) => {
      if (onEnded) {
        onEnded(e);
      }
    },
    [onEnded]
  );

  return (
    <Container className={className}>
      <div data-vjs-player>
        <video
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          onPlay={handlePlay}
          onPause={handlePause}
          onDragLeave={handleDragLeave}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleEnded}
          autoPlay={autoPlay}
        >
          <track kind="captions" />
        </video>
      </div>
    </Container>
  );
};

export default Player;
