import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

import { Container, Avatar, CategoryCard } from './styles';
import Slider from '~/components/Slider';

import avatar from '~/assets/defaults/avatar.png';
import api from '~/services/api';
import Skeleton from '~/components/Skeleton';

interface IWay {
  id: string;
  title: string;
  description: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IWayData {
  data: IWay[];
}

const Ways: React.FC = () => {
  const { user, updateUser } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [ways, setWays] = useState<IWay[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<IWayData>('ways')
      .then((response) => {
        setWays(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSelectRoute = useCallback(
    async (way: IWay) => {
      try {
        const response = await api.put('users/profile', {
          way_id: way.id,
        });

        const newUser = { ...user };
        newUser.way_id = response.data.way_id;
        if (newUser.way) {
          newUser.way.title = way.title.replace('Sou', '');
        } else {
          newUser.way = {
            title: way.title.replace('Sou', ''),
          };
        }

        updateUser(newUser);
        history.push(`${process.env.PUBLIC_URL}/dashboard`);
      } catch (error) {
        console.log(error);
      }
    },
    [history, updateUser, user]
  );

  return (
    <Container>
      <div className="container">
        <div className="row align-items-center justify-content-between py-5">
          {location.pathname === '/primeiros-passos' && (
            <>
              <div className="col-sm-8 col-lg-6 order-1 order-sm-0 my-3 my-sm-0">
                <p className="h5 mb-3 text-gray fw-medium">Bem-vindo</p>
                <h1 className="fw-semibold">Olá, {user.name}</h1>
              </div>
              <div className="col-sm-4 col-lg-6 order-0 order-sm-0">
                <Avatar
                  src={user.avatar ? user.avatar.avatar_url : avatar}
                  className="ms-auto"
                />
              </div>
            </>
          )}
          <div className="col-12 order-1 order-sm-0 mt-4 mt-sm-5">
            {location.pathname === '/caminhos' ? (
              <h1 className="fw-normal mb-n5 text-gray">
                Selecione um caminho
              </h1>
            ) : (
              <p className="h5 fw-normal mb-5 text-gray">
                Selecione um caminho
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="container-xxl px-0 px-lg-3 px-xxl-0">
        <div className="row align-items-center justify-content-between py-5">
          <div className="col-12 mt-4 mt-lg-5">
            {!loading ? (
              <Slider>
                {ways.map((way) => (
                  <div key={way.id} className="px-2 px-lg-3">
                    <CategoryCard
                      src={way.thumbnail.thumbnail_url}
                      onClick={() => handleSelectRoute(way)}
                      className="px-4 py-5 d-flex align-items-end"
                    >
                      <div className="d-flex justify-content-between align-items-end w-100">
                        <div className="w-75">
                          <h2 className="h5 mb-3">{way.title}</h2>
                          <p className="small mb-0">{way.description}</p>
                        </div>
                        <FaArrowRight size={18} color="#fff" />
                      </div>
                    </CategoryCard>
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider>
                <div className="px-2 px-lg-3">
                  <Skeleton width="100%" height={300} />
                </div>
                <div className="px-2 px-lg-3">
                  <Skeleton width="100%" height={300} />
                </div>
                <div className="px-2 px-lg-3">
                  <Skeleton width="100%" height={300} />
                </div>
                <div className="px-2 px-lg-3">
                  <Skeleton width="100%" height={300} />
                </div>
              </Slider>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Ways;
