import styled from 'styled-components';

import banner from '~/assets/banners/background-login.png';

interface IStep {
  active: boolean;
}

interface IAwaitingPayment {
  show: boolean;
}

export const Container = styled.div`
  > div {
    min-height: 100vh;
  }
`;

export const Content = styled.div`
  border-radius: 0px 33px 33px 0px;

  form {
    width: 100%;

    label {
      font-size: 14px;
    }

    button.btn-primary {
      border-radius: 10px !important;
      font-size: 18px;
      height: 54px;
    }
  }

  @media screen and (min-width: 992px) {
    form {
      width: 75%;
    }
  }

  @media screen and (min-width: 1600px) {
    form {
      width: 80%;
    }
  }
`;

export const Background = styled.div`
  background-image: url(${banner});
  background-size: cover;
  background-position: left center;
  position: relative;

  .logo {
    width: 105px;
  }

  @media (min-width: 992px) {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    flex: 0 0 auto;
    width: 61%;
    z-index: -1;
  }
`;

export const Step = styled.div<IStep>`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  min-height: 325px;
  flex-direction: column;
  justify-content: center;
`;

export const AwaitingPayment = styled.div<IAwaitingPayment>`
  transition-duration: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
