import styled from 'styled-components';
import List from 'react-chatview';

export const Container = styled(List)`
  a {
    border: 1px solid #3a3a3a;
    box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    img {
      width: 50px;
      height: 50px;
    }
  }
`;
