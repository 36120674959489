import styled from 'styled-components';
import { Form } from '@unform/web';

interface IQuestionButton {
  active: boolean;
}

interface IQuestion {
  active: boolean;
}

export const Container = styled(Form)`
  div.questions-group {
    > div {
      width: max-content;
      height: 54px;
    }
  }

  span.bg-correct,
  span.bg-error {
    width: 140px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 768px) {
    div.questions-group {
      max-width: 290px;
    }
  }

  @media screen and (min-width: 991px) {
    div.questions-group {
      max-width: 550px;
    }
  }
`;

export const Countdown = styled.div`
  width: 165px;
  cursor: default;
  background: #0c0c0c !important;
  color: #fff !important;
`;

export const QuestionButton = styled.button<IQuestionButton>`
  position: relative;
  border-radius: 7px;
  border: none;
  width: 45px;
  height: 45px;
  transition-duration: 0.3s;
  font-weight: 600;
  opacity: ${(props) => (props.active ? 1 : 0.8)};
  background: ${(props) => (props.active ? '#3A3A3A' : '#303030')};
  color: ${(props) => (props.active ? '#FFFFFF' : '#4D4D4D')};

  :hover {
    background: #3a3a3a;
    color: #ffffff;
  }

  + button {
    margin-left: 10px;
  }

  svg {
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Question = styled.div<IQuestion>`
  display: ${(props) => (props.active ? 'block' : 'none')};

  p {
    border-bottom: 3px solid #303030;
  }
`;

export const Buttons = styled.div`
  button {
    width: 100px;

    + button {
      margin-left: 15px;
    }
  }

  @media screen and (min-width: 992px) {
    button {
      width: 150px;
    }
  }
`;
