import styled from 'styled-components';

interface IContainer {
  show: boolean;
}

interface IBox {
  recommended: boolean;
}

interface IBoxProduct {
  src: string;
}

export const Container = styled.div<IContainer>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media screen and (min-width: 992px) {
    min-height: 100vh;
  }
`;

export const Box = styled.div<IBox>`
  border-radius: 20px;

  background-color: ${(props) => (props.recommended ? '#3a3a3a' : '#242526')};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.recommended ? 'space-between' : 'flex-start'};

  span {
    cursor: default;
    border-radius: 10px;

    :hover {
      background-color: #e50914 !important;
    }
  }

  h3 {
    font-size: ${(props) => (props.recommended ? '2.5rem' : '2rem')};
  }

  button {
    border-radius: 10px;
  }

  > div:nth-child(2) {
    margin-top: ${(props) => (props.recommended ? '1.5rem' : '3rem')};
    margin-bottom: ${(props) => (props.recommended ? '1rem' : '3rem')};

    > div {
      flex: 0 0 auto;
      width: ${(props) => (props.recommended ? '50%' : '100%')};

      p {
        color: ${(props) => (props.recommended ? '#ffffff' : '#bbbbbb')};
      }
    }
  }

  > div:nth-child(3) {
    flex-direction: ${(props) => (props.recommended ? 'row' : 'unset')};
    margin-top: ${(props) => (props.recommended ? 'unset' : 'auto')};

    button {
      border-color: ${(props) => (props.recommended ? '#fff' : '#bbb')};
      background-color: ${(props) => (props.recommended ? '#fff' : '#bbb')};
      color: #202020;
      width: ${(props) => (props.recommended ? 'auto' : '100%')};
      height: 52px;

      :hover {
        border-color: ${(props) => (props.recommended ? '#f2f2f2' : '#aeaeae')};
        background-color: ${(props) =>
          props.recommended ? '#f2f2f2' : '#aeaeae'};
      }
    }

    .logo {
      display: ${(props) => (props.recommended ? 'block' : 'none')};
    }
  }
`;

export const BoxProduct = styled.div<IBoxProduct>`
  border-radius: 20px;
  background: linear-gradient(
      188.52deg,
      rgba(44, 44, 44, 0.38) 33.3%,
      #2b2b2b 95.76%
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;

  span {
    cursor: default;
    border-radius: 10px;

    :hover {
      background-color: #e50914 !important;
    }
  }

  button {
    border-radius: 10px;
  }
`;
