import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_API_URL as string, {
  reconnection: true,
  reconnectionDelay: 500,
});

interface IInitializeSocketEventsProps {
  onPaid(data?: any): void;
  user_id?: string;
  order_id?: string;
}

interface ISuccessfulPaymentProps {
  user_id: string;
  order_id: string;
}

export const initializeSocketEvents = ({
  onPaid,
  user_id,
  order_id,
}: IInitializeSocketEventsProps): void => {
  console.log(socket);
  socket.on('connect', () => {
    console.log('socket connected');
  });

  socket.on('user-disconnected', (userData: any) => {
    console.log('user disconnected-- closing peers', userData);
  });

  socket.on('disconnect', () => {
    console.log('socket disconnected --');
  });

  socket.on('error', (err: any) => {
    console.log('socket error --', err);
  });

  socket.on('successful-payment', (data: ISuccessfulPaymentProps) => {
    if (data.user_id === user_id && data.order_id === order_id) {
      onPaid();
    }
  });

  socket.on('successful-payment-new-user', (data: any) => {
    onPaid(data);
  });
};
